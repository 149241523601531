<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-07-26 10:28:52
 * @LastEditors: Seven
 * @LastEditTime: 2022-09-21 16:07:57
-->
<template>
<div id="app" v-cloak>
    <div class="ty-page-top">
        <div class="pb36" style="text-align: left;line-height: 48px;display: flex;justify-content: space-between;">
          <span>
            <el-button class="hq-button" @click="cancel()">返回</el-button>
          </span>
          <!-- 最外层判断是否为转校订单 -->
          <span v-if="canAfterSale">
            <el-tooltip :disabled="refund" class="item" effect="light" :content="refundMsg" placement="bottom-end">
            <div style="display: inline">
              <el-button class="hq-button" type="primary"  :disabled="!refund"  @click="goDropOutPage()">退费</el-button>
            </div>
          </el-tooltip>

          <el-tooltip :disabled="turnClass" class="item" effect="light" :content="turnClassMsg" placement="bottom-end">
            <div style="display: inline">
              <el-button class="hq-button" type="primary"  :disabled="!turnClass"  @click="goTransferPage()" plain>转班</el-button>
            </div>
          </el-tooltip>

          <el-tooltip :disabled="upClass" class="item" effect="light" :content="upClassMsg" placement="bottom-end">
            <div style="display: inline">
              <el-button class="hq-button"  :disabled="!upClass" @click="goUpgradePage()">升班</el-button>
            </div>
          </el-tooltip>

            <el-tooltip :disabled="!cancelOrClose.cancelMsg" class="item" effect="light" :content="cancelOrClose.cancelMsg" placement="bottom-end">
              <div style="display: inline">
                <el-button :disabled="!cancelOrClose.cancel" type="primary" class="hq-button" @click="toRemoveOperation">取消订单</el-button>
              </div> 
            </el-tooltip>
          </span>
        </div>
    </div>

    <div class="ty-page-elform">
      
        <!-- 订单概况 -->
        <div class="ty-page-elform-section pb36">
            <div class="ty-info_title" style="margin: 18px 0 12px 0">订单概况</div>
            <div class="ty-w1000-content" style="background: #f5f7fa;padding: 12px 0 0 20px">
                <el-row class="order-detail">
                  <el-col :span="5">
                    <p>订单号</p>
                    <p style="color: #333">{{ this.orderNo }}</p>
                  </el-col>
                  <el-col :span="7" style="padding-right: 18px">
                    <p>报名商品</p>
                    <p style="color: #333">{{
                      this.toGoodsName + "(" + this.toCommodityName + ")"
                    }}</p>
                  </el-col>
                  <el-col :span="3">
                    <p>待收金额</p>
                    <p style="color: #181818">{{ this.allPayText }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p>订单状态</p>
                    <p><span style="color: #337AFF">{{ this.orderStatus }}</span><span v-if="isLock">（已锁单）</span></p
                    >
                  </el-col>
                  <el-col :span="5">
                    <p>报名类型</p>
                    <p style="color: #333">{{ this.orderTypeText }}</p>
                  </el-col>
                  <el-col :span="7">
                    <p>报名时间</p>
                    <p style="color: #333">{{ this.orderCreateTime }}</p>
                  </el-col>
                  <el-col :span="3">
                    <p>原订单号</p>
                    <p style="color: #181818">{{ this.oldOrderNo || '--' }}</p>
                  </el-col>
                  <el-col :span="8">
                    <p>原报名商品</p>
                    <p style="color: #333">{{ this.oldGoodsName || '--' }}</p>
                  </el-col>
                </el-row>
            </div>

            <div class="Mark">
                <el-table
                        style="width: 100%"
                        border
                        stripe
                        size="mini"
                        element-loading-text="拼命加载中"
                        :header-cell-style="{ background: '#e5f2ff' }"
                        :data="orderData">
                    <el-table-column :show-overflow-tooltip="true"  prop="orderKey" label="订单流程" min-width="150" ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true"  prop="orderStatus" label="状态" min-width="150" >
                      <template v-slot:default="{row}">
                        <span v-if="goodsTemplate === goodsTemplateEnum.wjdp">
                          <!-- 网教 -->
                          {{row.orderStatus | empty('--')}}
                        </span>
                        <span v-else>{{ row.orderStatus }}</span>
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true" prop="orderOperator" label="操作" min-width="150">
                        <template slot-scope="scope" >
                                            <span v-if="ios!==1 && scope.row.orderType === '1' && (
                    scope.row.orderStatus === '支付完成' || orderStatus === '订单关闭')" >
                                                <el-button
                                                        type="primary"
                                                        class="btn"
                                                        plain
                                                        @click="paymentDetail()"
                                                        size="mini">
                                                    收款明细
                                                </el-button>
                                            </span>

                                            <span v-if="ios!==1 
                                                  && orderStatus != '订单关闭' 
                                                  && scope.row.orderType === '1' 
                                                  && paymentData[0].collectionAmount">
                                                <el-button
                                                        type="primary"
                                                        @click="userPay()"
                                                        size="mini"
                                                        class="btn">
                                                    学员收款
                                                </el-button>
                                            </span>
                                            <span v-if="ios!==1 
                                                  && orderStatus != '订单关闭' 
                                                  && scope.row.orderType === '1' 
                                                  && whetherHaveVoucher
                                                ">
                                                <el-button
                                                        type="primary"
                                                        @click="goCollection(allCollectionInformation[0].processInstanceId)"
                                                        size="mini"
                                                        class="btn">
                                                    收款确认
                                                </el-button>
                                            </span>
                                                    
                                            <template v-if="scope.row.orderType === '2' && scope.row.orderStatus != '线下签订'">
                                              <span v-if="scope.row.orderType === '2' 
                                              && (scope.row.orderStatus === '待审核' 
                                                || scope.row.orderStatus === '通过'  
                                                || orderStatus === '订单关闭' || scope.row.orderStatus === '驳回')" >
                                                      <el-button
                                                              type="primary"
                                                              class="btn"
                                                              plain
                                                              v-if="btnChangeHiden"
                                                              :disabled="btnChangeEnable"
                                                              @click="signDetail()"
                                                              size="mini">
                                                          查看协议
                                                      </el-button>
                                              </span>

                                              <span v-if="scope.row.orderType === '2' && 
                                                  scope.row.orderStatus === '待审核' && isSchoolEq" >
                                                  <el-button
                                                          type="primary"
                                                          class="btn"
                                                          @click="showPassDialog()"
                                                          size="mini">
                                                      通过
                                                  </el-button>
                                              </span>

                                              <span v-if="orderStatus != '订单关闭' && (scope.row.orderStatus === '待审核' || scope.row.orderStatus === '通过') && isSchoolEq" >
                                                  <el-button
                                                          type="danger"
                                                          class="btn"
                                                          :disabled="rejectEnable"
                                                          @click="reject()"
                                                          size="mini">
                                                      驳回
                                                  </el-button>
                                              </span>

                                              <span 
                                              v-if="scope.row.orderType === '2' 
                                              && orderStatus != '订单关闭' 
                                              &&  (scope.row.orderStatus === '待签约' ||  scope.row.orderStatus=='驳回')"  >
                                                  <el-button
                                                          type="primary"
                                                          class="btn"
                                                          @click="userSign()"
                                                          size="mini">
                                                      学员签约
                                                  </el-button>
                                              </span>
                                              <span v-if="scope.row.orderStatus == '协议异常'">
                                                <el-button type="primary" class="btn" @click="signOnceClick">重推协议</el-button>
                                              </span>
                                            </template>

                                            <span v-if="scope.row.orderType === '3'" >
                                                                --
                                            </span>

                                            <span v-if="scope.row.orderType === '4'" >
                                                                <el-button
                                                                        type="primary"
                                                                        class="btn"
                                                                        plain
                                                                        v-if="xlType !== 3"
                                                                        @click="goodsDetail()"
                                                                        size="mini">
                                                                    商品详情
                                                                </el-button>
                                            </span>

                                            <span v-if="scope.row.orderType === '5' && scope.row.orderStatus !== undefined && scope.row.orderStatus.indexOf('转校') == -1">
                                                                <el-button
                                                                        type="primary"
                                                                        class="btn"
                                                                        plain
                                                                        @click="afterSaleView(scope.row)"
                                                                        size="mini">
                                                                    售后详情
                                                                </el-button>
                                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true"  prop="paymentStatus" label="时间" width="170" label-class-name="ty-ml8" ></el-table-column>
                </el-table>
            </div>
        </div>
        <div class="line"></div>

        <!-- 报名信息 -->
        <div class="ty-page-elform-section pb36">
            <div class="ty-info_title">报名信息</div>
            <div style="padding-bottom:18px;">
                <div class="ty-title2">学员信息</div>
                <div class="Mark" style="border:0px;">
                    <div>
                        <el-table
                                border
                                stripe
                                size="mini"
                                element-loading-text="拼命加载中"
                                :header-cell-style="{ background: '#e5f2ff' }"
                                style="width: 100%;border:1px solid #E1E4EB;"
                                :data="userData">
                            <el-table-column :show-overflow-tooltip="true"  prop="userId" label="学员ID" min-width="100" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="mobile" label="学员账号" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="name" label="学员姓名" min-width="120" >
                              <template slot-scope="scope">
                                <div>
                                  <v-text-field
                                    v-if="editStudentInfo"
                                    style="padding: 6px 0"
                                    v-model="scope.row.name"
                                    label="学员姓名"
                                    hide-details="auto"
                                    outlined
                                    :dense="true"
                                    :height="32"
                                  ></v-text-field>
                                  <span v-else>{{ scope.row.name }}</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  :formatter="isEmptyFun" prop="idCard" label="身份证号" min-width="170" label-class-name="ty-ml8" >
                              <template slot-scope="scope">
                                <div>
                                  <v-text-field
                                    v-if="editStudentInfo"
                                    style="padding: 6px 0"
                                    v-model="scope.row.idCard"
                                    label="身份证号"
                                    hide-details="auto"
                                    outlined
                                    :dense="true"
                                    :height="32"
                                  ></v-text-field>
                                  <span v-else>{{ scope.row.idCard }}</span>
                                </div>
                              </template>
                            </el-table-column>
                             <el-table-column label="学员学历" min-width="120">
                              <template slot-scope="scope">
                                <dict-select code="EDUCATION_TYPE" v-if="editStudentInfo">
                                  <v-select
                                    style="padding: 6px 0"
                                    v-model="scope.row.education"
                                    slot-scope="props"
                                    :items="props.dictData"
                                    :menu-props="{ bottom: true, offsetY: true }"
                                    label="学员学历"
                                    item-text="label"
                                    item-value="value"
                                    outlined
                                    :dense="true"
                                    :height="32"
                                    :clearable="true"
                                    clear-icon="$clear"
                                    hide-details="auto"
                                  ></v-select>
                                </dict-select>
                                <span v-else>{{ scope.row.educationName }}</span>
                              </template>
                            </el-table-column>
                            <el-table-column
                              :formatter="isEmptyFun"
                              prop="emergencyContact"
                              label="紧急联系人"
                              min-width="170"
                              label-class-name="ty-ml8"
                            >
                              <template slot-scope="scope">
                                <div>
                                  <v-text-field
                                    v-if="editStudentInfo"
                                    style="padding: 6px 0"
                                    v-model="scope.row.emergencyContact"
                                    label="紧急联系人"
                                    hide-details="auto"
                                    outlined
                                    :dense="true"
                                    :height="32"
                                  ></v-text-field>
                                  <span v-else>{{ scope.row.emergencyContact }}</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              :formatter="isEmptyFun"
                              prop="emergencyContactPhone"
                              label="紧急联系人电话"
                              min-width="170"
                              label-class-name="ty-ml8"
                            >
                              <template slot-scope="scope">
                                <div>
                                  <v-text-field
                                    v-if="editStudentInfo"
                                    style="padding: 6px 0"
                                    v-model="scope.row.emergencyContactPhone"
                                    label="紧急联系人"
                                    hide-details="auto"
                                    outlined
                                    :dense="true"
                                    :height="32"
                                  ></v-text-field>
                                  <span v-else>{{ scope.row.emergencyContactPhone }}</span>
                                </div>
                              </template>
                            </el-table-column>
                            <el-table-column
                              label="操作"
                              width="205"
                              label-class-name="ty-ml8"
                            >
                              <template slot-scope="scope">
                                <el-button size="small" v-if="isSchoolEq && !(orderData[2].orderStatus == '通过')" type="primary" @click="editEndSaveClick(scope.row)">{{ editStudentInfo?"保存":"编辑"}}</el-button>
                                <el-button size="small" v-if="editStudentInfo" @click="editStudentInfo = false">取消</el-button>
                              </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>

            <div style="padding-bottom:18px;">
                <div class="ty-title2">报考信息</div>
                <div class="Mark" style="border:0px;">
                    <div>
                        <el-table
                                border
                                stripe
                                size="mini"
                                element-loading-text="拼命加载中"
                                :header-cell-style="{ background: '#e5f2ff' }"
                                style="width: 100%;border:1px solid #E1E4EB;"
                                :data="registrationInformation">
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].specialtyEnrollingSchoolName != undefined" prop="specialtyEnrollingSchoolName" label="专科报读学院" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].specialtyEnrollingMajorName != undefined" prop="specialtyEnrollingMajorName" label="专科报读专业" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].specialtyEnrollingProvinceName != undefined" prop="specialtyEnrollingProvinceName"   label="专科考试省份" min-width="95" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].undergraduateEnrollingSchoolName != undefined" prop="undergraduateEnrollingSchoolName" label="本科报读学院" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].undergraduateEnrollingMajorName != undefined" prop="undergraduateEnrollingMajorName" label="本科报读专业" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].undergraduateEnrollingProvinceName != undefined" prop="undergraduateEnrollingProvinceName"   label="本科考试省份" min-width="95" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].enrollingTimeStr != undefined" prop="enrollingTimeStr"   label="学级" min-width="80" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].enrollingSchoolName != undefined" prop="enrollingSchoolName" label="报读院校" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"   v-if="registrationInformation[0].enrollingMajorName != undefined" prop="enrollingMajorName" label="报读专业" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  v-if="xlType !== 3" prop="secondEnrollingSchoolName" label="第2志愿院校" min-width="120" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  v-if="xlType !== 3" prop="secondEnrollingMajorName" label="第2志愿专业" min-width="120" ></el-table-column>
                            <el-table-column
                                :show-overflow-tooltip="true"
                                label="操作"
                                width="200"
                                label-class-name="ty-ml8"
                              >
                                <template>
                                  <el-button v-if="canSelectMajor == true" size="small" type="primary" @click="openbk"
                                  >编辑</el-button>
                                </template>
                              </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>


            <div style="padding-bottom:18px;">
            <div class="ty-title2">成交信息</div>
          <div class="Mark">
            <div>
              <el-table
                border
                stripe
                size="mini"
                element-loading-text="拼命加载中"
                :header-cell-style="{ background: '#e5f2ff' }"
                class="tb-edit-input"
                style="width: 100%"
                :data="userData"
              >
                <el-table-column :show-overflow-tooltip="true"
                  :formatter="isEmptyFun"
                  prop="customerCode"
                  label="客户编号"
                  min-width="120"
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="ncCustomerUserId"
                  label="商机编号"
                  min-width="190"
                ></el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  label="手机"
                  min-width="138"
                  class-name="tb-input"
                >
                  <div slot-scope="scope">
                    {{ scope.row.phoneNumber }}
                  </div>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  :formatter="isEmptyFun"
                  prop="dealSchoolName"
                  label="成交校区"
                  min-width="150"
                >
                  <div slot-scope="scope">
                    <span>{{
                      scope.row.subscribeSchoolName === ""
                        ? "--"
                        : scope.row.subscribeSchoolName
                    }}</span>
                  </div>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  :formatter="isEmptyFun"
                  prop="campusSchoolName"
                  label="上课校区"
                  min-width="150"
                >
                  <div slot-scope="scope">
                    <span>{{
                      scope.row.signSchoolName === ""
                        ? "--"
                        : scope.row.signSchoolName
                    }}</span>
                  </div>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  :formatter="isEmptyFun"
                  prop="teacherRecruitName"
                  label="招生老师"
                  min-width="130"
                >
                  <div slot-scope="scope">
                    <span>{{
                      scope.row.teacherRecruitName === ""
                        ? "--"
                        : scope.row.teacherRecruitName
                    }}</span>
                  </div>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  :formatter="isEmptyFun"
                  prop="teacherSalesName"
                  label="销售老师"
                  min-width="130"
                >
                  <div slot-scope="scope">
                    <span>{{
                      scope.row.teacherSalesName === ""
                        ? "--"
                        : scope.row.teacherSalesName
                    }}</span>
                  </div>
                </el-table-column>

                <el-table-column :show-overflow-tooltip="true"
                  prop="isRecommand"
                  label="转介绍"
                  width="80"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.isRecommand == 1? '是': '否' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="transIntroduceType"
                  label="转介绍类型"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.transIntroduceType == 0 || scope.row.transIntroduceType == 1?recommandTypeMap[scope.row.transIntroduceType]:'--' }}</div>
                  </template>
                </el-table-column> 
                <el-table-column :show-overflow-tooltip="true"
                  prop="teacherRecommandName"
                  label="转介绍老师"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.teacherRecommandName || '--' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="assistDealCrmUserId"
                  label="协助成交人员"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <v-autocomplete
                      style="padding: 6px 0"
                      v-if="edit && scope.row.isRecommand == 0"
                      v-model="scope.row.assistDealCrmUserId"
                      :items="assistDealList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="'协助成交人员'"
                      outlined
                      item-text="assistDealUserName"
                      item-value="assistDealCrmUserId"
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                    ></v-autocomplete>
                    <div v-else>{{scope.row.assistDealUserName || '--'}}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="teacherLecturerName"
                  label="讲师"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.teacherLecturerName || '--' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="openClassType"
                  label="公开课类型"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <!-- <template slot-scope="scope">
                    <div>{{ scope.row.openClassType || '--' }}</div>
                  </template> -->
                  <template slot-scope="scope">
                    <v-autocomplete
                      style="padding: 6px 0"
                      v-if="edit"
                      v-model="scope.row.openClassType"
                      :items="[{id:'普通小型公开课',name:'普通小型公开课'}, {id:'小型校内宣讲', name:'小型校内宣讲'}, {id:'大型校内宣讲', name:'大型校内宣讲'}, {id:'特训营正课试听', name:'特训营正课试听'}]"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="'公开课类型'"
                      outlined
                      item-text="name"
                      item-value="id"
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                    ></v-autocomplete>
                    <div v-else>{{ scope.row.openClassType || '--' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="lecturerAssigned"
                  label="是否外派"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                     <v-autocomplete
                      style="padding: 6px 0"
                      v-if="edit"
                      v-model="scope.row.lecturerAssigned"
                      :items="[{id:true,name:'是'}, {id:false, name:'否'}]"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="'是否外派'"
                      outlined
                      item-text="name"
                      item-value="id"
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                    ></v-autocomplete>
                    <div v-else>
                      <span v-if="scope.row.lecturerAssigned == true">是</span>
                      <span v-else-if="scope.row.lecturerAssigned == null"> </span>
                      <span v-else>否</span>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="economicFlowPartner"
                  label="流量经纪人"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.economicFlowPartner || '--' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="flowPartner"
                  label="流量合伙人"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.flowPartner || '--' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="isSixFour"
                  label="是否46分成"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <v-autocomplete
                      style="padding: 6px 0"
                      v-if="edit && (saleSourceName == '智慧校区web' || saleSourceName == '智慧校区h5')"
                      v-model="scope.row.isFourSix"
                      :items="[{id:1,name:'是'}, {id:0, name:'否'}]"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="'是否46分成'"
                      outlined
                      item-text="name"
                      item-value="id"
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                    ></v-autocomplete>
                    <div v-else>{{scope.row.isFourSix == 1 ? '是': '否'}}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="promotionForm"
                  label="促单形式"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <v-autocomplete
                      style="padding: 6px 0"
                      v-if="edit && (saleSourceName == '智慧校区web' || saleSourceName == '智慧校区h5')"
                      v-model="scope.row.promotionForm"
                      :items="[{id:'招生协助',name:'招生协助'}, {id:'合伙人自主', name:'合伙人自主'}]"
                      :menu-props="{ bottom: true, offsetY: true }"
                      :label="'促单形式'"
                      outlined
                      item-text="name"
                      item-value="id"
                      :dense="true"
                      :height="32"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                    ></v-autocomplete>
                    <div v-else>{{ scope.row.promotionForm || '--' }}</div>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  prop="channelsTypeName"
                  label="渠道名称"
                  width="200"
                  label-class-name="ty-ml8"
                >
                  <template slot-scope="scope">
                    <div>{{ scope.row.channelsTypeName || '--' }}</div>
                  </template>
                </el-table-column>
                 
                <el-table-column :show-overflow-tooltip="true"
                  prop="saleSourceName"
                  label="订单渠道"
                  width="200"
                  label-class-name="ty-ml8"
                >
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="205"
                  label-class-name="ty-ml8"
                  fixed="right"
                  v-if="!Number($route.query.transferSchool)"
                >
                  <template>
                    <el-button v-if="!edit" size="small" type="primary" :disabled="isLock" @click="openEdit"
                    >编辑</el-button>
                    <el-button v-if="edit" size="small" type="primary" @click="editSave" :disabled="saveButton"
                    >保存</el-button>
                    <el-button size="small" v-if="edit" @click="closeEdit">取消</el-button>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <!-- v-if="$route.query.transferSchool"转校订单成交信息不允许编辑 -->
          <!-- <div style="padding-top: 12px;margin-bottom: 10px" v-if="!Number($route.query.transferSchool)">
              <el-button v-if="!edit" type="primary" :disabled="isLock" @click="openEdit" size="mini">编辑</el-button>
              <el-button v-if="edit" type="primary" :disabled="saveButton"  @click="editSave" size="mini">保存</el-button>
              <el-button v-if="edit" @click="closeEdit" size="mini">取消</el-button>
          </div> -->
        </div>

            <div v-if="tobePaid">
                <span class="ty-title2">报名协议</span>
                <span class="ty-title2" style="padding-left:22px;padding-right:14px;">{{userData[0].signContractStatus}}</span>
                <template v-if="userData[0].signContractStatus != '线下签订'">
                  <el-button
                        type="primary"
                        v-if="btnChangeHiden"
                        :disabled="btnChangeEnable"
                        @click="signDetail()"
                        size="mini">
                      查看协议
                  </el-button>
                  <template v-if="isSchoolEq">
                    <el-button v-if="userData[0].signContractStatus === '待审核'"
                            @click="showPassDialog()"
                            size="mini">
                        通过
                    </el-button>

                    <el-button v-if="orderStatus != '订单关闭' && (userData[0].signContractStatus === '待审核' || userData[0].signContractStatus === '通过')"
                            type="warning"
                            @click="reject()"
                            size="mini">
                        驳回
                    </el-button>
                    <el-button
                            v-if="userData[0].signContractStatus !== '免签'"
                            @click="checkRecord()"
                            size="mini">
                        审核记录
                    </el-button>
                    <el-tooltip v-if="btnChangeEnable" effect="light" content="学员签订协议后可下载协议" placement="bottom-end">
                      <div style="display: inline;margin-left: 12px">
                        <el-button
                            type="primary"
                            v-if="btnChangeHiden"
                            :disabled="btnChangeEnable"
                            @click="downloadSignDetail()"
                            size="mini">
                            下载协议
                        </el-button>
                      </div>
                    </el-tooltip>
                    <el-button
                        type="primary"
                        v-if="btnChangeHiden && !btnChangeEnable"
                        :disabled="btnChangeEnable"
                        @click="downloadSignDetail()"
                        size="mini">
                        下载协议
                    </el-button>
                  </template>
                </template>
            </div>
        </div>
        <div class="line"></div>

        <!-- 商品信息 -->
        <div class="ty-page-elform-section pb36">
            <div class="ty-info_title">商品信息</div>
            <div>
                <div class="Mark">
                    <div>
                        <el-table
                                border
                                stripe
                                size="mini"
                                element-loading-text="拼命加载中"
                                :header-cell-style="{ background: '#e5f2ff' }"
                                style="width: 100%;"
                                :data="goodsData">
                            <el-table-column :show-overflow-tooltip="true"  fixed prop="goodsId" label="商品ID" width="80" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  fixed prop="goodsName" label="商品名" min-width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="ncCommodityId" label="商品NCID" min-width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="ncSchoolId" label="大学主键" min-width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="commodityName" label="班型" min-width="140" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="ncMajorId" label="专业主键" min-width="180" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="productName" label="产品线" min-width="140" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="goodsCategoryName" label="商品分类" min-width="150" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  :formatter="isEmptyFun" prop="goodsSeriesName" label="产品系列" min-width="200" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="goodsTypeName" label="商品类型" min-width="150" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true"  prop="pattern" label="上课形式" min-width="150" ></el-table-column>
                            <el-table-column :show-overflow-tooltip="true" v-if="xlType !== 3" fixed="right" width="160" label="操作">
                                <template slot-scope="scope">
                                    <el-button
                                            type="text"
                                            v-if="xlType !== 3"
                                            @click="goodsDetail(scope.row)"
                                            size="mini">
                                        商品详情
                                    </el-button>
                                    <el-button
                                            type="text"
                                            v-if="xlType === 1 "
                                            @click="serviceDetail2(scope.row)"
                                            size="mini">
                                        服务详情
                                    </el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>

        </div>
        <div class="line"></div>

        <!-- 收款信息 -->
        <div class="ty-page-elform-section pb36">
            <div class="ty-info_title">收费信息</div>
            <div class="Mark">
                <el-table
                        border
                        stripe
                        size="mini"
                        element-loading-text="拼命加载中"
                        :header-cell-style="{ background: '#e5f2ff' }"
                        style="width: 100%;"
                        :data="paymentData">
                    <el-table-column :show-overflow-tooltip="true"  :formatter="numberFormatter" prop="commodityPrice" label="商品价格" min-width="120" ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" :formatter="numberFormatter" prop="preferential" label="优惠额"
                                     min-width="100"> <template slot-scope="scope">{{ scope.row.preferential + (scope.row.upClassDiscount || 0) }} </template></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==1" :formatter="numberFormatter" prop="amountReceivable"
                                     min-width="120" label="应收学币"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==1" :formatter="numberFormatter" prop="paymentAmount"
                                     min-width="120" label="支付学币"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="amountReceivable"
                                     min-width="120" label="应收金额"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="changeClassDiscount" label="转班抵扣额"
                                     min-width="100"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="deduction" label="余额抵扣额"
                                     min-width="100"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="paymentAmount" label="支付金额"
                                      min-width="120" ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" :formatter="formatFormAllData" prop="payMode" label="支付方式"
                                     min-width="120" ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true"  :formatter="formatFormAllData" prop="state" label="支付状态" min-width="120"  ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="amountReceivedAll"
                                     min-width="120" label="已收金额"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="amountReceived"
                                     min-width="120" label="已确认收款"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios==0" :formatter="numberFormatter" prop="collectionAmount"
                                     min-width="120" label="待收金额"></el-table-column>
                    <el-table-column :show-overflow-tooltip="true"  :formatter="formatFormAllData" prop="allPay" label="收齐款" min-width="100"  ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true"  prop="completionTime" label="支付时间" min-width="140" ></el-table-column>
                    <el-table-column :show-overflow-tooltip="true" v-if="ios!=1" fixed="right" label="操作" :width="loanMethod ? 200 : 90">
                        <template v-if="ios!==1" slot-scope="scope">
                            <el-button
                                    type="text"
                                    @click="paymentDetail()"
                                    size="mini">
                                收款明细
                            </el-button>
                            <el-button
                                    type="text"
                                    v-if="loanMethod"
                                    @click="repaymentProject(scope.row)"
                                    size="mini">
                                还款明细
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <div class="line"></div>

        <!-- 收费项目\收支项目 -->
          <div class="ty-page-elform-section pb36" v-if="inoutProjectData.length">
            <div class="ty-info_title" style="padding-right:25px;">收费项目
                <el-button v-if="this.god === '1' " @click="addInoutFun()" type="success" style="float:right;">补充收费项目
                </el-button>
            </div>
            <div class="Mark">
                <el-table
                        border
                        stripe
                        size="mini"
                        show-summary
                        :summary-method="getTotal"
                        element-loading-text="拼命加载中"
                        :header-cell-style="{ background: '#e5f2ff' }"
                        style="width: 100%;"
                        :data="inoutProjectData">
                        <el-table-column :show-overflow-tooltip="true"
                          prop="inoutProjectName"
                          label="收支项目"
                          min-width="150"
                        ></el-table-column>
                        <el-table-column :show-overflow-tooltip="true"
                          prop="standardPrice"
                          label="标准价格"
                          min-width="150"
                        ></el-table-column>
                        <el-table-column :show-overflow-tooltip="true"
                          prop="goodsDiscount"
                          label="优惠额"
                          min-width="150"
                        >
                         <template slot-scope="scope">{{scope.row.goodsDiscount}}</template>
                        </el-table-column>
                        <el-table-column :show-overflow-tooltip="true"
                          prop="receivable"
                          label="应收金额"
                          min-width="150"
                        ></el-table-column>
                   
                </el-table>
            </div>
        </div>


        <div class="ty-page-form-btn" style="text-align: center;margin: 20px 0">
        </div>
    </div>

    <el-dialog title="服务详情" width="480px" :visible.sync="dialogFormVisible" :close-on-click-modal="false" top="5vh">
        <ul class="ty-column-table">
            <li><span>类型</span><span>次数</span></li>
            <li><span>会答次数</span><span>{{serviceData.length>0 ? serviceData[0].kuaidaTotalStr : ''}}</span></li>
            <li><span>会答频次</span><span>{{serviceData.length>0 ? serviceData[0].kuaidaFrequency : ''}}</span></li>
            <li><span>复训期(月)</span><span>{{serviceData.length>0 ? serviceData[0].effectiveMonth : ''}}</span></li>
            <li><span>复训期(月)</span><span>{{serviceData.length>0 ? serviceData[0].retrainingCount : ''}}</span></li>
            <li><span>重读次数</span><span>{{serviceData.length>0 ? serviceData[0].restudyCountStr : ''}}</span></li>
            <li><span>请假次数</span><span>{{serviceData.length>0 ? serviceData[0].leaveCount : ''}}</span></li>
            <li><span>调班次数</span><span>{{serviceData.length>0 ? serviceData[0].shiftClassCount : ''}}</span></li>
            <li><span>休学次数</span><span>{{serviceData.length>0 ? serviceData[0].suspendSchoolCount : ''}}</span></li>
            <li><span>转校次数</span><span>{{serviceData.length>0 ? serviceData[0].shiftSchoolCount : ''}}</span></li>
        </ul>

        <div slot="footer" class="dialog-footer" style="text-align:center;width: 100%">
            <el-button @click="okFun()" type="primary">确定</el-button>
        </div>
    </el-dialog>
    <GoodsDetailDrawer
      ref="goodsDetail"
      :orderId="orderId"
      :goodsId="goodsId"
      :visible.sync="showGoodDetailVisible"
    ></GoodsDetailDrawer>
    <service-detail-modal
      :orderId="orderId"
      :businessId="businessId"
      :visible.sync="showServiceDetail"
    >
    </service-detail-modal>
    <service-detail-single-modal
      :orderId="orderId"
      :businessId="businessId"
      :visible.sync="showServiceDetailSingle"
      >
    </service-detail-single-modal>
    <CollectDetailModal
      :orderId="orderId"
      :visible.sync="showCollectionDetailVisible"
    ></CollectDetailModal>
    <CollectDetailSingleModal
      :payTypeList="payTypeList"
      ref="drawer"
      :orderId="orderId"
      :visible.sync="showCollectionDetailSingleVisible"
    ></CollectDetailSingleModal>
    <AgreementDetaiModal
      ref="agree"
      :orderId="orderId"
      :name="name"
      :visible.sync="showSignDetailVisible"
    ></AgreementDetaiModal>
    <HistoryAuditRecordModal
      ref="historyDrawer"
      :contractRecordId='contractRecord.id'
      openType='3'
      :visible.sync="showHistoryAuditRecordVisible"
    ></HistoryAuditRecordModal>
    <RepaymentDetailModal
     :orderId="orderId"
      :visible.sync="showRepaymentDetailVisible"
    ></RepaymentDetailModal>
    <AuditReject
      :visible.sync="showRejectModalVisible"
      @rejectOk="rejectOk"
    >
    </AuditReject>
    <HqModal :width="'555'" :min-height="'650'" :max-height="'650'" :visible="bkModel" title="编辑" @on-cancel="cancelbk">
       <el-form
        :model="signUpForm"
        :rules="signUpFormRules"
        label-position="right"
        ref="signUpForm"
        style="padding-bottom: 160px;"
      >
      <el-row :gutter="8"><div>第一志愿</div></el-row>
       <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item prop="goodsName">
            <schooleTree 
            ref="deptsys"
            :data="findSchoolList"
            @update:select="selectSchoolNcid"/>
          </el-form-item>
        </el-col>
       </el-row>
       <el-row :gutter="24">
        <el-col :span="12">
          <el-form-item prop="enrollingMajorId" style="margin-bottom: 12px">
            <v-autocomplete
                style="width: 250px; margin-right: 12px"
                v-model="ckSingle.form.enrollingMajorId"
                :items="this.ckSingle.enrollingMajorList"
                :menu-props="{ bottom: true, offsetY: true }"
                label="报考专业"
                outlined
                item-text="majorName"
                item-value="majorId"
                :dense="true"
                :height="32"
                clear-icon="$clear"
                hide-details="auto"
                no-data-text="暂无数据"
                class="normal-select"
                @change="selectCkSingleProfessional"
              ></v-autocomplete>
          </el-form-item>
          <el-form-item>
            <v-autocomplete
              v-model="ckSingle.form.enrollingGradationId"
              :items="this.ckSingle.enrollingGradationList"
              :menu-props="{ bottom: true, offsetY: true }"
              label="报考学级"
              outlined
              item-text="gradationYear"
              item-value="id"
              :dense="true"
              :height="42"
              clear-icon="$clear"
              hide-details="auto"
              no-data-text="暂无数据"
              class="normal-select"
              @change="selectCkSingleGradation"
            ></v-autocomplete>
          </el-form-item>
        </el-col>
       </el-row>
       <el-row :gutter="8" v-if="this.secondEnrollingSchoolName"><div>第二志愿</div></el-row>
        <el-row :gutter="24" v-if="this.secondEnrollingSchoolName">
          <el-col :span="12">
            <schooleTree 
              ref="deptsystwo"
              :data="findtwoSchoolList"
              @update:select="selectSchoolNcidtwo"/>
          </el-col>
          <el-col :span="12" v-if="ckSingle.enrollingMajorList2">
            <el-form-item prop="enrollingMajorId2">
                <v-autocomplete
                  v-model="ckSingle.form.enrollingMajorId2"
                  :items="ckSingle.enrollingMajorList2"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="报考专 业"
                  outlined
                  item-text="majorName"
                  item-value="majorId"
                  :dense="true"
                  :height="42"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  class="normal-select"
                  @change="selectCkSingleProfessional2"
                ></v-autocomplete>
              </el-form-item>
          </el-col>
        </el-row>
       </el-form>
       <template #footer>
        <el-button
          type="primary"
          @click="searchBkOk()"
          :loading="Bkloading"
          >确定</el-button
        >
        <el-button
          type="error"
          @click="cancelbk"
          style="margin-left: 8px"
          >取消</el-button
        >
      </template>
    </HqModal>
    <HqModal :width="'555'" :min-height="'150'" class="tishiclass" :visible="bkModelts" title="提示" @on-cancel="cancelts">
      <div style="width: 100%; 
            text-align: center;">当前第二志愿未选择完，确定继续提交吗？</div>
      <template #footer>
        <el-button
          type="primary"
          @click="searchtsOk()"
          :loading="tsloading"
          >确定</el-button
        >
        <el-button
          type="error"
          @click="cancelts"
          style="margin-left: 8px"
          >取消</el-button
        >
      </template>
    </HqModal>
</div>
</template>

<script>
import { 
  getTransactionInfoByOrderId,
  getDeptId
} from "api/customer";
import AuditReject from '../components/audit-reject.vue'
import GoodsDetailDrawer from "../components/goods-detail-drawer.vue"
import ServiceDetailModal from "../components/service-detail-modal.vue"
import ServiceDetailSingleModal from "../components/service-detail-single-modal.vue"
import CollectDetailModal from "../components/collect-detail-modal.vue"
import CollectDetailSingleModal from "../components/collect-detail-single-modal.vue"
import AgreementDetaiModal from "../components/agreement-detail-modal.vue"
import HistoryAuditRecordModal from '../components/history-audit-record-modal.vue'
import RepaymentDetailModal from '../components/repayment-detail-modal.vue'
import dictSelect from "components/dictSelect"
import axios from "axios"
import { doOrderDetailUpdate } from "api/order/change"
import { mapGetters } from "vuex";
import { goodsTemplateEnum, recommandTypeMap, xlTypeEnum, businessIdEnum } from '../constants';
import { download } from "@/utils";
import { reqDictByCode } from "api/dict";
import { getQuerySchoolList } from "api/count";
import schooleTree from "@/components/schooletree";
import Checker from "@/utils/Checker";


export default {
    name: 'order-detial-xl',
     components: {
      GoodsDetailDrawer,
      ServiceDetailModal,
      ServiceDetailSingleModal,
      CollectDetailModal,
      CollectDetailSingleModal,
      AgreementDetaiModal,
      HistoryAuditRecordModal,
      RepaymentDetailModal,
      AuditReject,
      dictSelect,
      schooleTree
    },
    data(){
        return {
            bkModelts: false,
            tsloading: false,
            bkModel: false,
            paramsts: {},
            findSchoolList: [],
            signUpForm: {},
            signUpFormRules: {},
            xlTypeEnum,
            findtwoSchoolList: [],
            currentGoods2: {},
            firstMajor: null,
            /***
             * 针对新加的学历
             */
            graduactionForm: {
              enrollingProvinceIdList: [], //报考省份名称集合
              enrollingProvinceNameList: [], //报考省份名称集合
              enrollingMajorIdList: [], //报考专业id集合
              enrollingMajorNameList: [], //报考专业名称集合
              enrollingSchoolIdList: [], //报考院校id集合
              enrollingSchoolNameList: [], //报考省份名称集合
              gradationIdList: [], //报考层次(1专科  2本科)
              enrollingTime: "", //报考时间(用于记录成考、网教报考学级)
              xlCommodityIdList: [], //学历的规格ids
              // 成考第二志愿 start
              enrollingProvinceIdList2: [], //报考省份名称集合
              enrollingProvinceNameList2: [], //报考省份名称集合
              enrollingMajorIdList2: [], //报考专业id集合
              enrollingMajorNameList2: [], //报考专业名称集合
              enrollingSchoolIdList2: [], //报考院校id集合
              enrollingSchoolNameList2: [], //报考省份名称集合
              gradationIdList2: [], //报考层次(1专科  2本科)
              enrollingTime2: "", //报考时间(用于记录成考、网教报考学级)
              xlCommodityIdList2: [], //学历的规格ids
              // 成考第二志愿 end
            },
            Bkloading: false,
            curenrolling: {},
            // 第一志愿
            currentGoods:{},
            ckSingle: {
              xlType: 2,
              xlType_second: 2,
              //商品id
              goodsId: "",
              goodsId_second: "",
              //商品价格
              goodsPrice: "",
              //报考院校
              enrollingSchoolName: "",
              enrollingSchoolName_second: "",
              //报考学级
              enrollingGradationDatasource: [],
              enrollingGradationDatasource_second: [],
              enrollingGradationList: [],
              //报考专业
              enrollingMajorList: [],
              enrollingMajorList_second: [],
              specificationPriceDataSource: [],
              specificationPriceDataSource_second: [],
              //课程清单
              courseList: [],
              form: {
                enrollingMajorId: "",
                enrollingGradationId: "",
                enrollingMajorId_second: "",
                enrollingGradationId_second: "",
              },
            },
            secondEnrollingSchoolName: '',
            //是否允许转班
            turnClass: false,
            turnClassMsg: "",
            //是否允许升班
            upClass: false,
            upClassMsg: "",
            //是否允许退费
            refund: false, 
            refundMsg: "",   
            showRejectModalVisible: false,
            name: '',
            showGoodDetailVisible: false,
            showServiceDetailSingle: false,
            showServiceDetail:false,
            showCollectionDetailSingleVisible: false,
            showCollectionDetailVisible: false,
            showSignDetailVisible:false,
            showHistoryAuditRecordVisible:false,
            showRepaymentDetailVisible: false,

            goodsId: '',//商品规格id
            orderId: '',//订单id
            userId: '',//用户id
            ios: '',
            goodsName: '',
            subOrderId: '',
            orderNo: null,
            orderStatus: '',
            payPrice: 0,
            amountReceived: 0,
            goodsTemplate:'',
            toGoodsTypeName: "",
            toProductName: "",
            toCommodityName: "",
            orderTypeText: '',
            toGoodsName: "",
            toGoodsId: "",
            // 报名时间
            orderCreateTime: "",
            // 原订单号
            oldOrderNo: "",
            // 原商品名称
            oldGoodsName: "",
            orderUpdateTime: '',
            orderData: [],
            paymentData: [],
            userData: [],
            goodsData: [],
            gitData: [],
            xlType:null,
            canSelectMajor: false,
            registrationInformation:[{
                "specialtyEnrollingSchoolName":"",
                "specialtyEnrollingMajorName":"",
                "specialtyEnrollingProvinceName":"",
                "undergraduateEnrollingSchoolName":"",
                "undergraduateEnrollingMajorName":"",
                "undergraduateEnrollingProvinceName":"",
                "enrollingSchoolName":"",
                "enrollingMajorName":"",
                "enrollingTimeStr":"",
                "secondEnrollingSchoolName ":"",
                "secondEnrollingMajorName ":"",
            }],
            serviceData: [],
            loading: false,
            saveButton:false,
            affiliationName:'--',
            phoneNumber:'--',
            teacherRecruitName:'--',
            isLock:false,
            sendNcButton:false,
            /*重新调用全网与代理商机接口*/
            reCreateSJ:false,
            saveExtraDoc:{
                teacherRecruitId:'',
                teacherRecruitName:'',
                teacherRecruitNCid:'',
                teacherSalesId:'',
                teacherSalesName:'',
                teacherSalesNCid:'',
                crmName:'',
                crmCode:'',
                crmPkSupplier:'',
                economicFlowPartner:'',
                economicFlowPartnerPhone:'',
                economicFlowPartnerCode:'',
                flowPartner:'',
                flowPartnerPhone:'',
                flowPartnerCode:'',
            },
            // 全网请求参数
            queryQWParam:{
                Param:{
                    msgBody:{
                        source:10,
                        admissionsChannel:"恒企网校",
                        createTime: this.dateFormat(new Date()),
                        name:"",
                        phone:"",
                    },
                    msgType:2
                },
                url:"",
                createUrl:"",
            },
            phone: {
                test: function (value, saveGo) {
                    // 验证手机号
                    let reg = /^1[0-9]{11}$/
                    if (value === undefined || value === '' || value.length < 11 || reg.test(value)) {
                        if (saveGo === undefined) {
                            this.$message({
                                message: "请输入正确的手机号",
                                type: 'error'
                            });
                        }
                        return false
                    } else {
                        return true;
                    }
                }
            },
            tempArr: [],
            businessId:null,
            edit:false,
            allPayText:'',
            ncState:null,
            sellType:'',
            saveFlag:'',
            btnChangeEnable:true,
            btnChangeHiden:true,
            rejectEnable:true,
            inoutProjectData: [],
            dialogFormVisible: false,
            loanMethod:false,

            // 协议状态对象
            contractRecord:{},
            god: '',
            tobePaid:false,

            userTempArr: [],
            payStatusArr: [
                {"id": 1, "Text": "待支付"},
                {"id": 2, "Text": "支付中"},
                {"id": 3, "Text": "支付完成"},
                {"id": 4, "Text": "订单取消"},
                {"id": 5, "Text": "订单超时"}, 
                {"id": 6, "Text": "贷款中"},
                {"id": 7, "Text": "贷款完成"},
                {"id": 8, "Text": "贷款失败"}],
            orderStatusArr: [
                {"id": 11, "Text": "待支付"},
                {"id": 12, "Text": "支付中"}, 
                {"id": 13, "Text": "支付完成"},
                {"id": 14, "Text": "订单取消"},
                {"id": 15, "Text": "订单超时"}, 
                {"id": 16, "Text": "订单删除"}, 
                {"id": 17, "Text": "待签约"}, 
                {"id": 18, "Text": "待确认"}, 
                {"id": 19, "Text": "订单完成"},
                 {"id": 20, "Text": "订单关闭"}],
            payTypeArr : [
                {"id": 1, "Text": "支付宝"}, 
                {"id": 2, "Text": "微信支付"}, 
                {"id": 3, "Text": "有钱花"},
                {"id": 4, "Text": "民生分期"},
                {"id":99,"Text":"学币支付"}],
            allPayArr: [
                {"id": "", "Text": "--"}, 
                {"id": 1, "Text": "是"}, 
                {"id": 0, "Text": "否"}],
            allPayTextArr: [
                {"id": "", "Text": "--"},
                {"id": 1, "Text": "收齐款"},
                {"id": 0, "Text": "未收齐款"}],
            saleSourceArr: [
                {"id": 1, "Text": "财经官网web端"}, 
                {"id": 2, "Text": "财经Android端"},
                {"id": 3, "Text": "财经ios端"},
                {"id": 4, "Text": "财经线下成交(招生工作台)"}, 
                {"id": 5, "Text": "财经h5"}, 
                {"id": 6, "Text": "学历官网web端"}, 
                {"id": 7, "Text": "学历Android端"},
                {"id": 8, "Text": "学历ios端"},
                {"id": 9, "Text": "学历h5"},
                {"id": 10, "Text": "学历线下成交(招生工作台)"}, 
                {"id": 11, "Text": "财会线下成交(招生工作台)"},
                {"id":13,"Text":"天翼商品后台"}],
            orderTypeArr: [
                {"id": 0, "Text": "--"},
                {"id": 1, "Text": "新增报名"}, 
                {"id": 2, "Text": "升班报名"},
                {"id": 3, "Text": "转班报名"},
                {"id": 4, "Text": "免费报名"}],
            courseLearningStatusArr : [
                {"id": "0", "Text": "在读"},
                {"id": "1", "Text": "休学"},
                {"id": "2", "Text": "转校-暂停"},
                {"id": "3", "Text": "结业"},
                {"id": "4", "Text": "退学"}],
            paymentStatusArr:[
                {"id": "1", "Text": "待支付"}, 
                {"id": "2", "Text": "支付中"}, 
                {"id": "3", "Text": "支付完成"}],
            signAgreementStatusArr:[
                {"id": "20", "Text": "线下签订"},
                {"id": "21", "Text": "免签"},
                {"id": "22", "Text": "待签约"},
                {"id": "23", "Text": "待审核"},
                {"id": "24", "Text": "通过"}, 
                {"id": "25", "Text": "驳回"}, 
                {"id":"26","Text":"协议异常"}],
            permissionOpeningStatusArr : [
                {"id": "31", "Text": "待开通"}, 
                {"id": "32", "Text": "已开通"}, 
                {"id": "33", "Text": "开通异常"},
                {"id": "34", "Text": "待关闭"}, 
                {"id": "35", "Text": "已关闭"}, 
                {"id": "36", "Text": "关闭异常"}],
            aftetSaleStatusArr: [
                {"id": "0", "Text": "待提交"},
                {"id": "1", "Text": "待审核"},
                {"id": "2", "Text": "审核通过"}, 
                {"id": "3", "Text": "审核驳回"}, 
                {"id": "4", "Text": "售后完成"},
                {"id": "5", "Text": "售后异常"}],
            blankArr:[{id:"",Text:"--"}],
            // 订单渠道
            saleSourceName: "",
            // 支付方式
            payTypeList: [],
            payModeList: [],
            goodsTemplateEnum,
            allCollectionInformation: [],
            recommandTypeMap,
            cancelOrClose: {},
            // 判断是否有凭证
            whetherHaveVoucher: false,
            // 编辑学员信息
            editStudentInfo: false,
            // 订单来源
            saleSourceList: {},
            campusList: [],
            property: null,
            //协助成交人员列表数据
            assistDealList:[]
        }
    },
    methods: {
      // 报考信息
      cancelbk() {
        this.$refs.deptsys && this.$refs.deptsys.init()
        this.$refs.deptsystwo && this.$refs.deptsystwo.init()

        this.ckSingle.form.enrollingGradationId = "";
        this.ckSingle.form.enrollingMajorId = "";
        this.ckSingle.form.enrollingMajorId2 = "";
        this.ckSingle.enrollingMajorList2 = []
        console.log('registrationInformation',this.registrationInformation);
        this.$forceUpdate()
        this.bkModel = false
      },
      async searchBkOk() {
        let getTyUserInfo = JSON.parse(localStorage.getItem("tyUserInfo")) || {};
        
        let params = {
          orderId: this.orderId,
          tyUserId: getTyUserInfo.id,
          registrationInformationList: [{
            id:this.registrationInformation[0].idStr,
            enrollingSchoolName: this.currentGoods.enrollingSchoolName,
            enrollingMajorName: this.curenrolling.majorName,
            ncSchoolId: this.currentGoods.schoolNcId,
            ncMajorId: this.curenrolling.majorNcId,
            enrollingTime: this.ckSingle.enrollingTime,
            enrollingSchoolId: this.currentGoods.enrollingSchoolId,
            enrollingMajorId: this.curenrolling.majorId,
            volunteer: this.registrationInformation[0].volunteer,
          }]
        }
        console.log('this.registrationInformation[0]', this.registrationInformation[0], !this.registrationInformation[0].secondVolunteerInfo);
        if (this.registrationInformation[0] && !this.registrationInformation[0].secondVolunteerInfo) {
          this.registrationInformation[0].secondVolunteerInfo = {}
          this.registrationInformation[0].secondVolunteerInfo.idStr = ''
          this.registrationInformation[0].secondVolunteerInfo.volunteer = ''
        }
        
        console.log('params',params); 
        console.log('this.currentGoods2.enrollingSchoolId', this.currentGoods2.enrollingSchoolId);
        if(!this.currentGoods.enrollingSchoolName){
          return this.$message.error('请选择第一志愿内容！')
        }
        if (this.curenrolling.majorName == undefined) {
          return this.$message.error('请选择第一志愿内容！')
        }
        if (this.currentGoods.schoolNcId == undefined) {
          return this.$message.error('请选择第一志愿内容！')
        }
        if (this.curenrolling.majorNcId == undefined) {
          return this.$message.error('请选择第一志愿内容！')
        }
        if (this.ckSingle.enrollingTime == undefined) {
          return this.$message.error('请选择第一志愿内容！')
        }
        console.log('this.secondEnrollingSchoolName', this.secondEnrollingSchoolName);
        // 判断是否有第二志愿
        if (this.secondEnrollingSchoolName == '') {
          this.getupdateEnrollInfo(params)
        } else if(!this.currentGoods2.enrollingSchoolId && this.secondEnrollingSchoolName !== '') {
          this.bkModelts = true
          
        } else {
          let enrolltwo = {
            id:this.registrationInformation[0].secondVolunteerInfo.idStr ? this.registrationInformation[0].secondVolunteerInfo.idStr : '',
            enrollingSchoolName: this.currentGoods2.enrollingSchoolName ? this.currentGoods2.enrollingSchoolName : '',
            enrollingMajorName: this.ckSingle.form.enrollingmajorName ? this.ckSingle.form.enrollingmajorName : '',
            ncSchoolId: this.currentGoods2.enrollingschoolNcId ? this.currentGoods2.enrollingschoolNcId : '',
            ncMajorId: this.ckSingle.form.enrollingmajorNcId ? this.ckSingle.form.enrollingmajorNcId : '',
            enrollingTime: this.ckSingle.enrollingTime ?this.ckSingle.enrollingTime: '',
            enrollingSchoolId: this.currentGoods2.enrollingSchoolId ?this.currentGoods2.enrollingSchoolId:'',
            enrollingMajorId: this.ckSingle.form.enrollingMajorId2 ? this.ckSingle.form.enrollingMajorId2 : '',
            volunteer: 2
          }
          params.registrationInformationList.push(enrolltwo)
          this.getupdateEnrollInfo(params)
        }
        this.paramsts = params
      },
      // 确认第二志愿弹窗
      searchtsOk() {
        let valParams = this.paramsts
        valParams.registrationInformationList.push(this.registrationInformation[0].secondVolunteerInfo)
        console.log('valParams', valParams);
        valParams.registrationInformationList[1].id = valParams.registrationInformationList[1].idStr
        this.getupdateEnrollInfo(valParams)
      },
      cancelts() {
        this.bkModelts = false
      },
      async getupdateEnrollInfo(val) {
        await this.$fetch("thrid_updateEnrollInfo", val).then(res => {
          if (res.code == 200) {
            this.$message.success(res.msg)
            this.getordersj()
            this.bkModelts = false
          } else {
            this.$message.error(res.msg)
          }
          this.cancelbk()
        }).catch(error=>{
              console.log(error);
              this.$message.error(`请求失败:${error.msg}`);
          })
      },

      openbk() {

        this.getfindEnrollingSchoolList()
        this.getCkGoodsSpecs()
        this.getfindEnrollingSchoolListwo()
        this.bkModel = true
      },
       // 选择第一志愿树
      async selectSchoolNcid(value,valueid,name) {
        let gradationId
        let goodsId
        let id
        this.currentGoods.enrollingSchoolName = name
        this.currentGoods.enrollingSchoolId = valueid
        this.currentGoods.schoolNcId = value
        await this.$fetch("third_getfindEnrollingMajorList", {schoolNcId: value, gradationId: gradationId, id: id, goodsId: goodsId}).then(res => {
          if(res.code === 200) {
            res.data.map((item) => {
              if (item.name) {
                item.majorName = item.name
              }
            })
             this.ckSingle.enrollingMajorList = res.data;
          }
        })
      },
      // 第一志愿下拉
      selectCkSingleProfessional(majorId, action) {
        const isGoods1 = action !== '2'
        if(isGoods1) {
          this.firstMajor = majorId
        }
      const dataKey = key => isGoods1 ? key : `${key}2`;

        let index = 0;
        let curEnrollingMajorItem = undefined;
        let enrollingMajorList = this.ckSingle.enrollingMajorList
        console.log('enrollingMajorList', enrollingMajorList);
        for( let i in  enrollingMajorList){
          let item = enrollingMajorList[i];
          if( item.majorId === majorId ){
            index = i;
            curEnrollingMajorItem = item;
            break;
          }
        }
        console.log('curEnrollingMajorItem=====', curEnrollingMajorItem);
        this.curenrolling = curEnrollingMajorItem
      if(Checker.isUndefined(curEnrollingMajorItem)) {
        this.cleanProfessional(isGoods1)
        return
      }
      console.log('enrollingGradationDatasource赋值', this.ckSingle[dataKey('enrollingGradationDatasource')]);
      this.ckSingle[dataKey('enrollingGradationList')] = this.ckSingle[dataKey('enrollingGradationDatasource')] || [];
      // console.log('key学籍', this.ckSingle[dataKey('enrollingGradationList')]);
      this.ckSingle.form[dataKey('enrollingGradationId')] = "";
      //报考专业 majorId, majorName
      console.log('this.graduactionForm', this.graduactionForm);
      // this.graduactionForm.enrollingMajorIdList = curEnrollingMajorItem.majorId
      this.graduactionForm[dataKey('enrollingMajorIdList')] = [
        curEnrollingMajorItem.majorId,
      ];
      this.graduactionForm[dataKey('enrollingMajorNameList')] = [
        curEnrollingMajorItem.majorName,
      ];
      //报考院校 schoolId,   schoolName
      this.graduactionForm[dataKey('enrollingSchoolIdList')] = [
        this[dataKey('currentGoods')].enrollingSchoolId,
      ];
      this.graduactionForm[dataKey('enrollingSchoolNameList')] = [
        this[dataKey('currentGoods')].enrollingSchoolName,
      ];
      //报考省份 provinceId, provinceName
      this.graduactionForm[dataKey('enrollingProvinceIdList')] = [
        this[dataKey('currentGoods')].enrollingProvinceId,
      ];
      this.graduactionForm[dataKey('enrollingProvinceNameList')] = [""];

      //根据选择的报考专业来获取对应的 specificationPriceArr 中的 专业层次
      let specificationPriceItem = this.ckSingle.specificationPriceDataSource[0]
      console.log('specificationPriceItem', specificationPriceItem);
      // this.graduactionForm[dataKey('gradationIdList')] = [
      //   specificationPriceItem.enrollingMajorList[0].gradationId,
      // ];
       console.log('--------enrollingGradationList', this.ckSingle.enrollingGradationList);
        this.setEnrollingGradationId2()
      },
      async selectSchoolNcidtwo(value,valueid,name) {
        console.log('value获取院校选择的schoolNcId2', value);
        
      let gradationId
      let goodsId
      let id
      this.ckSingle.specificationPriceDataSource.map((item) => {
        goodsId = item.goodsId
        id = item.id
        if(item.enrollingMajorList && item.enrollingMajorList.length) {
          gradationId = item.enrollingMajorList[0].gradationId
        }
      })
      this.currentGoods2.enrollingSchoolName = name
      this.currentGoods2.enrollingSchoolId = valueid
      this.currentGoods2.enrollingschoolNcId = value
      
      await this.$fetch("third_getfindEnrollingMajorList", {schoolNcId: value, gradationId: gradationId, id: id, goodsId: goodsId}).then(res => {
        console.log('res.data获取院校2',  res.data);
          if(res.code === 200) {
            res.data.map((item) => {
              if (item.name) {
                item.majorName = item.name
              }
            })
            this.ckSingle.enrollingMajorList2 = res.data;
            this.ckSingle.form.enrollingmajorName = ''
            this.ckSingle.form.enrollingmajorNcId = ''
            console.log('this.ckSingle.enrollingMajorList2', this.ckSingle.enrollingMajorList2);
            this.$forceUpdate()
          }
        })
      },
      // 成考第二志愿，选择报考专业 - 下拉框响应事件
    selectCkSingleProfessional2(majorId){
      console.log('第二志愿', majorId);
      if(this.firstMajor === majorId) {
        this.$message.error("第二志愿专业不能跟第一志愿专业相同")
        this.$nextTick(() => {
          this.ckSingle.form.enrollingMajorId2 = "";
        });
        return false
      }else {
        this.ckSingle.form.enrollingMajorId2 = majorId
      }
      console.log('this.ckSingle.form.enrollingMajorId2', this.ckSingle.form.enrollingMajorId2);
      let enrollingMajorList = this.ckSingle.enrollingMajorList2
      console.log('enrollingMajorList', enrollingMajorList);
      enrollingMajorList.map((item) => {
        if (item.majorId == this.ckSingle.form.enrollingMajorId2) {
          this.ckSingle.form.enrollingmajorName = item.majorName
          this.ckSingle.form.enrollingmajorNcId = item.majorNcId
        }
      })
      // this.selectCkSingleProfessional(majorId, '2')
    },
    // 报考学级下拉
      selectCkSingleGradation(gradationId) {
        console.log('gradationId', gradationId);
        let enrollingGradationItem = this.ckSingle.enrollingGradationList.find( item=> item.id===gradationId ) || {gradation: '', gradationYear: ''}
        this.ckSingle.enrollingTime =
          enrollingGradationItem.gradation;
        this.ckSingle.gradationYear =
          enrollingGradationItem.gradationYear;
        console.log('this.ckSingle', this.ckSingle);
        this.setEnrollingGradationId2()
      },
      // 成考第二志愿，填充与报名商品同等学级
    setEnrollingGradationId2(){
       console.log('this.ckSingle.enrollingGradationList', this.ckSingle.enrollingGradationList);
      if(this.currentGoods.xlType === xlTypeEnum.ck){
        // 已选第二志愿专业
        if(this.ckSingle.form.enrollingMajorId2){
          // 报名商品已选中学级
          if(this.ckSingle.form.enrollingGradationId){
            const hit = this.ckSingle.enrollingGradationList.filter(item => item.id == this.ckSingle.form.enrollingGradationId)
            if(hit.length){
              // 筛选出与报名同等学级
              const same = this.ckSingle.enrollingGradationList2.filter(item => item.gradation == hit[0].gradation)
              if(same[0]){
                this.ckSingle.form.enrollingGradationId2 = same[0].id
              }else{
                this.ckSingle.form.enrollingGradationId2 = ''
              }
            }else{
              this.ckSingle.form.enrollingGradationId2 = ''
            }
          }else{
            this.ckSingle.form.enrollingGradationId2 = ''
          }
        }else{
          this.ckSingle.form.enrollingGradationId2 = ''
        }
      }
     
    },
      // 获取院校
    async getfindEnrollingSchoolList () {
      const { data } = await this.$fetch("third_getfindEnrollingSchoolList");
      console.log('data院校', data);
      data.map((item) => {
        if (item.children) {
          item.disabled = true
          item.children.map((i) => {
            if(!i.schoolNcId) {
              i.disabled = true
            }
          })
        }
      })
      this.findSchoolList = data
    },
    // 获取院校2
    async getfindEnrollingSchoolListwo () {
      const { data } = await this.$fetch("third_getfindEnrollingSchoolList");
      console.log('data院校', data);
      data.map((item) => {
        if (item.children) {
          item.disabled = true
          item.children.map((i) => {
            if(!i.schoolNcId) {
              i.disabled = true
            }
          })
        }
      })
      this.findtwoSchoolList = data
    },
    /********************成考相关数据请求******************************************************************** */
    async getCkGoodsSpecs() {
      let form = {
        goodsId: this.goodsData[0].goodsId,
        businessId: businessIdEnum.xl,
      };
      // enrollingGradationList:学级列表 enrollingMajorList:专业列表
      const { data } = await this.$fetch("third_getfindSpByIdGoodsId", form);

      let _specificationPriceArr = data.specificationPriceArr || []
      let _enrollingMajorList = data.enrollingMajorList || []
      const enrollingGradationList = data.enrollingGradationList || []
      const enrollingMajorList = _enrollingMajorList
      const specificationPriceArr = _specificationPriceArr
      const goodsId = data.goodsId
      const xlType = data.xlType

      this.ckSingle.enrollingGradationList = enrollingGradationList

      console.log('this.ckSingle.enrollingGradationList', this.ckSingle.enrollingGradationList);
      // 第一志愿
      this.ckSingle.enrollingGradationDatasource = enrollingGradationList;
      this.ckSingle.enrollingMajorList = enrollingMajorList;
      this.ckSingle.specificationPriceDataSource = specificationPriceArr;
      this.ckSingle.goodsId = goodsId;
      this.ckSingle.xlType = xlType;
      console.log('第一志愿选择', this.ckSingle.specificationPriceDataSource);

      if (this.secondEnrollingSchoolName == '') {
         
      } else {
        // 第二志愿
          const checkResult = this.checkCKGoods2(enrollingMajorList, enrollingGradationList)
          
          this.ckSingle.enrollingGradationDatasource2 = enrollingGradationList;
          // this.ckSingle.enrollingMajorList2 = enrollingMajorList;
          this.ckSingle.specificationPriceDataSource2 = specificationPriceArr;
          this.ckSingle.goodsId2 = goodsId;
          this.ckSingle.xlType2 = xlType;
      }
    },
    // 检查成考第二志愿与报名的商品同一个层次、同一个学级
    checkCKGoods2(enrollingMajorList2, enrollingGradationDatasource2){
      let sameEnrollingMajor = []//层次
      let sameEnrollingGradation = false//学级
      const enrollingMajorList1 = this.ckSingle.enrollingMajorList
      const enrollingGradationDatasource1 = this.ckSingle.enrollingGradationDatasource
      console.log('enrollingMajorList1', enrollingMajorList1);
      console.log('enrollingMajorList2', enrollingMajorList2);
      // 先排查层级
      enrollingMajorList1.map(i => {
        const hit = enrollingMajorList2.filter(j => j.gradationId === i.gradationId)
        sameEnrollingMajor.push(hit.length > 0)
      })
      // 排查学级
      enrollingGradationDatasource1.map(i =>{
        enrollingGradationDatasource2.map(j =>{
          if(i.gradation === j.gradation){
            // 有学级相同
            sameEnrollingGradation = true
          }
        })
      })

      return {
        sameEnrollingMajor: sameEnrollingMajor.filter(item=> item).length >= enrollingMajorList1.length && sameEnrollingMajor.length > 0,
        sameEnrollingGradation
      }
    },
    // 清除成考专业
    cleanProfessional(isGoods1) {
      const dataKey = key => isGoods1 ? `${key}` : `${key}2`;
      this.ckSingle.gradationYear = ''
      this[dataKey('receiveItemData')] = []
      this.ckSingle.form[dataKey('enrollingMajorId')] = ''
      this.ckSingle.form[dataKey('enrollingGradationId')] = ''
      // 清除第一志愿专业。需要处理第二志愿专业
      if(isGoods1){
         this.ckSingle.form.enrollingMajorId2 = ''
         this.ckSingle.form.enrollingGradationId2 = ''
         this.receiveItemData2 = []
      }
    },


    // ---------------------------
      // 判断是否可以取消和关闭订单
    async checkOutOrderCancelAndClose(orderId) {
      const { data = {}, code } = await this.$fetch('checkOutOrderCancelAndClose', { orderId: orderId })
      if(code === 200) {
        this.cancelOrClose = data
      }
    },
      getAftersaleGetOriginOrder(orderId) {
        this.$fetch("aftersaleGetOriginOrder", { newOrderId: orderId }).then(res => {
          if(res.data) {
            this.oldOrderNo = res.data.orderNo
            const name = res.data.goodsSpecName?`（${res.data.goodsSpecName}）`:""
            this.oldGoodsName = `${res.data.goodsName}${name}`
          }
        })
      },
      // 获取凭证列表（为了看要不要展示收款确认按钮）
    getAllVoucherDetails(orderId) {
      this.$fetch("third_GetqueryPayVoucherDetailsByOrderId", {orderId: orderId}).then(res => {
        if(res.code === 200) {
          this.allCollectionInformation = res.data || []
        }
      })
    },
      // 获取收费项目
    getQueryOrderInOutProjectDto(orderId) {
      this.$fetch("third_getQueryOrderInOutProjectDto", { orderId }).then(res => {
        if(res.code === 200) {
          this.findOrderInOutProject(res.data)
        }
      })
    },
    // 收费项目合计
    getTotal(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }
        const values = data.map(item => Number(item[column.property] * 1000));
        if (column.property === 'standardPrice' || column.property === 'goodsDiscount' || column.property === 'upClassDiscount' || column.property === 'receivable') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index] / 1000;
        } else {
          sums[index] = '--';
        }
      });
      return sums;
    },
               /***
  * 获取成交信息
  */
  getTransactionInfo(){
    let form = {
      orderId: this.orderId
    }
    getTransactionInfoByOrderId(form).then( res=>{
      if( res.code === 0 ){
        this.transactionInfo = res.result || {};
        console.log('this.transactionInfo.crmDealSchoolDeptId', this.transactionInfo.crmDealSchoolDeptId);
        this._getDeptorderId(this.transactionInfo.crmDealSchoolDeptId)
        Object.keys(this.transactionInfo).forEach( key=>{
            if( key == "isFourSix" || key == "isRecommand" ){
              this.transactionInfo[`${key}Name`] = this.transactionInfo[key] == 1 ? '是': '否'
            }
        })
        
        if( !Array.isArray(this.userData) ){
          this.userData = [];
        }

        /***
          * customerCode   
          * phoneNumber
          * subscribeSchoolName 成交校区
          * signSchoolName   上课校区
          * saleSource   销售渠道
          
          * ncCustomerUserId  显示在商机编号
          */
        let ncNeedParameter = {
          subscribeSchoolName: this.transactionInfo.dealSchoolName,
          signSchoolName: this.transactionInfo.campusSchoolName
        }
        let name = this.userData[0].name
       let temp = Object.assign(this.userData[0], this.transactionInfo, ncNeedParameter);
       if(name){
          temp.name = name
        }
        this.userData = [{...temp}]
         this.userData[0].customerCode = this.userData[0].customerId;
         console.log('this.userData', this.userData);
        //获取协助成交人员列表数据
        this.getAssistDealList()
      }
    })
  },
  // 获取协助成交人员列表数据
  getAssistDealList() {
      const arr = [this.transactionInfo.crmDealSchoolDeptId]
      axios.post(`/qw_api/wb/biz/userExtend/getTeacherList?requireOld=false`, arr).then(response => {
        const res = response.data
        if(res.code === 0){
          this.assistDealList = res.result.map((item) => {
            return {
              assistDealNcUserId:item.ncUserPk,
              assistDealCrmUserId:item.userId,
              assistDealUserName:item.userName
            }
          })
        }else{
          this.$message.error(res.msg)
        }
      }).catch( () => {
        this.$message.error(error.msg)
      })
    },
  // 通过部门拿成交信息
  _getDeptorderId(val) {
    getDeptId(val).then(res => {
      console.log('通过部门拿到成交信息',res);
      this.property = res.result.property
    })
  },

        
    // 转班页跳转
    goTransferPage() {
      this.$router.push(`/afterSale/transfer?orderId=${this.orderId}`)
    },
    //升班页跳转
    goUpgradePage() {
      this.$router.push(`/afterSale/upgrade?orderId=${this.orderId}`);
    },
    // 退费页跳转
    goDropOutPage() {
      this.$router.push(`/afterSale/dropout?orderId=${this.orderId}`);
    },



    //返回
    cancel() {
        console.log('this.$router',this.$router, this.$route);
        if (this.$route.query && this.$route.query.pathName && this.$route.query.pathName == "changerouter") {
          this.$router.push("/afterSale/change")
        } else {
          this.$router.push("/afterSale/order")
        }
    },

    
  reject(){
      this.showRejectModalVisible = false;
        setTimeout(()=>{
          this.showRejectModalVisible = true;
        })    
  },


  // 驳回协议
  rejectOk( rejectReason ){
    this.showRejectModalVisible = false;
    let contractRecordId = this.contractRecord.id;
    this.changeStatus(rejectReason, 4, contractRecordId);
  },

  /**
   * 通过
  */
  showPassDialog(){
      let rejectReason = '';
      let contractRecordId = this.contractRecord.id;
      this.$hqMessageBox({
          title: '提示',
          message:'确认审核通过吗?'
      }).then(() => {
          this.changeStatus(rejectReason, 3, contractRecordId);
      })
  },

  // 审核记录
  checkRecord(){
    // // 如果没有协议id的话就重新调用接口查询
    if (this.contractRecord.id === undefined) {
        this.getContractRecord();
    }
    this.$refs.historyDrawer.init()
    this.showHistoryAuditRecordVisible = true
  },

  // 根据订单id获取协议状态数据
  getContractRecord() {
      if(this.orderId){
          this.$fetch('findByOrderId', {orderId: this.orderId}).then(response=>{
              let result = response.data || {};
              if (result !== undefined && result.length > 0) {
                  this.contractRecord=result[0];
              }
          }).catch(error=>{
              console.log(error);
              this.$message.error(`请求失败:${error.message}`);
          })
      }
  },
       
  //状态切换
  changeStatus(row, status, recordId){
    let getTyUserInfo = JSON.parse(localStorage.getItem("tyUserInfo")) || {};
      let obj = {
          id : recordId,
          auditStatus: status,
          auditMsg: row,
          //审核人名称
          auditUserName: getTyUserInfo['nickName'] || getTyUserInfo["userName"]
      }
        
      this.$fetch("docontractAudit", obj).then(response => {
              if (response.code == 200) {
                  this.$message({message: '操作成功', type: 'success'});
                  if (status + '' === "4") {
                      this.$set(this.userData[0] ,"signContractStatus", '驳回')
                  } else if (status + '' === "3") {
                      this.$set(this.userData[0] ,"signContractStatus", '通过')
                  }
                  const statusStr = String(status)
                  if(['3', '4'].includes(statusStr)){
                    // 查找协议类型
                    const index = this.orderData.findIndex(item => item.orderType === '2')
                    if(index > -1){
                      this.$set(this.orderData[index],"orderStatus", statusStr === '3' ? '通过' : '驳回');
                    }
                  }

              } else {
                  this.$message.error(response.data.msg);
              }
          }).catch(error => {
          console.log(error);
          this.$message.error(`请求失败:${error.message}`);
      })
    },

    userPay() {
        // 商品名称
        let orderId = this.orderId;
        this.$fetch("thrid_getOrderDetailInfo", {orderId}).then( (response={}) => {
        let arr = response.data.orderAllStatusVos.filter(item => {
          return item.orderStatus == '待支付' || item.orderStatus == '支付中'
        })

        if(arr.length) {
          this.$router.push(`/afterSale/collection?orderId=${orderId}&property=${this.property}`);
        } else {
          this.$message.error('商品支付状态已更新，页面将重新加载')
          setTimeout(() => {
            this.$router.go(0)
          }, 1500)
        }
      })
    },
    // 收款确认跳转收款审批详情
    goCollection(id) {
      this.$router.push(`/afterSale/collection-detail?id=${id}`)
    },
    userSign(){
        this.$router.push(`/afterSale/payComplete?orderId=${this.orderId}`)    
    },
    // 重推协议
    signOnceClick() {
      this.$hqMessageBox({
          title: '提示',
          message:'确认重推协议吗?'
        }).then(() => {
          this.$fetch("doBatchRefresh", {}, {orderIds: this.$route.query.orderId}).then(res => {
            this.$message.success(res.msg)
            setTimeout(() => {
              this.$router.go(0)
            }, 1500)
          }).catch(res => {
            this.$message.error(res.msg)
          })
        }).catch(() => {
        })
    },
    paymentDetail() {
      this.$refs.drawer.init()
        this.showCollectionDetailSingleVisible = true;
    },
    
    repaymentProject(row) {
        this.orderId = row.orderId;
        this.showRepaymentDetailVisible = true;
    },
            goodsDetail() {
                this.showGoodDetailVisible = true;
                this.$refs.goodsDetail.init()
            },
            closeEdit(){
                this.$set(this ,"edit", false)
            },
            openEdit(){
                this.$set(this ,"edit", true)
                // 不需要走天翼的逻辑，直接全网对接
                // this.findCustomerInfo();
                this.$set(this ,"saveFlag", 0)
            },
            editSave() {
              //协助成交人员
              if(this.userData[0].assistDealCrmUserId){
                this.assistDealList.forEach(el => {
                  if(el.assistDealCrmUserId === this.userData[0].assistDealCrmUserId){
                    this.userData[0].assistDealNcUserId = el.assistDealNcUserId
                    this.userData[0].assistDealUserName = el.assistDealUserName
                  }
                });
              }

              if(!this.userData[0].promotionForm) {
                this.userData[0].promotionForm = ""
              }
              doOrderDetailUpdate(this.userData[0]).then(data => {
                if(data.code === 0) {
                  this.$message.success(data.msg)
                  this.edit = false
                  this.getTransactionInfo()
                  this.$set(this ,"saveFlag", 1)
                } else {
                  this.$message.error(data.msg)
                }
              })
            },
            // 查看协议
            signDetail() {
              // this.showSignDetailVisible = true;
              // this.$refs.agree.getDataList(this.orderId)
              const params = {
                orderId: this.orderId,
                token: this.userData[0].userId
              }
              this.$fetch("getPreviewUrlForBestSign", params).then(res => {
                if(res.code == 200) {
                  window.open(res.data)
                } else {
                  this.$message.error(res.msg)
                }
              })
            },
            // 下载协议
            downloadSignDetail() {
              const fileName = this.toGoodsName + '_' + this.goodsData[0].commodityName + '_' + this.userData[0].name + '_' + this.orderData[2].paymentStatus + '.pdf'
              download(`/qw_school/schoolcj/order/api/contract/web/download?orderId=${this.orderId}&contractRecordId=${this.orderId}`, fileName)
            },
            serviceDetail2() {
              this.showServiceDetailSingle = true;
            },
            formatDate(data) {
                if (!data) {
                    return '';
                }
                let dt = new Date(data);
                let dtYear = dt.getFullYear()
                let dtMonth = dt.getMonth()+1<10 ? '0'+(dt.getMonth()+1) : dt.getMonth()+1;
                let dtDate = dt.getDate()<10 ? '0'+dt.getDate() : dt.getDate();
                let dtHours = dt.getHours()<10 ? '0'+dt.getHours() : dt.getHours();
                let dtMin = dt.getMinutes()<10 ? '0'+dt.getMinutes() : dt.getMinutes();
                return dtYear + '-' + dtMonth + '-' + dtDate + ' ' + dtHours + ':' + dtMin;
            },
            formatFormAllData(row, column) {
                switch (column.property) {
                    case "state":
                        return this.newFormatDetailData(row[column.property], this.payStatusArr);
                        //支付状态
                    case "orderStatus":
                        switch (row.orderType) {
                            case "1": //支付状态
                                return this.newFormatDetailData(row.orderStatus, this.paymentStatusArr)
                            case "2": //协议状态
                                return this.newFormatDetailData(row.orderStatus, this.signAgreementStatusArr)
                            case "3": //权限状态
                                return this.newFormatDetailData(row.orderStatus, this.permissionOpeningStatusArr)
                            case "4": //学习状态
                                return this.newFormatDetailData(row.orderStatus, this.courseLearningStatusArr)
                            case "5": //售后状态
                                return row.orderStatus;
                        }
                        return this.newFormatDetailData(row[column.property], this.payStatusArr);
                    case "allPay":
                        return this.formatDetailData(row[column.property], this.allPayArr);
                    case "isPayDownPayment":
                        return this.formatDetailData(row[column.property], this.allPayArr);
                    // case "payType":
                    //     return this.formatDetailData(row[column.property], this.payTypeArr, 1);
                    case "payMode":
                      return this.formatDetailData(row[column.property], this.payModeList, 1);
                    case "downPaymentInstallmentType":
                        return this.formatDetailData(row[column.property], this.payTypeArr);
                    case "saleSource":
                        return this.formatDetailData(row[column.property], this.saleSourceArr);
                    case "amountReceived":
                        return this.formatDetailData(row[column.property], this.blankArr, 1);
                    case "orderType":
                        return this.formatDetailData(row[column.property], this.orderTypeArr, 1);
                    case "signContractStatus":
                        return this.formatDetailData(row[column.property] + "", this.signAgreementStatusArr);
                    case "paymentStatus":
                        return this.formatDetailData(row[column.property], this.paymentStatusArr, 1);
                    case "permissionOpeningStatus":
                        return this.formatDetailData(row[column.property], this.permissionOpeningStatusArr, 1);
                    case "courseLearningStatus":
                        return this.formatDetailData(row[column.property], this.courseLearningStatusArr, 1);
                    case "aftetSaleStatus":
                        return this.formatDetailData(row[column.property], this.aftetSaleStatusArr, 1);
                    case "tradeSerialNumber":
                        return this.formatDetailData(row[column.property], this.blankArr,1);
                    case "tradeNo":
                        return this.formatDetailData(row[column.property], this.blankArr,1);
                    default:
                        return "";
                }
            },
            formatDetailData(data, list, type) {
                if (type != undefined && (data == "" || data == undefined)) {
                    return "--";
                }
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === data) {
                        return list[i]["Text"];
                    }
                }
                return data;
            },
            newFormatDetailData(data, list, value) {
                if (value != undefined) {
                    if (data != undefined && data != value) {
                        return "--";
                    }
                }
                for (let i = 0; i < list.length; i++) {
                    if (list[i].id === data) {
                        return list[i]["Text"];
                    }
                }
            },
            numberFormatter(row, column) {
                let data = row[column.property];
                if (data == undefined) {
                    return "0.00";
                }
                return data.toFixed(2);
            },
            isEmptyFun(row, column) {
                let data = row[column.property];
                if (data == undefined || data == "") {
                    return "--";
                }
                return data;
            },
            addInoutFun() {
               this.$hqMessageBox({
                    title: '提示',
                    message: '确定要补充收支项目吗(仅限调试模式用)'
                    }).then(() => {
                        this.loading = true;
                        let OrderInOutProjectVo = {};
                        OrderInOutProjectVo["orderId"] = this.orderId;
                        this.$fetch('addInoutByOrderId', OrderInOutProjectVo).then(response => {
                            let result = response.data;
                            if (result !== undefined && result > 0) {
                                // this.findOrderInOutProject(this.orderId);
                            } else if (result === 0) {
                                this.$message.error(`订单待支付`);
                            }
                        }).catch(error => {
                            this.$message.error(`请求失败:${error.message}`);
                        }).finally(() => {
                            this.loading = false;
                        })
                    }).catch(() => {
                    })
            },



      updateOrderDetails( orderDetails ){
        this.allPayText = this.formatDetailData(orderDetails["allPay"], this.allPayTextArr)
        this.orderUpdateTime = this.formatDate(orderDetails["updateTime"]);
        this.orderCreateTime = this.formatDate(orderDetails["createTime"])
        this.orderNo = orderDetails["orderNo"];
        this.orderStatus = this.formatDetailData(orderDetails["orderStatus"], this.orderStatusArr);
        this.userId = orderDetails["userId"];
        this.subOrderId = orderDetails["subOrderId"];
        this.payPrice = orderDetails["payPrice"];//应收金额
        this.amountReceived = orderDetails["amountReceived"];//已收金额
        this.businessId = orderDetails["businessId"];
        this.ncState = orderDetails["ncState"];// 推送状态
        this.sellType = orderDetails["sellType"];
        this.ios = orderDetails["isIos"];
        let crmUrl
        if (process.env.VUE_APP_TITLE === "development") {
          crmUrl = "http://crm.dev.hqjy.com"
        } else if (process.env.VUE_APP_TITLE === "testing") {
          crmUrl = "http://crm.hqbis.com:18080"
        } else if(process.env.VUE_APP_TITLE === "beta") {
          crmUrl = "http://crm.beta.hqjy.com"
        } else if(process.env.VUE_APP_TITLE === "production") {
          crmUrl = "http://crm.hqjy.com"
        }
        this.goodsTemplate = orderDetails["goodsTemplate"];// 判断是否是组合套餐
        this.queryQWParam.url= crmUrl + "/api/api/open/v1/findCustomerInfo" ;
        this.queryQWParam.createUrl= crmUrl + "/api/api/open/v1/receivefromThirdParty" ;
        // 如果是锁单的话就不给点推送按钮
        if (orderDetails["isLock"] + '' === "1") {
            this.isLock = true;
            this.sendNcButton = true;
        } else {
            // 订单状态不是订单关闭才可以推送
            if (orderDetails["orderStatus"] + '' !== "20") {
                // 如果是正价课才能推送
                if ("1" === this.sellType + '') {
                    // 支付中、支付完成、贷款中、贷款成功、贷款失败 可以推送nc 其他状态不能推送
                    if (orderDetails["state"] + '' === "2" || orderDetails["state"] + '' === "3" || orderDetails["state"] + '' === "6" || orderDetails["state"] + '' === "7" 
                    || orderDetails["state"] + '' === "8" ) {
                        if (orderDetails["ncState"] + '' === "0") {
                            this.sendNcButton = true;
                        } else {
                            this.sendNcButton = false;
                        }
                    } else {
                        this.sendNcButton = true;
                    }
                } else {// 否则也不能
                    this.sendNcButton = true;
                }
            } else {
                this.sendNcButton = true;
            }
        }
      },

        findGoods( goodsInfo = {}) {
                this.toGoodsTypeName = goodsInfo["goodsTypeName"];
                this.toProductName = goodsInfo["productName"];
                this.toGoodsName = goodsInfo["goodsName"];
                this.toGoodsId =goodsInfo["goodsId"];
                this.toCommodityName = goodsInfo["commodityName"];
                this.goodsData = [goodsInfo];
            },


        findUsers(usersDetails={}) {
            let resultDoc = usersDetails || {};
            if (resultDoc.userId != undefined) {
            if (resultDoc.signContractStatus === 23 || resultDoc.signContractStatus === 24 || resultDoc.signContractStatus === 25) {
                this.btnChangeEnable = false;
            }
            if (resultDoc.signContractStatus === 21) {
                this.btnChangeHiden = false;
            }
            if (resultDoc.orderStatus !== 20 && (resultDoc.signContractStatus === 23 || resultDoc.signContractStatus === 24)) {
                this.rejectEnable = false;
            }
            resultDoc.signContractStatus = this.formatDetailData(resultDoc.signContractStatus + "", this.signAgreementStatusArr, 1);
            if (resultDoc['payStatus'] !== 1) {
                this.tobePaid = true;
            }
            let doc = {
                ppp:'1',
                ...resultDoc
            } 

              let saleSource = resultDoc.saleSource
              let saleSourceValue = this.saleSourceList[saleSource];
              if( saleSourceValue ){
                resultDoc["saleSourceName"] = saleSourceValue;
              }
            this.saleSourceName = saleSourceValue
            this.userTempArr.push(doc);
            
            let firstUserDataItem = this.userData[0] || {};
            this.userData = [{...firstUserDataItem, ...resultDoc}];
            this.queryQWParam.Param.msgBody.phone = resultDoc["mobile"]
            this.queryQWParam.Param.msgBody.name = resultDoc["name"]
         } 
        },
        paymentDetailFun( adminWxPayTypeTradeOrder={} ) {
            this.orderTypeText = this.formatDetailData(adminWxPayTypeTradeOrder["orderType"], this.orderTypeArr, 1);
            this.paymentData = [{
            ...adminWxPayTypeTradeOrder,
            completionTime: this.formatDate(adminWxPayTypeTradeOrder["completionTime"]),
            // payType: this.ios==1 ? 99: '',
            }];
        },
        okFun() {
            this.dialogFormVisible = false;
            this.serviceData = [];
        },
        findOrderStatus( findOrderStatus=[] ) {
            this.orderData = findOrderStatus;
        },
        findOrderInOutProject(orderInoutProjectList=[]) {
            this.inoutProjectData = orderInoutProjectList;
        },

        
        findOrderRegistrationInformationVoByOrderId(result=[]) {
          this.registrationInformation = result.length? result : [{}];
            // this.$fetch('findOrderRegistrationInformationVoByOrderId',{
            //     orderId
            // }).then(response => {
            //     let result = response.data || [];
            //     if (result.length > 0) {
            //         this.registrationInformation = result;
            //     }
            // }).catch(error => {
            //     console.log(error);
            //     this.$message.error(`请求失败:${error.message}`);
            // })
        },


        afterSaleView(row) {
            //售后id
         let  aftersaleId = row.orderOperator;
         //售后类型
         let aftersaleType = row.orderTypeStatus;
         console.log(row)
          // 去售后单详情页
          this.$router.push({
          path:"/afterSale/afterSaleDetail",
          query: {
              id: aftersaleId,
              type: aftersaleType
          }
          })
        },
        // 关闭订单
        toCloseOperation() {
          this.$hqMessageBox({
            title: '关闭订单',
            message: '此操作将关闭订单，是否继续？'
          }).then( async ()=>{
              const { msg, code } = await this.$fetch("doCloseAuthorityByAuthority", {orderNo: this.orderNo})
              if (code === 200) {
                this.$message.success(msg)
                this.checkOutOrderCancelAndClose(this.orderId)
              } else {
                this.$message.error(msg)
              }
          });
        },
        // 取消订单
        async toRemoveOperation(){
          const { orderId } = this.$route.query
          //根据用户手机号获取用户信息
          // let { data={}, code } = await this.$fetch("getUserByMobile", {  mobile: this.transactionInfo.phoneNumber });
          // if( code != 200 || !data.userId ){
          //   this.$message.error("学员信息不存在，无法发起取消订单")
          //   return;
          // }
          if(!this.userData[0].userId) {
            this.$message.error("学员信息不存在，无法发起取消订单")
            return;
          }
          //rowIndex
            this.$hqMessageBox({
              title: '取消订单',
              message: this.cancelOrClose.cancelAlert || "确认取消订单？",
            }).then( async ()=>{
              const params = {
                  orderId: orderId,
                  id: orderId,
                  userId: this.userData[0].userId
                }
                this.$fetch("thrid_cancelOrderByOrderId", params).then(res => {
                  const { msg, code } = res
                  if (code === 200) {
                    this.$message.success("取消订单成功，即将返回订单列表")
                    setTimeout(() => {
                      this.$router.push('/afterSale/order')
                    }, 1500);
                  } else {
                    this.$message.error(msg)
                  }
                }).catch(error => {
                this.$message.error(error.msg)
              });
            })
        },
        dateFormat:function(time) {
            let date=new Date(time);
            let year=date.getFullYear();
            let month= date.getMonth()+1<10 ? "0"+(date.getMonth()+1) : date.getMonth()+1;
            let day=date.getDate()<10 ? "0"+date.getDate() : date.getDate();
            let hours=date.getHours()<10 ? "0"+date.getHours() : date.getHours();
            let minutes=date.getMinutes()<10 ? "0"+date.getMinutes() : date.getMinutes();
            let seconds=date.getSeconds()<10 ? "0"+date.getSeconds() : date.getSeconds();
            // 拼接
            return year+"-"+month+"-"+day+" "+hours+":"+minutes+":"+seconds;
        },


        findCustomerInfo() {
            axios({
                method: 'get',
                url: this.queryQWParam.url + "?phone=" + this.queryQWParam.Param.msgBody.phone + "&trackType=2",
            })
                .then(response => {
                    let data = response.data||{};
                    let result = data.result||{};
                    if (result["affiliationName"] !== undefined && result["affiliationName"] !== '') {
                        this.$set(this ,"affiliationName", result["affiliationName"])
                    }
                    if (result["ncId"] !== undefined && result["ncId"] !== '') {
                        this.$set(this.userData[0] ,"customerCode", result["ncId"])
                    } else {
                        if (this.reCreateSJ) {
                            this.$message.warning(`获取全网商机数据失败,请检查此手机是否存在于全网系统!`);
                        } else {
                            this.receiveFromThirdParty();
                        }
                    }
                    if ((result["teacherRecruitName"] !== undefined && result["teacherRecruitName"] !== '') || (result["teacherSalesName"] !== undefined && result["teacherSalesName"] !== '')) {
                        this.$set(this ,"teacherRecruitName", result["teacherRecruitName"] === '' || result["teacherRecruitName"] === undefined ? result["teacherSalesName"] : result["teacherRecruitName"])
                    }
                    if (result["phoneNumber"] !== undefined && result["phoneNumber"] !== '') {
                        this.$set(this ,"phoneNumber", result["phoneNumber"])
                    }
                    this.saveExtraDoc.projectCode = "CJSD";// 现在暂时写死cjsd
                    this.saveExtraDoc.crmName = result["recruitChannelsName"];
                    this.saveExtraDoc.crmCode = result["channelsCode"];
                    this.saveExtraDoc.crmPkSupplier = result["pkSupplier"];
                    this.saveExtraDoc.economicFlowPartner = result["economicFlowPartner"];
                    this.saveExtraDoc.economicFlowPartnerPhone = result["economicFlowPartnerPhone"];
                    this.saveExtraDoc.economicFlowPartnerCode = result["economicFlowPartnerCode"];
                    this.saveExtraDoc.flowPartner = result["flowPartner"];
                    this.saveExtraDoc.flowPartnerPhone = result["flowPartnerPhone"];
                    this.saveExtraDoc.flowPartnerCode = result["flowPartnerCode"];
                }).catch(error => {
                console.log(error);
                this.$message.error(`请求失败:${error.message}`);
            }).finally(() => {
                this.loading = false;
            })
        },
        receiveFromThirdParty() {
            this.reCreateSJ = true;
            let param = {
                "msgBody":{},
            };
            let msgBody = {};
            param["msgType"] = 2;
            msgBody["source"] = 10;
            msgBody["phone"] = this.queryQWParam.Param.msgBody.phone;
            msgBody["name"] = this.queryQWParam.Param.msgBody.name;
            msgBody["admissionsChannel"] = "恒企网校";
            msgBody["channelName"] = "恒企网校";
            msgBody["createTime"] = this.dateFormat(new Date());
            param["msgBody"] = msgBody
            axios.post(this.queryQWParam.createUrl, param)
                .then(response => {
                    let data = response.data||{};
                    if (data + '' === "0") {
                        this.findCustomerInfo();
                    } else {
                        this.$message.warning(data.msg);
                    }
                })
                .catch(() => {
                    this.$message.warning("再次创建商机失败!");
                })
         },

      //根据售后类型 检查订单是否能发起售后
      checkOrderByAfterSaleType(){
          let getTyUserInfo = JSON.parse(localStorage.getItem("tyUserInfo")) || {};
          if( !getTyUserInfo.dingtalkUserId ){
              this.turnClass = false;
              this.refund    = false;
              this.$message.error("该用户没有钉钉id,不能进行转班，退费操作")
          }

          this.$fetch('checkOrderByAfterSaleType', {
              orderIdList: [this.orderId]
          }).then( res=>{
              if( res.code == 200 ){
                let data = res.data;
                // this.turnClass = data.turnClass && getTyUserInfo.dingtalkUserId;
                // this.upClass   = data.upClass ;
                // this.refund    = data.refund && getTyUserInfo.dingtalkUserId;

                this.turnClass = data.turnClass;
                this.upClass   = data.upClass;
                this.refund    = data.refund;

                this.turnClassMsg = data.turnClassMsg
                this.upClassMsg = data.upClassMsg
                this.refundMsg = data.refundMsg
              }
             
          }).catch( error=>{
              console.log(error)
          })
      },
        // 判断是否可以取消和关闭订单
        async checkOutOrderCancelAndClose(orderId) {
          const { data = {}, code } = await this.$fetch('checkOutOrderCancelAndClose', { orderId: orderId })
          if(code === 200) {
            this.cancelOrClose = data
          }
        },
        // 学员信息操作
        editEndSaveClick(row) {
          if(this.editStudentInfo) {
            if(!row.education) return this.$message.error("请选择学历！")
            this.$fetch("doIdCardValidate", {}, {idCard: row.idCard}).then(async res => {
              await this.$fetch("doSaveUser", {idCard: row.idCard, userId: row.userId, mobile: row.mobile, realName: row.name})
              await this.$fetch("updateOrderEducation", { customerId: this.transactionInfo.customerId, orderId: row.orderId, education: row.education })
              await this.$fetch("thrid_updateOrdert", {orderId: row.orderId, emergencyContact: row.emergencyContact, emergencyContactPhone: row.emergencyContactPhone })
              this.$fetch("doUpdateOrderPhone", {
                orderId: row.orderId, 
                idCard: row.idCard, 
                phoneNumber: row.mobile, 
                stuName: row.name, 
                realName: row.name, 
                contractRecordId: this.contractRecord.id, 
                token: row.userId,
              }).then(res => {
                this.$message.success('保存成功')
                this.editStudentInfo = !this.editStudentInfo
                setTimeout(() => {
                  this.$router.go(0)
                }, 1000);
              })
            }).catch(res => {
              if(res.code == 500) {
                return this.$message.error(res.msg)
              }
            })
          } else {
            this.editStudentInfo = !this.editStudentInfo
          }
        },
        getordersj(){
          this.$fetch("thrid_getOrderDetailInfo", {orderId: this.orderId}).then(response => {
                    response = response.data;

                    //用户详情详细
                    let usersDetails = response.usersDetails || {};
                    this.findUsers(usersDetails);    

                    //订单详情
                    let orderDetails = response.orderDetails;
                    this.updateOrderDetails(orderDetails);   

                    this.canSelectMajor = response.canSelectMajor;
                    this.secondEnrollingSchoolName = response.orderRegistrationInformationVo[0].secondEnrollingSchoolName ? response.orderRegistrationInformationVo[0].secondEnrollingSchoolName : ''
                    //查询商品数据
                    let goodsDetails = response.goodsDetails;
                    if( response.orderRegistrationInformationVo && response.orderRegistrationInformationVo.length  ){
                                          goodsDetails.ncCommodityId = response.orderRegistrationInformationVo[0].ncCommodityId
                    goodsDetails.ncSchoolId = response.orderRegistrationInformationVo[0].ncSchoolId
                    goodsDetails.ncMajorId = response.orderRegistrationInformationVo[0].ncMajorId
                    }

                    this.findGoods(goodsDetails);

                    //查询支付信息
                    let adminWxPayTypeTradeOrder = response.adminWxPayTypeTradeOrder;
                    this.paymentDetailFun(adminWxPayTypeTradeOrder);

                    //查询订单的所有状态
                    let findOrderStatus = response.orderAllStatusVos;
                    this.findOrderStatus(findOrderStatus);

                    this.whetherHaveVoucher = response.whetherHaveVoucher
                        
                        // 收费项目单独调接口 订单9期
                        // let orderInoutProjectList = response.orderInoutProjectList || [];
                        // if (this.goodsTemplate === undefined || this.goodsTemplate !== 6) {
                        //         this.findOrderInOutProject(orderInoutProjectList);// 查询订单的收支项目
                        // }
                        // this.findOrderRegistrationInformationVoByOrderId(this.orderId);// 查询学历报考信息
                        // 学历报考信息
                        const orderRegistrationInformationVo = response.orderRegistrationInformationVo || {}
                        this.findOrderRegistrationInformationVoByOrderId(orderRegistrationInformationVo)
                        
                        let tempArr = this.tempArr;
                        this.tempArr = tempArr;
                        if(orderDetails["installmentType"] !== undefined&& orderDetails["installmentType"] !== 0) {
                            this.loanMethod = true;
                        }
                        this.xlType = orderDetails["xlType"];
                        

                        // 转校才判断
                        if(Number(this.$route.query.transferSchool)) {
                          getQuerySchoolList().then(res => {
                            this.campusList = res.result
                          })
                        }

                        this.getTransactionInfo();
                    })
                    .catch(error => {
                        this.$message.error(`请求失败:${error.message}`);
                    })
        }

        },

        computed: {
            ...mapGetters(["loginUser", 'getTyUserInfo']),
            // 判断是否可以做售后操作
            canAfterSale() {
              let showAfaterSale
              if(Number(this.$route.query.transferSchool)) {
                // 转校
                if(((this.loginUser.dataScope == 0 || this.loginUser.dataScope == 1 || this.loginUser.dataScope == 3) && this.userData.length && this.campusList.filter(item => item.deptName == this.userData[0].campusSchoolName).length) || this.loginUser.dataScope == 2) {
                // if(((this.loginUser.dataScope == 0 || this.loginUser.dataScope == 1 || this.loginUser.dataScope == 3) && this.userData.length && this.loginUser.deptName == this.userData[0].campusSchoolName) || this.loginUser.dataScope == 2) {
                  showAfaterSale = true
                } else {
                  showAfaterSale = false
                }
              } else {
                showAfaterSale = true
              }
              return showAfaterSale
            },
            // 判断所属校区是否等于上课校区
            isSchoolEq() {
              let showSchoolEq
              if(Number(this.$route.query.transferSchool)) {
                showSchoolEq = this.userData.length && this.loginUser.deptName == this.userData[0].campusSchoolName
              } else {
                showSchoolEq = true
              }
              return showSchoolEq
            }
        },


        mounted() {

            // this.customerCode = "0001AZ100000006FNJ4K";

             
            this.god = this.$route.query.god;
            this.orderId = this.$route.query.orderId;
            this.ios =  this.$route.query.ios;
            // 获取订单来源列表
            reqDictByCode('SCHOOL_ID').then((res) => {
              this.saleSourceList = {}
              res.result.map(item => {
                this.saleSourceList[item.value] = item.label
              })
            })

            if ( this.orderId) {
                
                this.getordersj()
                this.getContractRecord();// 获取协议id

                this.getQueryOrderInOutProjectDto(this.orderId)
                this.getAllVoucherDetails(this.orderId)
                this.checkOutOrderCancelAndClose(this.orderId)
                this.getAftersaleGetOriginOrder(this.orderId)
            }


            this.checkOrderByAfterSaleType();

            // 获取支付方式
            this.$fetch("third_innerfindSettlementMethod").then(res => {
              this.payModeList = []
              this.payTypeList = []
              res.data.map(item => {
                this.payTypeList.push({'id': item.id, 'Text': item.name})
                this.payModeList.push({'id': item.payMode, 'Text': item.name})
              })
            })
        }
}
</script>

<style scoped lang="scss">
::v-deep .confirm_box {
  // min-height: 500px;
  width: 555px !important;
}
::v-deep .confirm_box .modal__body {
  min-height: 520px;
}

    .Mark {
        // margin: 0 24px;
        border: 1px solid #e1e4eb;
    }

    [v-cloak] {
        display: none;
    }

    .el-row {
        margin-bottom: 18px;
    }

    .el-row + .el-row {
        margin-bottom: 0;
    }

    .el-row span:nth-child(1) {
        color: #999999;
    }

    .ty-title2 {
        font-size: 14px;
        font-weight: 400;
        text-align: left;
        color: #333333;
        // margin-left: 24px;
        padding-bottom: 12px;
    }

    /* 两列表格弹窗 */
    .ty-column-table li {
        display: flex;
        height: 42px;
        line-height: 42px;
    }

    .ty-column-table li:nth-child(odd) {
        background: #f7fcfc;
    }

    .ty-column-table li:first-child {
        background: #e1f5f5;
        border-radius: 4px 4px 0px 0px;
        color: #333333;
        font-size: 12px;
        font-weight: 700;
    }

    .ty-column-table li:last-child {
        border-radius: 0px 0px 4px 4px;
    }

    .ty-column-table li span {
        flex: 1;
        text-align: center;
    }

    .ty-column-table li:first-child span:first-child {
        border-right: 1px solid #e1e4eb;
    }

    .ty-column-table li + li span {
        border-left: 1px solid #e1e4eb;
        border-bottom: 1px solid #e1e4eb;
        color: #999999;
        font-size: 13px;
    }

    .ty-column-table li + li span:nth-child(2) {
        border-right: 1px solid #e1e4eb;
        font-size: 13px;
        color: #666666;
    }
    .tb-edit-input .el-input .el-input__inner{
        height: 36px;
        line-height: 36px;
    }
    /* 列表td有输入框 */
    .el-table.tb-edit-input td.tb-input{
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }
    .el-table.tb-edit-input td.tb-input .cell{
        padding: 0 4px !important;
    }
    .el-table.tb-edit-input td.tb-input .cell .el-input .el-input__inner{
        height: 36px;
        line-height: 36px;
        font-size: 13px;
        padding: 0 5px;
        /* input多了border所以加1px */
    }
    .el-table.tb-edit-input td.tb-input .cell p{
        padding: 0 6px;
    }
    
    .deal-info{
        margin-left: 24px;
        margin-right: 24px;
        margin-bottom: 12px;
        max-width: 692px;
        padding: 0 42px;
        height: 67px;
        line-height: 67px;
        background: #F8F9FB;
        border: 1px solid #E1E4EB;
        border-radius: 4px;
        font-size: 14px;
        display: flex;
        overflow-x: auto;
    }
    .deal-info li{
        min-width: 162px;
        flex: 1;
        display: flex;
    }
    .deal-info li.words2 span:nth-child(1){
        min-width: 42px;
    }
    .deal-info li.words4 span:nth-child(1){
        min-width: 70px;
    }
    .ty-el-row.deal-info li span:nth-child(1){
        color: #999999;
    }
    .ty-el-row.deal-info li span:nth-child(2){
        color: #333333;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .ty-page-top {
      background: #f5f7fa;
    }

    .pb36 {
      width: 94%;
      margin: 0 auto;

      .ty-info_title {
        font-weight: bold;
        padding-bottom: 10px;
      }

      .order-detail {
        .el-col {
          p{
            font-size: 13px;
            color: #999999;
            &:first-child {
              margin-bottom: 6px;
            }
            &:last-child {
              margin-bottom: 12px;
            }
          }
        }
      }
    }
.el-button {
  &.btn{
    padding: 9px 18px;
    margin-right: 12px;
    min-width: 92px;
  }
}
</style>