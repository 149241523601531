<template>
    <div class="customer">
      <div ref="contentTop">
        <div class="tool">
          <div class="customer-tool">
              <div></div>
              
              <!-- <div style="line-height: 40px">
                <el-button type="primary" class="hq-button" @click="saveModel = true">新增</el-button>
              </div> -->
          </div>
        </div>
        <div
          :gutter="10"
          style="width: 94%; margin: 0 auto"
        >
            <Search
            :searchAllParam="searchAllParam"
            @search="search"
            @reset="reset"
            :searchParamList="searchParamList"
            code="count"
            />
        </div>
        <div class="line"></div>
      </div>
      <el-row style="width: 94%; margin: 0 auto">
        <el-col>
          <el-table
            highlight-current-row
            ref="multipleTable"
            :data="tableData"
            tooltip-effect="dark"
            style="width: 100%"
            border
            stripe
            size="mini"
            v-loading="listLoading"
            element-loading-text="拼命加载中"
            :row-style="{ height: '20px' }"
            :cell-style="{ padding: '0px' }"
            :header-cell-style="{ background: '#e5f2ff' }"
            @row-click="clickRow"
          >
            <el-table-column :show-overflow-tooltip="true"
                label="学员"
                prop="name"
                min-width="100"
            ></el-table-column>
            
            <el-table-column :show-overflow-tooltip="true"
                label="电话"
                prop="phone"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="报读商品"
                prop="goodsName"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="商品价格"
                prop="totalPrice"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="订单优惠"
            >
              <template slot-scope="scope">
                {{Number(scope.row.totalPrice) - Number(scope.row.payablePrice)}}
              </template>
            </el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="当前节点"
                prop="processNode"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="当前办理人"
                prop="approver"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="申请状态"
                prop="approvalStatus"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="提交人"
                prop="submitter"
                min-width="100"
            ></el-table-column>
            <el-table-column :show-overflow-tooltip="true"
                label="提交时间"
                prop="createTime"
                min-width="160"
            ></el-table-column>
            <el-table-column
            label="操作"
            min-width="280"
            fixed="right"
          >
            <template slot-scope="scope">
              <el-button type="text"
                @click="changeJump(scope.row)"
                >审核记录</el-button>
                <!-- :disabled="scope.row.processStatus != 0 && scope.row.processStatus != 5" -->
                <el-button type="primary" class="hq-button" @click="listBtn(1,scope.row.processInstanceId)"
                  v-if="permUtil.WbOrder.orderDiscountApprovalBtn()" :disabled="scope.row.processStatus != 0 && scope.row.processStatus != 5">
                  通过
                </el-button>
                <el-button type="danger" class="hq-button" @click="jujueShow(scope.row)"
                  v-if="permUtil.WbOrder.orderDiscountApprovalBtn()" :disabled="scope.row.processStatus != 0 && scope.row.processStatus != 5">
                  拒绝
                </el-button>
                <el-button class="hq-button" @click="listBtn(3,scope.row.processInstanceId)"
                  v-if="permUtil.WbOrder.orderDiscountApprovalBtn()" :disabled="scope.row.processStatus != 0 && scope.row.processStatus != 5">
                  撤销
                </el-button>
            </template>
          </el-table-column>
          </el-table>
          <div class="page_test_bottom">
            <el-pagination
              background
              @size-change="sizeChange"
              @current-change="currentChange"
              layout="total, sizes, prev, pager, next, jumper"
              :current-page="pageParam.pageNum"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="pageParam.pageSize"
              :total="total"
            />
          </div>
        </el-col>
      </el-row>
      <HqModal :visible="jujueModel" :title="'是否拒绝'" width="400" @on-cancel="cancel">
        <el-form ref="form" :model="form" :rules="formRules">
            <el-row :gutter="10">
                <div class="lineclass"></div>
                <el-col :span="24">
                    <el-form-item prop="changeReason">
                        <v-textarea
                            v-model="form.changeReason"
                            outlined
                            :min-height="80"
                            placeholder="输入拒绝理由，200字内"
                            name="input-7-4"
                            label="拒绝理由"
                        ></v-textarea>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <div slot="footer">
            <el-button @click="cancel">取消</el-button>
            <el-button @click="jujueOk" type="primary">确认</el-button>
        </div>
      </HqModal>
      <review-history 
      :visible="onReviewHistoryStatus"
      :reviewData="reviewData"
      ref="drawer"
      @on-cancel="onReviewHistoryCancel"
    />
    </div>
</template>

<script>
import Search from "@/components/search";
import { mapGetters } from "vuex";
import common from '@/views/order/after-sale-nc/mixins/common'
import axios from "axios";


export default {
    name: 'discountApproval',
    mixins: [common],
    components: {
        Search,
        reviewHistory : () => import("./reviewHistory"),

    },
    data() {
        const searchParamList = ["createTime", "keyword", "status"]
        const validateReason = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('请输入变更理由'));
            } else {
                if (value.length > 200) {
                    callback(new Error('变更理由输入长度不能大于200'));
                }
                callback();
            }
        };
        return {
            reviewData: {},
            onReviewHistoryStatus: false,
            form: {
                changeReason: "",
            },
            formRules: {
                changeReason: [
                    {required: true, validator: validateReason}
                ],
            },
            jujueModel: false,
            jujueId:'',
            searchAllParam: [
                { 
                  name: "createTime", 
                  label: "提交时间", 
                  type: "date", 
                  value: [], 
                  ref: "xxxxx", 
                  show: false 
                },
                {
                    name: "phone",
                    label: "电话",
                    type: "input",
                    value: "",
                },
                {
                    name: "goodsName",
                    label: "报读商品",
                    type: "input",
                    value: "",
                },
                {
                    name: "submitter",
                    label: "提交人",
                    type: "input",
                    value: "",
                },
                {
                    name: "approvalStatusList",
                    label: "申请状态",
                    type: "dictMult",
                    value: "",
                    code: "PROCESS_STATUS",
                },  
                
            ],
            searchParam: {},
            searchParamList: searchParamList,
            listLoading: false,
            tableData: [],
            pageParam: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            publicPath: process.env.BASE_URL,
            fileList: [],   // excel文件列表
        }
    },
    methods: {
        onReviewHistoryCancel() {
          this.onReviewHistoryStatus = false;
        },
        // 上传修改成本文件
        async upload(param) {
          const formData = new FormData()
          console.log('param', param);
          formData.append('upfile', param.file)
          const res = await this.$fetch("third_uploadPic", formData)
          console.log('res文件上传', res);
          if(res.state == "SUCCESS") {
            this.form.changeFile = res.url
          }
        },
        beforeUploadFile(file) {
          console.log('file',file);
          let extension = file.name.substring(file.name.lastIndexOf('.') + 1)
          let size = file.size / 1024 / 1024
          if (extension !== 'xlsx') {
            this.$message.warning('只能上传后缀是.xlsx的文件')
          }
          if (size > 10) {
            this.$message.warning('文件大小不得超过10M')
          }
        },
        handleExceed(files, fileList) {
          this.$message.error('请删除当前上传的文件！')
        },
        // 更新自定义查询
        searchList() {
            this.getSearchList();
        },
        async getSearchList() {
            this.searchParamList = this.searchAllParam;
        },
        // 获取列表数据
        async GetList() {
          console.log('this.searchParam', this.searchParam);
            let params = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                dataScope: this.loginUser.dataScope,
                userId: this.loginUser.tyUserId,
                ...this.searchParam
            }
            console.log('params', params);
            this.listLoading = true
            const res = await axios.post(`/qw_school/schoolcj/order/api/order/discount/apply/list`, params,{
              headers:{
                "CrmToken": localStorage.getItem("userToken")
              }
            }).then((res) => res.data)
            console.log('res', res);
            if(res.code == 200) {
              this.tableData = res.data.list || []
              this.total = res.data.total
              console.log('this.total',this.total);
            } else {
              this.$message.error(res.msg)
            }
            this.listLoading = false
        },
        search() {
          this.searchParam = {}
          this.searchAllParam.forEach( item => {
          if( (item.value !== undefined && item.value !== null && item.value !== '' ) || ( Array.isArray(item.value)  && item.value.length ) ) {
                    if(item.type === "date") {
                      if(item.name === "createTime") {
                        console.log('item.value[0]', item.value[0]);
                        this.searchParam.startCreateTime = item.value[0];
                        this.searchParam.endCreateTime = item.value[1];
                      }
                    } else {
                      this.searchParam[item.name] = item.value 
                    }
              }
            })

            this.GetLissearch()
        },
        // 提取文件
        changeJump(id) {
          console.log(id);
          this.reviewData = id
          this.$refs.drawer.init(id)
        },
        reset() {
          this.searchParamList.map(item => {
            if(item.value || item.value == 0) {
              if(item.name === "createTime") {
                item.value = []
              } else {
                item.value = ""
              }
            }
          })
          this.pageParam = {
            pageNum: 1,
            pageSize: 10,
          }
          this.total = 0
          this.searchParam.approvalStatusList = []
          this.searchParam.goodsName = ''
          this.searchParam.submitter = ''
          this.searchParam.startCreateTime = '';
          this.searchParam.endCreateTime = '';
          this.GetLissearch()
        },
        // 获取列表数据
        async GetLissearch() {
          if (this.searchParam.startCreateTime == '' || this.searchParam.startCreateTime == undefined) {
            this.searchParam.startCreateTime = ''
          } else {
            this.searchParam.startCreateTime = `${this.searchParam.startCreateTime} 00:00:00`
          }
          if (this.searchParam.endCreateTime == '' || this.searchParam.endCreateTime == undefined) {
            this.searchParam.endCreateTime = ''
          } else {
            this.searchParam.endCreateTime = `${this.searchParam.endCreateTime} 23:59:59`
          }
          console.log('this.searchParam', this.searchParam);
            let params = {
                pageNum: this.pageParam.pageNum,
                pageSize: this.pageParam.pageSize,
                dataScope: this.loginUser.dataScope,
                userId: this.loginUser.tyUserId,
                ...this.searchParam
            }
            console.log('params', params);
            this.listLoading = true
            const res = await axios.post(`/qw_school/schoolcj/order/api/order/discount/apply/list`, params,{
              headers:{
                "CrmToken": localStorage.getItem("userToken")
              }
            }).then((res) => res.data)
            console.log('res', res);
            if(res.code == 200) {
              this.tableData = res.data.list || []
              this.total = res.data.total
            } else {
              this.$message.error(res.msg)
            }
            this.listLoading = false
        },
        clickRow() {

        },
        sizeChange(val) {
          this.pageParam.pageSize = val;
          this.GetList()
        },
        currentChange(val) {
          this.pageParam.pageNum = val;
          this.GetList()
        },
        listBtn(num,processInstanceId){
          let str =''
          if(num == 1){
            str = "通过"
          }else if(num == 2){
            str = "拒绝"
          }else if(num ==3){
            str = "撤销"
          }
          this.$hqMessageBox({
            title: '提示',
            message: '是否'+str+'该申请?'
          }).then(async () => {
              const params = {
                operationType: num,
                processInstanceId: processInstanceId
              };
              this.listLoading = true
              const {code , msg} = await axios.post(`/qw_school/schoolcj/order/api/order/discount/apply/approval`, params,{
                headers:{
                  "CrmToken": localStorage.getItem("userToken")
                }
              }).then((res) => res.data)
              if (code === 200) {
                this.$message.success(msg);
                this.GetList();
              } else {
                this.$message.error(msg);
              }
          }).catch(() => {
            // reject(false);
          });
        },
        cancel() {
            this.jujueModel = false
            this.form.changeReason = ""
            this.jujueId = ""
        },
        jujueShow(data){
          this.jujueModel = true
          this.jujueId = data.processInstanceId
        },
        async jujueOk() {
          if(this.form.changeReason.length == 0) {
            return this.$message.error('请填写拒绝理由')
          }
          if(this.form.changeReason.length > 200) {
            return this.$message.error('请保证拒绝理由在200字以内')
          }
          let params = {
            operationType: 2,
            remark: this.form.changeReason,
            processInstanceId: this.jujueId
          }
          console.log('params', params);
          this.listLoading = true
          const res = await axios.post(`/qw_school/schoolcj/order/api/order/discount/apply/approval`, params,{
            headers:{
              "CrmToken": localStorage.getItem("userToken")
            }
          }).then((res) => res.data)
          console.log('res,提交', res);
          if (res.code && res.code == 200) {
            this.$message.success(res.msg)
            this.cancel()
            this.GetList()
          } else {
            this.$message.error(res.msg)
          }
        }
    },
    computed: {
      ...mapGetters([
        "getTyUserInfo", "loginUser"
      ]),
    tableHeight() {
      return window.outerHeight - this.topHeight - 256 + 'px'
    }
  },
    mounted() {
        this.searchList()
        this.GetList()
    }
}
</script>

<style lang="scss" scoped>
.lineclass {
    margin-top:20px;
    height: 10px;
    width: 100%;
    display: inline-block;
}
.customer {
  // padding-bottom: 40px;
  .titie {
    text-align: left;
  }

  .tool {
    background: #f5f7f9;
  }

  .customer-tool {
    display: flex;
    justify-content: space-between;
    width: 94%;
    margin: 0 auto;

    .tab-bar {
      overflow: hidden;

      .active {
        color: #409eff;
        position: relative;

        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: #337aff;
          bottom: 0;
          left: 0;
        }
      }

      > span {
        float: left;
        margin-right: 12px;
        line-height: 40px;
        text-align: center;
        text-decoration: none;
        color: #000;
        cursor: pointer;
        font-size: 14px;
        padding: 0 10px;
      }
    }
  }
  .phone {
    .el-button--text {
      padding: 10px 4px;
    }
  }
}
::v-deep .action_col{
  padding: 4px 0 !important;
}
.action_btn{
  width: 60px;
  padding: 0;
  height: 24px;
  line-height: 24px;
  background-color: #337AFF;
  font-size: 13px;
  &.is-disabled{
    background-color: #E1E4EB;
    border-color: #E1E4EB;
  }
}
</style>
