<template>
  <div class="upgrade">
    <!-- <p>申请售后</p> -->
    <div class="form-wrap">
      <el-form
        :model="signUpForm"
        :rules="formRules"
        label-position="right"
        ref="form"
      >
        <div class="upgrade-info-wrap">
          <el-form-item
            prop="afterSaleType"
            class="inline-formitem afterSaleName"
            style="width: 282px"
          >
            <span>售后类型</span>
            <!-- <span> 转班</span> -->
            <v-text-field
              hide-details="auto"
              disabled
              outlined
              :dense="true"
              :height="42"
              value="转班"
            ></v-text-field>
          </el-form-item>
          <div>
            <el-form-item prop="originOrder">
              <span>售后订单</span>
              <el-table
                border
                stripe
                size="mini"
                element-loading-text="拼命加载中"
                :row-style="{ height: '48px' }"
                :cell-style="{ padding: '0px' }"
                :header-cell-style="{ background: '#e5f2ff' }"
                style="width: 100%"
                header-cell-class-name="normal-table-header"
                :data="orderTable"
              >
                <template v-for="item of orderColumns">
                  <el-table-column :show-overflow-tooltip="true" v-if="item.prop === 'payType'" :key="item.prop" :label="item.label" width="120px">
                    <template v-slot:default="{ row }">
                      {{ payTypeMap[row.payType] }}
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'allPay'" :key="item.prop" :label="item.label">
                    <template v-slot:default="{ row }">
                      {{ allPayMap[row.allPay] }}
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'seSeQuenName'" :key="item.prop" :label="item.label" width="180px" :fixed="item.fixed">
                    <template v-slot:default="{ row }">
                      <span>{{ row.seSeQuenName }}</span>
                      <span style="color: #337AFF; margin-top: 6px">{{ row.commodityName }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'orderStatus'" :key="item.prop" :label="item.label" width="120px">--</el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'afterSaleTypes'" :key="item.prop" :label="item.label">--</el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'registDate'" :key="item.prop" :label="item.label" width="180px">
                    <template v-slot:default="{ row }">
                      {{ row.registDate | formatDate("YYYY-MM-DD HH:mm:ss") }}
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'installmentType'" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width">
                    <template v-slot:default="{ row }">
                      <span v-if="row.installmentType != 0">{{ installmentTypeMap[row.installmentType] }}</span>
                      <span v-else>- -</span>
                    </template>
                  </el-table-column>
                  <el-table-column :show-overflow-tooltip="true" v-else-if="item.prop === 'tradeSerialNumber'" :key="item.prop" :prop="item.prop" :label="item.label" :width="item.width">
                      <template v-slot:default="{ row }">
                        <span v-if="row.installmentType != 0">{{ row.tradeSerialNumber }}</span>
                        <span v-else>- -</span>
                    </template>
                  </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  v-else-if="item.prop === 'customerIdCode'"
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :fixed="item.fixed"
                >
                  <template v-slot:default="{row}">
                    <span >{{ row[item.prop] | empty('--')}}</span>
                  </template>
                </el-table-column>
                <el-table-column :show-overflow-tooltip="true"
                  v-else
                  :key="item.prop"
                  :prop="item.prop"
                  :label="item.label"
                  :width="item.width"
                  :fixed="item.fixed"
                >
                  <template v-slot:default="{row}">
                    <span v-if="['prodLineName', 'goodsTypeName', 'priTypeName', 'campusName'].includes(item.prop)">{{ row[item.prop] | empty('--') }}</span>
                    <span v-else-if="['goodsSpecName', 'goodsCategoryName'].includes(item.prop)">--</span>
                    <span v-else>{{ row[item.prop] }}</span>
                  </template>
                </el-table-column>
                </template>
              </el-table>
            </el-form-item>
          </div>

          <el-form-item prop="cashierId" style="width: 282px;margin-bottom: 35px">
            <v-autocomplete
              v-model="signUpForm.cashierId"
              label="出纳审核"
              :items="cashierCheckList"
              :menu-props="{ bottom: true, offsetY: true }"
              item-text="userName"
              item-value="tyUserId"
              placeholder="选择校区出纳"
              outlined
              :dense="true"
              :height="42"
              :clearable="true"
              clear-icon="$clear"
              hide-details="auto"
            ></v-autocomplete>
          </el-form-item>
          <el-form-item prop="reason" class="Contextclass">
            <v-textarea
              v-model.trim="signUpForm.reason"
              hide-details="auto"
              label="申请原因"
              placeholder="售后申请说明，500字内"
              outlined
              :dense="true"
              :height="120"
              maxlength="500"
              counter
            ></v-textarea>
          </el-form-item>
          <annex ref="annexRef"/> 
        </div>
        <div class="line"></div>

        <div class="payinfo-wrap">
          <h2 class="sub-title" style="margin: 18px 0 6px 0">退费信息</h2>
          <div class="dealInfo" style="margin-top: -12px">
            <el-form-item prop="originOrder">
              <span>退费计算<span class="start">*</span></span>
              <el-row>
                <div
                  v-for="(dropOutTable, tableIndex) in dropOutTableList"
                  :key="tableIndex"
                  class="dropout_table"
                  :class="{single: dropOutTableList.length === 1}"
                >
                  <div class="goodsAndClass" style="background: #f5f7fa;overflow: hidden;padding: 10px 20px;margin-bottom: 18px">
                    <el-col :span="6">
                      <p style="color: #999">商品名称</p>
                      <p v-if="classInfo[tableIndex]">{{
                        classInfo[tableIndex].goodsName
                      }}</p>
                      <p v-else>- -</p>
                    </el-col>
                    <el-col :span="4">
                      <p style="color: #999">已收金额</p>
                      <p v-if="classInfo[tableIndex]"
                        >￥{{ classInfo[tableIndex].receivedMoney | decimal2 }}</p
                      >
                      <p v-else>0.00</p>
                    </el-col>
                    <!-- nc不显示已上课时金额 -->
                    <el-col :span="9" v-if="false">
                      <p style="color: #999">已上课时费用</p>
                      <p class="classHis">
                        <span>线上课程</span>
                        <span
                          v-if="
                            classInfo[tableIndex] &&
                            classInfo[tableIndex].onlineViewType != 3
                          "
                        >
                          <span
                            v-show="
                              classInfo[tableIndex].onlineViewType == 0 ||
                              classInfo[tableIndex].onlineViewType == 2
                            "
                            >{{ classInfo[tableIndex].ineffectiveMonth }}/{{
                              classInfo[tableIndex].effectiveMonth
                            }}
                            月 已上课时费用{{
                              classInfo[tableIndex].onlineMonthSpendMoney
                            }}元</span
                          >
                          <span
                            v-show="
                              classInfo[tableIndex].onlineViewType == 1 ||
                              classInfo[tableIndex].onlineViewType == 2
                            "
                            >{{ classInfo[tableIndex].signCount }}/{{
                              classInfo[tableIndex].lessonCount
                            }}
                            课时 已上课时费用{{
                              classInfo[tableIndex].onlineSpendMoney
                            }}元</span
                          >
                        </span>
                        <span v-else>- -</span>
                      </p>
                      <p class="classHis">
                        <span>线下课程</span>
                        <span
                          v-if="
                            classInfo[tableIndex] &&
                            classInfo[tableIndex].underlineViewType != 3
                          "
                        >
                          <span
                            v-show="
                              classInfo[tableIndex].underlineViewType == 0 ||
                              classInfo[tableIndex].underlineViewType == 2
                            "
                          >
                            {{ classInfo[tableIndex].ineffectiveMonth }}/{{
                              classInfo[tableIndex].effectiveMonth
                            }}
                            月 已上课时费用{{
                              classInfo[tableIndex].underlineMonthSpendMoney
                            }}元</span
                          >
                          <span
                            v-show="
                              classInfo[tableIndex].underlineViewType == 1 ||
                              classInfo[tableIndex].underlineViewType == 2
                            "
                            >{{ classInfo[tableIndex].signCount }}/{{
                              classInfo[tableIndex].lessonCount
                            }}
                            课时 已上课时费用{{
                              classInfo[tableIndex].underlineSpendMoney
                            }}元</span
                          >
                        </span>
                        <span v-else>- -</span>
                      </p>
                    </el-col>
                    <el-col :span="5" v-if="orderExtraInfo && orderExtraInfo.goodsTemplate === goodsTemplateEnum.cjzh">
                      <p style="color: #999">{{ approvedRefundLabel }}</p>
                      <p>{{ classInfo[tableIndex].refundMoney | decimal2 }}元</p>
                    </el-col>
                    <span class="expand" v-if="dropOutTableList.length > 1" @click="toggleDropoutTable(tableIndex)">{{!classInfo[tableIndex].expand ? '详情' : '收起' }}<i :class="[!classInfo[tableIndex].expand ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"></i></span>
                  </div>
                  <div v-show="classInfo[tableIndex].expand">
                    <div class="inout_bar"><b>收支项目</b><i class="el-icon-circle-plus color" @click="addDropOut(tableIndex)">添加扣费项目</i></div>
                    <!-- 退费计算表格 -->
                    <el-table
                      border
                      stripe
                      size="mini"
                      element-loading-text="拼命加载中"
                      :row-style="{ height: '48px' }"
                      :cell-style="{ padding: '0px' }"
                      :header-cell-style="{ background: '#ffffff' }"
                      style="width: 100%"
                      header-cell-class-name="normal-table-header"
                      show-summary
                      :summary-method="onCustomSummaryDropoutBox"
                      :data="dropOutTable"
                      @cell-click="onDropOutCellClick"
                      class="dropout-table"
                    >
                      <template v-for="item of dropOutColumns">
                        <!-- 应收金额 -->
                        <el-table-column :show-overflow-tooltip="true"
                          v-if="item.prop === 'receivableMoney'"
                          :label="item.label"
                          :key="item.prop"
                          :prop="item.prop"
                        >
                          <template v-slot:default="{ row }">
                            <p>{{ row.canDelete ? "--" : row.receivableMoney }}</p>
                          </template>
                        </el-table-column>
                        <!-- 已收金额 -->
                        <el-table-column :show-overflow-tooltip="true"
                          v-else-if="item.prop === 'receivedMoney'"
                          :label="item.label"
                          :key="item.prop"
                          :prop="item.prop"
                        >
                          <template v-slot:default="{ row }">
                            <p>{{ row.canDelete ? "--" : row.receivedMoney }}</p>
                          </template>
                        </el-table-column>
                        <!-- 扣费金额 -->
                        <el-table-column :show-overflow-tooltip="true"
                          class-name="deduction-column"
                          v-else-if="item.prop === 'deductionMoney'"
                          :key="item.prop"
                          :label="item.label"
                          :prop="item.prop"
                        >
                          <template slot="header">
                            <div>{{ item.label }}
                              <el-tooltip class="item" effect="light" content="售后订单扣减的金额，扣减的金额不用于转班订单抵扣和退费" placement="top-start">
                                <i class="el-icon-question" style="color: #9BACBF;font-size: 18px;margin-left: 8px"></i>
                              </el-tooltip>
                            </div>
                          </template>
                          <div slot-scope="scope" data-type="1">
                            <div
                              class="deduction-item"
                              @click="toggleDeductionMoney(scope.row, $event)"
                            >
                              <!-- <p v-if="!scope.row.edit">
                                {{ scope.row.deductionMoney }}
                              </p> -->
                              <el-input
                                size="mini"
                                v-model="scope.row.deductionMoney"
                                type="number"
                                @blur="
                                  onDeductionMoneyBlur(
                                    dropOutTable,
                                    scope.row,
                                    scope.row.$index
                                  )
                                "
                                @input="
                                  onDeductionMoneyInput(scope.row, scope.row.$index)
                                "
                              />
                            </div>
                          </div>
                        </el-table-column>
                        <!-- 核定可退款金额 -->
                        <el-table-column :show-overflow-tooltip="true"
                          v-else-if="item.prop === 'refundableMoney'"
                          :label="item.label"
                          :key="item.prop"
                          :prop="item.prop"
                        >
                          <template slot="header">
                            <div>{{ item.label }}
                              <el-tooltip class="item" effect="light" content="售后订单扣减后剩余的金额，可用于转班抵扣，抵扣后剩余的金额可用于贷款平台退费和学员退费" placement="top-start">
                                  <i class="el-icon-question" style="color: #9BACBF;font-size: 18px;margin-left: 8px"></i>
                              </el-tooltip>
                            </div>
                          </template>
                          <template v-slot:default="{ row }">
                            <p>{{ row.refundableMoney }}</p>
                          </template>
                        </el-table-column>

                        <el-table-column :show-overflow-tooltip="true"
                          v-else-if="item.prop === 'canDo'"
                          :label="item.label"
                          :key="item.prop"
                          :prop="item.prop"
                        >
                          <template v-slot:default="{ row }">
                            <el-link
                              type="primary"
                              @click="
                                onDeleteDropOutTableRow(
                                  dropOutTable,
                                  tableIndex,
                                  row
                                )
                              "
                              v-if="row.canDelete"
                              >删除</el-link
                            >
                            <p v-else>--</p>
                          </template>
                        </el-table-column>

                        <el-table-column :show-overflow-tooltip="true"
                          v-else
                          :key="item.label"
                          :label="item.label"
                          :width="item.width"
                          :prop="item.prop"
                        />
                      </template>
                    </el-table>
                  </div>
                </div>
              </el-row>
            </el-form-item>
          </div>

          <span>退费结算</span>
          <el-row style="margin-top: 12px">
            <el-col :span="4" style="width: 280px;">
              <div>
                <span class="fb14 color_title">合计扣费金额</span>
                <el-tooltip effect="light" content="售后订单扣减的金额总和，扣减的金额不用于转班订单抵扣和退费" placement="top-start">     
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
              </div>
              
              <section class="dealInfoBox fb14 color_price">{{allDeductionMoney | decimal2}} 元</section>
            </el-col>
            <el-col :span="4" style="width: 280px;margin-left: 24px">
              <div>
                <span class="fb14 color_title">{{ approvedRefundLabel }}</span>
                <el-tooltip effect="light" content="售后订单扣减后剩余的金额总和，可用于转班抵扣，抵扣后剩余的金额可用于贷款平台退费和学员退费" placement="top-start">     
                  <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                </el-tooltip>
              </div>

              <section class="dealInfoBox fb14 color_price">{{approvedRefundableAmount | decimal2}} 元</section>
            </el-col>
          </el-row>
        </div>
        <div class="line"></div>
        <!-- 成交信息 -->
        <div class="deal-info-wrap" style="margin-top: 18px">
          <h2 class="sub-title" style="margin-bottom:20px">成交信息</h2>
          <div>
            <div :gutter="24" class="dealInfoList">
              <div :span="4" v-for="(item, index) in makeList" :key="index" style="margin-bottom: 20px" class="item">
                <v-autocomplete
                  v-if="item.value==='isFourSix'"
                  v-model="transactionInfo[item.value]"
                  :items="[{id:1,name:'是'}, {id:0, name:'否'}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="'是否46分成'"
                  outlined
                  item-text="name"
                  item-value="id"
                  :dense="true"
                  :height="42"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                ></v-autocomplete>
                 <v-autocomplete
                  v-else-if="item.value==='promotionForm'"
                  v-model="transactionInfo[item.value]"
                  :items="[{id:'招生协助',name:'招生协助'}, {id:'合伙人自主', name:'合伙人自主'}]"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="'促单形式'"
                  outlined
                  :disabled="!transactionInfo['economicFlowPartner']"
                  item-text="name"
                  item-value="id"
                  :dense="true"
                  :height="42"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                ></v-autocomplete>
                <v-text-field
                    v-else-if="item.value === 'transIntroduceType'"  
                    v-model="recommandTypeMap[transactionInfo[item.value]]"
                    :label="item.label"
                    hide-details="auto"
                    disabled
                    outlined
                    :dense="true"
                    :height="42"
                  ></v-text-field>
                <v-text-field
                    v-else  
                    v-model="transactionInfo[item.value]"
                    :label="item.label"
                    hide-details="auto"
                    disabled
                    outlined
                    :dense="true"
                    :height="42"
                  ></v-text-field>
              </div>
              <div :span="4" style="margin-bottom: 20px" class="item">
                <v-autocomplete
                  v-model="transactionInfo['assistDealCrmUserId']"
                  :items="assistDealList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  :label="'协助成交人员'"
                  outlined
                  item-text="assistDealUserName"
                  item-value="assistDealCrmUserId"
                  :dense="true"
                  :height="42"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  :disabled="transactionInfo.isRecommand == 1"
                  @change="changeAssistDeal"
                ></v-autocomplete>
              </div>
            </div>
          </div>
        </div>
        <div class="line"></div>
        <!-- 学员信息 -->
        <div class="student-info-wrap">
          <h2 class="sub-title">学员信息<b class="tip">(学习账号用于app的学习与签约接收验证码，请正确填写！)</b></h2>
          <div style="margin-top: 16px">
            <el-row :gutter="24">
              <el-col style="width:306px">
                <el-form-item
                  prop="stuMoblie"
                  class="inline-formitem"
                  style="margin-bottom: 2px"
                  :class="{noAllowed: disabledChangeStuMobile}"
                >
                  <!-- <v-select
                    v-if="phoneList.length"
                    v-model="signUpForm.stuMoblie"
                    :items="phoneList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="学习账号"
                    item-text="detail"
                    item-value="detail"
                    outlined
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    :disabled="phoneList.length == 1"
                    @change="getCustomeruser"
                  ></v-select> -->
                  <v-text-field
                    v-model.trim="signUpForm.stuMoblie"
                    label="学习账号"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                    :disabled="isCanStudy"
                    @blur="addCustomeruser"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col style="width:306px">
                <el-form-item prop="name" class="inline-formitem" style="margin-bottom: 2px">
                  <v-text-field
                    v-model.trim="signUpForm.name"
                    label="学员姓名"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                  ></v-text-field>
                </el-form-item>    
              </el-col>
              <el-col style="width:306px">
                <el-form-item
                  style="margin-bottom: 2px"
                  prop="idCard"
                  class="inline-formitem idCardBigBox"
                >
                  <v-text-field
                    v-model.trim="signUpForm.idCard"
                    label="身份证号"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                  ></v-text-field>
                </el-form-item>
              </el-col>
              <el-col style="width: 306px">
                <el-form-item prop="education" class="inline-formitem">
                  <dict-select code="EDUCATION_TYPE">
                    <v-select
                      v-model="signUpForm.education"
                      slot-scope="props"
                      :items="props.dictData"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="学员学历"
                      item-text="label"
                      item-value="value"
                      outlined
                      :dense="true"
                      :height="42"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                    ></v-select>
                  </dict-select>
                </el-form-item>
              </el-col>
              <el-col :span="4">
          <el-form-item prop="emergencyContact" class="inline-formitem idCardBigBox">
            <v-text-field
              v-model.trim="transactionInfo.emergencyContact"
              label="紧急联系人"
              hide-details="auto"
              outlined
              :dense="true"
              :height="42"
            ></v-text-field>
          </el-form-item>
        </el-col>
        <el-col :span="4">
          <el-form-item prop="emergencyContactPhone" class="inline-formitem idCardBigBox">
            <v-text-field
              v-model.trim="transactionInfo.emergencyContactPhone"
              label="紧急联系人电话"
              hide-details="auto"
              outlined
              :dense="true"
              :height="42"
            ></v-text-field>
          </el-form-item>
        </el-col>
            </el-row>
          </div>
        </div>
        <div class="line"></div>

        <!-- 商品信息 -->
        <div class="goods-info-wrap">
          <h2 class="sub-title">商品信息</h2>
          <section class="goodsInfoBox">
            <div class="fieldRow">
              <div class="label">报名类型</div>
              <div class="val">
                <v-text-field
                  value="转班报名"
                  disabled
                  outlined
                  :dense="true"
                  :height="42"
                  hide-details="auto"
                />
            </div>
            </div>
            <el-row :gutter="12">
              <el-col :span="8">
                <el-form-item prop="goodsName">
                  <v-text-field
                    style="width: 100%;display: inline-block"
                    v-model="signUpForm.goodsName"
                    label="选择商品"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                    readonly
                    @click.native="onChoiceGoods"
                  >
                  </v-text-field>
                </el-form-item>
              </el-col>
              <el-col :span="8" style="line-height: 40px">
                <a
                    type="error"
                    v-show="currentGoods.statusOnlineSell == 2"
                    @click="previewGoods(currentGoods.goodsId)"
                    style="margin-right:12px;"
                    >预览</a
                  >
                  <a
                    type="error"
                    :disabled="!signUpForm.goodsName"
                    @click="removeChoiceGoods(null)"
                    >重置</a
                  >
              </el-col>
            </el-row>

            <!-- ******************************************************************* -->
            <!-- 用于自考-单品 -->
            <template v-if="this.ctr.showZkSingleOption">
              <el-form-item label="报考专业">
                <span>{{ currentGoods.enrollingMajorName }}</span>
              </el-form-item>
              <el-form-item>
                <v-autocomplete
                  v-model="zkSingle.form.provinceId"
                  :items="this.zkSingle.enrollingProvinceList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  label="报考院校"
                  outlined
                  item-text="enrollingProvinceName"
                  item-value="index"
                  :dense="true"
                  :height="42"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  class="normal-select"
                  @change="selectZkSingleProvince"
                ></v-autocomplete>
                <v-autocomplete
                  v-model="zkSingle.form.schoolId"
                  :items="this.zkSingle.enrollingSchoolList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  outlined
                  item-text="enrollingSchoolName"
                  item-value="index"
                  :dense="true"
                  :height="42"
                  :clearable="true"
                  clear-icon="$clear"
                  hide-details="auto"
                  no-data-text="暂无数据"
                  class="normal-select"
                  @change="selectZkSingleSchool"
                ></v-autocomplete>
              </el-form-item>

              <el-form-item prop="specTable" label="">
                <!-- 规格表格 -->
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="zkSingle.zkGoodsSpecTable"
                >
                  <el-table-column :show-overflow-tooltip="true"
                    v-for="item of zkSingle.zkGoodsColumns"
                    :key="item.label"
                    :label="item.label"
                    :prop="item.prop"
                    :width="item.width || '120px'"
                  />
                </el-table>
              </el-form-item>
              <el-form-item
                prop="choiceClass"
                class="choiceClass"
                :label="labelTitle"
              >
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  style="width: 800px"
                  header-cell-class-name="normal-table-header"
                  :data="zkSingle.courseList"
                >
                  <el-table-column :show-overflow-tooltip="true" label="课程" prop="courseName" />
                  <el-table-column :show-overflow-tooltip="true" label="类型" prop="type" />
                  <el-table-column :show-overflow-tooltip="true"
                    label="上课形式"
                    prop="classForm"
                    width="125"
                  />
                </el-table>
              </el-form-item>
            </template>

            <!-- 用于成考，网教-单品 -->
            <template v-if="this.ctr.showCkSingleOption">
               <el-row :gutter="8" v-if="showYxOption ==true">
                <el-col :span="8">
                      <schooleTree 
                        ref="deptsys"
                        :data="findSchoolList"
                        @update:select="selectSchoolNcid"/>
                    </el-col>
              </el-row>
              <br />
              <el-row :gutter="24">
                 <el-col :span="6" v-if="showYxOption == true">
                    <v-autocomplete
                      v-model="ckSingle.form.enrollingMajorId"
                      :items="this.ckSingle.enrollingMajorList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="报考专业"
                      outlined
                      item-text="majorName"
                      item-value="majorId"
                      :dense="true"
                      :height="42"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      class="normal-select"
                      @change="selectCkSingleProfessional"
                    ></v-autocomplete>
                </el-col>
                <el-col :span="6" v-if="showYxOption == false">
                  <v-text-field
                    :value="currentGoods.enrollingSchoolName"
                    disabled
                    dense
                    outlined
                    :height="42"
                    label="报考院校"
                  ></v-text-field>
                </el-col>
                <el-col :span="6" v-if="showYxOption == false">
                  <!-- <el-form-item prop="enrollingMajorId"> -->
                    <v-autocomplete
                      v-model="ckSingle.form.enrollingMajorId"
                      :items="this.ckSingle.enrollingMajorList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="报考专业"
                      outlined
                      item-text="majorName"
                      item-value="id"
                      :dense="true"
                      :height="42"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      class="normal-select"
                      @change="selectCkSingleProfessional"
                    ></v-autocomplete>
                  <!-- </el-form-item> -->
                </el-col>
                <el-col :span="6">
                  <!-- <el-form-item prop="enrollingGradationId"> -->
                    <v-autocomplete
                      v-model="ckSingle.form.enrollingGradationId"
                      :items="this.ckSingle.enrollingGradationList"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="报考学级"
                      outlined
                      item-text="gradationYear"
                      item-value="id"
                      :dense="true"
                      :height="42"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      class="normal-select"
                      @change="selectCkSingleGradation"
                    ></v-autocomplete>
                  <!-- </el-form-item> -->
                </el-col>
                <el-col :span="24" v-if="repeat">
                  <div style="color: red">学员已报名该班型，请勿重复报名</div>
                </el-col>
              </el-row>
              <el-row :gutter="24">
                <el-col :span="6">
                  <v-text-field
                    :value="ckSingle.goodsPrice + '元'"
                    readonly
                    dense
                    outlined
                    :height="42"
                    label="商品价格"
                    v-if="ckSingle.goodsPrice"
                  ></v-text-field>
                </el-col>
                <el-col :span="6">
                  <v-text-field
                    :value="ckSingle.effectiveMonth + ' 个月'"
                    disabled
                    dense
                    outlined
                    :height="42"
                    label="学习有效期"
                    v-if="ckSingle.effectiveMonth"
                  ></v-text-field>
                </el-col>
              </el-row>
              <template v-if="currentGoods.xlType != 3">
                <el-form-item
                  v-if="showCKGoods2?ckSingle.courseList.length:true"
                  prop="choiceClass"
                  class="choiceClass"
                >
                  <div>{{labelTitle}}</div>
                  <el-table
                    border
                    stripe
                    size="mini"
                    element-loading-text="拼命加载中"
                    :row-style="{ height: '48px' }"
                    :cell-style="{ padding: '0px' }"
                    :header-cell-style="{ background: '#e5f2ff' }"
                    style="width: 800px"
                    header-cell-class-name="normal-table-header"
                    :data="ckSingle.courseList"
                  >
                    <el-table-column :show-overflow-tooltip="true" label="课程" prop="courseName" />
                    <el-table-column :show-overflow-tooltip="true" label="类型" prop="type" />
                    <el-table-column :show-overflow-tooltip="true"
                      label="上课形式"
                      prop="classForm"
                      width="125"
                    />
                  </el-table>
                </el-form-item>
              </template>
              <!-- 成考第二志愿 开始-->
              <el-form-item prop="goodsName" v-if="showCKGoods2">
                <el-row :gutter="24" align="middle" type="flex">
                  <el-col :span="12">
                      <v-text-field
                        style="width: 100%;display: inline-block"
                        v-model="signUpForm.goodsName2"
                        hide-details="auto"
                        outlined
                        label="第二志愿（选填）"
                        :dense="true"
                        :height="42"
                        readonly
                        @click.native="onChoiceGoods(SecondGoods)"
                      >
                      </v-text-field>
                  </el-col>
                  <el-col :span="8">
                    <a
                        type="error"
                        v-show="currentGoods2.statusOnlineSell == 2"
                        @click="previewGoods(currentGoods2.goodsId)"
                        style="margin-left: 12px;margin-right:12px;"
                        >预览</a
                      >
                      <a
                        type="error"
                        :disabled="!signUpForm.goodsName2"
                        @click="removeChoiceGoods(SecondGoods)"
                        >重置</a
                      >
                  </el-col>
                </el-row>
              </el-form-item>
               <el-row :gutter="24" v-if="showYxOption2 ==true">
                  <el-col :span="8">
                        <schooleTree 
                          ref="deptsystwo"
                          :data="findtwoSchoolList"
                          @update:select="selectSchoolNcidtwo"/>
                      </el-col>
                </el-row>
                <br />
              <el-row :gutter="24" v-if="showCKGoods2">
                <el-col :span="6" v-if="showYxOption2 ==false">
                  <v-text-field
                    :value="currentGoods2.enrollingSchoolName"
                    readonly
                    dense
                    outlined
                    :height="42"
                    label="报考院校"
                  ></v-text-field>
                </el-col>
                <el-col :span="6">
                   <el-form-item prop="enrollingMajorId2"  v-if="showYxOption2 ==true">
                    <v-autocomplete
                      v-model="ckSingle.form.enrollingMajorId2"
                      :items="ckSingle.enrollingMajorList2"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="报考专 业"
                      outlined
                      item-text="majorName"
                      item-value="majorId"
                      :dense="true"
                      :height="42"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      class="normal-select"
                      @change="selectCkSingleProfessional2"
                    ></v-autocomplete>
                   </el-form-item>
                   <el-form-item prop="enrollingMajorId2" v-if="showYxOption2 ==false">
                    <v-autocomplete
                      v-model="ckSingle.form.enrollingMajorId2"
                      :items="ckSingle.enrollingMajorList2"
                      :menu-props="{ bottom: true, offsetY: true }"
                      label="报考 专业"
                      outlined
                      item-text="majorName"
                      item-value="id"
                      :dense="true"
                      :height="42"
                      :clearable="true"
                      clear-icon="$clear"
                      hide-details="auto"
                      no-data-text="暂无数据"
                      class="normal-select"
                      @change="selectCkSingleProfessional2"
                    ></v-autocomplete>
                  </el-form-item>
                </el-col>
              </el-row>
            <!-- 成考第二志愿 结束 -->
            </template>

            <!-- 用于自考-套餐  -->
            <template v-if="this.ctr.showZkMultipleOption">
              <el-row :gutter="8">
                <el-col :span="8">
                  <!-- 选择班型 -->
                  <el-form-item prop="spec">
                    <v-text-field
                      style="width: 100%"
                      v-model="signUpForm.spec"
                      label="选择班型"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="42"
                      disabled
                      @click.native="onChoiceGoodsSpec"
                    >
                    </v-text-field>
                  </el-form-item>
                </el-col>
              </el-row>

              <!-- 报考专业 -->
              <el-form-item label="报考专业">
                <span style="margin-right: 20px">{{
                  zkMultiple.curEnrollingMajorName
                }}</span>
                <el-button type="primary plain" @click="showTableOfDrawer"
                  >选择专业</el-button
                >
              </el-form-item>

              <!-- 报考院校 -->
              <template v-if="zkMultiple.isDoubleProfessional">
                <!-- 双层次： 专科院校 -->
                <el-form-item prop="xxxx">
                  <v-autocomplete
                    v-model="zkMultiple.form.zkProvinceId"
                    :items="this.zkMultiple.zkEnrollingProvinceList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="专科院校"
                    outlined
                    item-text="enrollingProvinceName"
                    item-value="index"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectZkZkMultipleProvince"
                  ></v-autocomplete>
                  <v-autocomplete
                    style="margin-left: 15px"
                    v-model="zkMultiple.form.zkSchoolId"
                    :items="this.zkMultiple.zkEnrollingSchoolList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    item-text="enrollingSchoolName"
                    item-value="index"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectZkZkMultipleSchool"
                  ></v-autocomplete>
                </el-form-item>

                <!-- 双层次 - 本科院校 -->
                <el-form-item>
                  <v-autocomplete
                    style="margin-left: 15px"
                    v-model="zkMultiple.form.bkProvinceId"
                    :items="this.zkMultiple.bkEnrollingProvinceList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="本科院校"
                    outlined
                    item-text="enrollingProvinceName"
                    item-value="index"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectZkBkMultipleProvince"
                  ></v-autocomplete>
                  <v-autocomplete
                    style="margin-left: 15px"
                    v-model="zkMultiple.form.bkSchoolId"
                    :items="this.zkMultiple.bkEnrollingSchoolList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    item-text="enrollingSchoolName"
                    item-value="index"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectZkBkMultipleSchool"
                  ></v-autocomplete>
                </el-form-item>
              </template>
              <template v-else>
                <el-form-item>
                  <v-autocomplete
                    style="margin-left: 15px"
                    v-model="zkMultiple.form.provinceId"
                    :items="this.zkMultiple.enrollingProvinceList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    label="报考院校"
                    outlined
                    item-text="enrollingProvinceName"
                    item-value="index"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectZkMultipleProvince"
                  ></v-autocomplete>
                  <v-autocomplete
                    style="margin-left: 15px"
                    v-model="zkMultiple.form.schoolId"
                    :items="this.zkMultiple.enrollingSchoolList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    outlined
                    item-text="enrollingSchoolName"
                    item-value="index"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    no-data-text="暂无数据"
                    class="normal-select"
                    @change="selectZkMultipleSchool"
                  ></v-autocomplete>
                </el-form-item>
              </template>

              <!-- 班型信息   -->
              <el-form-item prop="specTable" label="">
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="zkMultiple.zkGoodsSpecTable"
                >
                  <el-table-column :show-overflow-tooltip="true"
                    v-for="item of zkMultiple.zkGoodsColumns"
                    :key="item.label"
                    :label="item.label"
                    :prop="item.prop"
                    :width="item.width || '120px'"
                  />
                </el-table>
              </el-form-item>

              <!-- 课程清单 -->
              <el-form-item
                prop="choiceClass"
                class="choiceClass"
              >
                <span class="litter-title">{{ labelTitle }}</span>
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  header-cell-class-name="normal-table-header"
                  :data="zkMultiple.courseList"
                >
                  <el-table-column :show-overflow-tooltip="true"
                    label="班型分组"
                    prop="classType"
                    width="150"
                  />
                  <el-table-column :show-overflow-tooltip="true" label="班型" prop="className" />
                  <el-table-column :show-overflow-tooltip="true" label="课程" prop="courseName" />
                  <el-table-column :show-overflow-tooltip="true" label="类型" prop="type" width="125" />
                  <el-table-column :show-overflow-tooltip="true"
                    label="上课形式"
                    prop="classForm"
                    width="125"
                  />
                </el-table>
              </el-form-item>
            </template>

            <!-- 用于以前的财经学员报名 -->
            <template v-if="this.ctr.showCjSingleOption">
              <el-row :gutter="8">
                <el-col :span="8">
                  <el-form-item prop="spec" :style="{'marginBottom': signUpForm.spec?'10px':'22px'}">
                    <v-text-field
                      style="width: 100%"
                      v-model="signUpForm.spec"
                      label="选择班型"
                      hide-details="auto"
                      outlined
                      :dense="true"
                      :height="42"
                      readonly
                      @click.native="onChoiceGoodsSpec"
                    >
                    </v-text-field>
                  </el-form-item>
                </el-col>
                <el-col :span="24" v-if="repeat">
                  <div style="color: red">学员已报名该班型，请勿重复报名</div>
                </el-col>
              </el-row>

              <template v-if="employmentShow">
                <span style="font-size: 14px;color: #333;">就业保底底金</span>
                <el-row :gutter="8" style="margin-top: 12px">
                  <el-col :span="4">
                    <el-form-item prop="jobLowestSalaryUnderCollege">
                      <v-text-field
                        style="width: 100%"
                        v-model="signUpForm.jobLowestSalaryUnderCollege"
                        label="大专以下(元/月)"
                        hide-details="auto"
                        outlined
                        :dense="true"
                        :height="32"
                      >
                      </v-text-field>
                    </el-form-item>
                  </el-col>
                  <el-col :span="4">
                    <el-form-item prop="jobLowestSalaryUpCollege">
                      <v-text-field
                        style="width: 100%"
                        v-model="signUpForm.jobLowestSalaryUpCollege"
                        label="大专及大专以上(元/月)"
                        hide-details="auto"
                        outlined
                        :dense="true"
                        :height="32"
                      >
                      </v-text-field>
                    </el-form-item>
                  </el-col>
                </el-row>
              </template>

              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate != 6"
              >
                <!-- 规格表格 -->
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  highlight-current-row
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="goodsSpecTable"
                >
                  <template v-for="item of goodsColumns">
                    <el-table-column :show-overflow-tooltip="true"
                      v-if="item.prop === 'goodsDiscountPrice'"
                      :prop="item.prop"
                      :key="item.prop"
                      :label="item.label"
                    >
                      <template v-slot:default="{ row }">
                        <span
                          v-if="
                            row.goodsDiscountPrice != '' &&
                            row.goodsDiscountPrice != '--' &&
                            row.goodsDiscountPrice != undefined
                          "
                        >
                          {{
                            (row.goodsPrice - row.goodsDiscountPrice).toFixed(2)
                          }}
                        </span>
                        <span v-else>- -</span>
                      </template>
                    </el-table-column>
                    <el-table-column :show-overflow-tooltip="true"
                      v-else
                      :width="item.width || '120px'"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                    >
                      <template v-slot:default="{ row }">
                        {{row[item.prop] | empty('--')}}
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </el-form-item>
              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate == 6"
              >
                <div style="color: #666">
                  <span
                    >商品价格: ¥{{
                      (goodsSpecTable[0] && goodsSpecTable[0].goodsPrice) || 0.0
                    }}</span
                  >
                </div>
              </el-form-item>
              <el-form-item
                prop="specTable"
                v-if="currentGoods.goodsTemplate == 6"
              >
                <el-table
                  border
                  stripe
                  size="mini"
                  element-loading-text="拼命加载中"
                  :row-style="{ height: '48px' }"
                  :cell-style="{ padding: '0px' }"
                  :header-cell-style="{ background: '#e5f2ff' }"
                  highlight-current-row
                  style="width: 100%"
                  header-cell-class-name="normal-table-header"
                  :data="comComGoodsInfoList"
                >
                  <template v-for="item of cjComComGoodsColumns">
                    <el-table-column :show-overflow-tooltip="true"
                      :width="item.width || '120px'"
                      :key="item.label"
                      :label="item.label"
                      :prop="item.prop"
                    >
                      <template v-slot:default="{ row }">
                        {{row[item.prop] | empty('--')}}
                      </template>
                    </el-table-column>
                  </template>
                </el-table>
              </el-form-item>

              <!-- 财经课程清单 -->
              <el-form-item
                prop="choiceClass"
                class="choiceClass"
              >
                <span class="litter-title">{{ labelTitle }}</span>
                <selcetionClass
                  :mealGroupData="mealGroupData"
                  :type="currentGoods.type"
                  :oneItem="oneItem"
                  @confirmClass="confirmClass"
                  ref="selcetionClass"
                />
              </el-form-item>
            </template>
          </section>
        </div>

        <!-- 收款信息 转班 -->
        <div class="line"></div>
        <div class="colllection-info-wrap">
          <h2 class="sub-title">收款信息</h2>

          <div :gutter="24" class="collectionInfoList">
            <div class="item">
              <v-text-field
                :value="(isCj
                  ? (+newGoodsPrice).toFixed(2)
                  : (+zkNewGoodsPrice).toFixed(2)) + '元'"
                label="商品价格"
                hide-details="auto"
                outlined
                :dense="true"
                :height="42"
                readonly
              >
              </v-text-field>
            </div>


             <div class="item" style="display: inline-block">
                  <v-autocomplete
                  v-model="discountType"
                    :items="discountInfoList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    :label="'商品优惠'"
                    outlined
                    item-text="activityName"
                    item-value="id"
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                     @change="selectDiscountInfo"
                    no-data-text="暂无数据"
                  ></v-autocomplete>
                  <div v-if="wxTradeOrderDiscountVOList.length" :span="24"><div style="height:24px;fontSize:14px;color:#f00">{{discountTip}}</div></div>

            </div>
            <div class="item" style="display: inline-block">
              <v-text-field
                    v-model="discountPrice"
                    label="商品优惠金额"
                    hide-details="auto"
                    outlined
                    :dense="true"
                    :height="42"
                    :disabled="!wxTradeOrderDiscountVOList.length"
                     @input="inputDiscountPrice"
                  >
                  </v-text-field>
                  <div v-if="discountNotuse.length" @click="disountsShow = true" style="line-height: 32px; fontsize: 14px; color: #f00;cursor: pointer;">
                    <i class="el-icon-warning-outline"></i>不可用优惠
                  </div>
            </div>
            <div class="item">
              <v-text-field
                :value="amountReceivable + '元'"
                label="应收金额"
                hide-details="auto"
                outlined
                :dense="true"
                :height="42"
                readonly
              >
              </v-text-field>
            </div>
          </div>
        </div>
        <div class="colllection-info-wrap">
          <h2 class="colllection-info-title">收款项目</h2>
          <receiveItem
            :columns="receiveItemColumns"
            :tableData="receiveItemDataList"
            :showSummary="true"
            :summaryMethod="receiveItemSummaryMethod"
          />
        </div>

        <div class="line"></div>
        <div class="payinfo-wrap">
          <h2 class="sub-title" style="margin: 18px 0 6px 0">付款信息</h2>
            <span>转班结算</span>
            <el-row style="margin-top: 12px">
              <el-col :span="4" style="width: 280px;">
                <div>
                  <span class="fb14 color_title">转班抵扣</span>
                  <el-tooltip effect="light" content="售后订单抵扣到转班订单的金额，用于转班订单付款" placement="top-start">
                  <!-- <el-tooltip effect="light" :content="shouldReturnPayPrice > 0?'高转低/平转，转班抵扣 = 转班应收金额':'低转高，转班抵扣 = 合计退费金额'" placement="top-start">      -->
                    <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                  </el-tooltip>
                </div>
                
                <section class="dealInfoBox fb14 color_price">{{approvedRefundableAmount | decimal2}} 元</section>
              </el-col>
              <el-col :span="4" style="width: 280px;margin-left: 24px">
                <div>
                  <span class="fb14 color_title">应退金额</span>
                  <el-tooltip effect="light" content="售后订单抵扣后剩余的金额，可用于贷款平台退费和学员退费" placement="top-start">
                  <!-- <el-tooltip effect="light" :content="shouldReturnPayPrice?'高转低/平转，应退金额 = 合计退费金额 - 转班应收金额':'低转高，应退金额 = 0'" placement="top-start">      -->
                    <i class="el-icon-question" style="font-size: 14px;color:#9BACBF;margin-left: 12px"></i>
                  </el-tooltip>
                </div>
                
                <section class="dealInfoBox fb14 color_price">{{shouldReturnPayPrice | decimal2}} 元</section>
              </el-col>
            </el-row>
        </div>
        <div class="payinfo-wrap" v-if="shouldReturnPayPrice > 0">
          <el-form-item>
            <span>贷款退费<span class="start">*</span></span>
            <check-box
              style="margin-left: -10px"
              :list="[{label: '是', value: 1}, {label: '否', value: 0}]"
              v-bind:select.sync="signUpForm.isLoanRefund"
              ref="checkLoan"
              @update:select="switchOneOrMore"
            />
          </el-form-item>
        </div>
        <div class="colllection-info-wrap loan-info-comp">
          <loan-info :loanInfoForm="dropoutForm" :rulesForm="signUpForm" :installmentMode="installmentMode" :loanRefundStuMoney="loanRefundStuMoney" 
            :loanIouNoDisabled="loanIouNoDisabled"
            :rules="formRules" 
            :orderTable="orderTable"
            :loanPlatformList="loanPlatformList"
            :loanRefundMoneyVerify="loanRefundMoneyVerify"
            v-if="signUpForm.isLoanRefund && shouldReturnPayPrice > 0"
          />
        </div>

        <div class="payinfo-wrap">
          <span>学员退费</span>
          <el-row style="margin-top: 12px">
            <div class="fb14 color_title">退费金额</div>
            <section class="dealInfoBox fb14 color_price" style="margin-right: 20px;display: inline-block">{{loanRefundStuMoney | decimal2}} 元</section>
            <span v-if="loanRefundStuMoney < 0" class="approvedRefundTxt" style="color: red"><i class="el-icon-warning"></i>原订单需要学员补款，暂不支持售后申请</span>
          </el-row>
          <el-form-item class="border" prop="payMethod" v-if="loanRefundStuMoney !== 0">
            <span>付款方式<span class="start">*</span></span>
            <el-tooltip class="item" effect="light" content="售后订单扣减后剩余的金额，可用于贷款平台退费和学员退费" placement="top-start">
              <div slot="content">1、学员余额，售后订单退费至学员学习账号，下次报名时可使用余额付款<br/>
              2、银行账户，售后订单退费至学员银行账户，下次报名时需学员重新付款</div>
              <i class="el-icon-question" style="color: #9BACBF;font-size: 18px;margin-left: 8px"></i>
            </el-tooltip>
            <check-box
              style="margin-left: -10px;margin-top: 10px"
              :list="[{label: '银行账户', value: refundPayTypeEnum.bank},{label: '学员余额', value: refundPayTypeEnum.balance}]"
              v-bind:select.sync="dropoutForm.payType"
              ref="check"
            />
            <span style="color:#f00" v-if="dropoutForm.payType == '0'" >学员余额仅用于一转多的场景，该学员的余额仅限于当前学员使用，不支持转给其他学员所使用</span>
            <div
              v-show="dropoutForm.payType == '0'"
              style="margin-top: 10px"
              class="radio1-content"
            >
              <el-form-item style="display: inline-block;margin-right: 24px;width:282px;" prop="a">
                <!-- <v-autocomplete
                  v-model.trim="signUpForm.dropOrderNo"
                  :items="canDropoutList"
                  :menu-props="{ bottom: true, offsetY: true }"
                  item-text="mobile"
                  item-value="orderNo"
                  placeholder="请选择学员账号"
                  outlined
                  :dense="true"
                  :height="42"
                  hide-details="auto"
                  @change="changeDropoutMobile"
                  :readonly="disabledChangeDropOrderNo"
                ></v-autocomplete> -->
                <v-text-field
                  hide-details="auto"
                  v-model.trim="signUpForm.dropMobile"
                  label="学员账号"
                  disabled
                  outlined
                  :dense="true"
                  :height="42"
                ></v-text-field>
              </el-form-item>
              <el-form-item style="display: inline-block;width:282px;">
                <v-text-field
                  hide-details="auto"
                  v-model.trim="signUpForm.dropName"
                  label="学员姓名"
                  disabled
                  outlined
                  :dense="true"
                  :height="42"
                ></v-text-field>
              </el-form-item>
            </div>
            <div
              v-show="dropoutForm.payType == '1'"
              style="margin-top: 10px"
              class="radio2-content"
            >
              <el-form-item
                style="margin-right: 24px;display: inline-block;width:282px;margin-bottom: 12px"
                prop="bankAccountName"
              >
                <v-text-field
                  hide-details="auto"
                  v-model.trim="signUpForm.bankAccountName"
                  placeholder="学员开户名"
                  outlined
                  :dense="true"
                  :height="42"
                ></v-text-field>
              </el-form-item>
              <el-form-item style="display: inline-block;margin-right: 24px;width:282px;" prop="bank">
                <v-text-field
                  hide-details="auto"
                  v-model.trim="signUpForm.bank"
                  placeholder="学员开户行及网点"
                  outlined
                  :dense="true"
                  :height="42"
                ></v-text-field>
              </el-form-item>
              <el-form-item
                style="display: inline-block;width:282px;"
                prop="bankAccount"
              >
                <v-text-field
                  hide-details="auto"
                  v-model.trim="signUpForm.bankAccount"
                  placeholder="学员户行账号"
                  outlined
                  :dense="true"
                  :height="42"
                ></v-text-field>
              </el-form-item>
              <div style="margin-top: 10px;">
                <el-form-item style="display: inline-block;width:588px;" prop="ncBankAccountPk">
                  <v-autocomplete
                    v-model.trim="signUpForm.ncBankAccountPk"
                    :items="payBankList"
                    :menu-props="{ bottom: true, offsetY: true }"
                    item-text="accountName"
                    item-value="bankAccountPk"
                    placeholder="付款银行"
                    outlined
                    :dense="true"
                    :height="42"
                    :clearable="true"
                    clear-icon="$clear"
                    hide-details="auto"
                    @change="onBankAccountPkChange"
                  >
                  </v-autocomplete>
                </el-form-item>
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div class="bottom-btn">
      <el-button @click="doBack" size="middle" :gap="20"
        >返回</el-button
      >
      <el-button :disabled="!currentGoods.goodsId" @click="showSureData" type="primary" size="middle" :loading="submitLoading"
        >提交</el-button
      >
    </div>
    <order-modal
      ref="orderModal"
      :visible="orderModalVisible"
      :aftersaleType="2"
      :orderModalFields="orderModalFields"
      :orderModalItems="orderModalItems"
      :orderModalRules="orderModalRules"
      :selectMode="signUpForm.oneOrMore"
      @on-cancel="onOrderModalCancel"
      @on-ok="onOrderModalOk"
    />
    <goods-specs-modal
      :visible="gsVisible"
      :specTypeArr="specTypeArr"
      :specArray="specArray"
      :specCountArr="specCountArr"
      :specPriceArr="specPriceArr"
      :key="goodsSpecModalKey"
      :currentGoodsRank="currentGoodsRank"
      @on-cancel="onGsCancel"
      @onSpecModalOk="onSpecModalOk"
    />
    <goods-modal
      :visible.sync="goodsDrawerVisible"
      :goodsForm="goodsForm"
      :goodsFormRules="goodsFormRules"
      :goodsFormItems="goodsFormItems"
      businessId=""
      :selectGoodsType="selectGoodsType"
      :enrollingMajorId="firstEnrollingMajorId"
      :enrollingGradation="ckSingle.enrollingTime"
      :enrollingProductId="currentGoods.productId"
      :type="goodModalTypeEnum.transfer"
      @on-ok="onGoodsDrawerOk"
      ref="goodsModalRef"
      :schoolId="ncDealSchoolId"
    />
    <!-- 选中收支项目 -->
    <receiveOut
      :visible="receiveOutVisible"
      :aftersaleType="2"
      :orderModalFields="receiveOutFields"
      :orderModalItems="receiveOut"
      :orderModalRules="orderModalRules"
      :dropOutTableList="dropOutTableList"
      :currentSelDrop="currentSelDrop"
      selectMode="1"
      @on-cancel="onReceiveOutCancel"
      @on-ok="onReceiveOutOk"
    />
    <!-- 提交时确认订单弹窗 -->
    <sure
      :visible="sureVisible"
      showTitle="转班报名确认"
      :sureData="sureData"
      :loading="loading"
      @on-cancel="onSureCancel"
      @on-ok="onSureOk"
    />
    <!-- 多转一 选择学员 -->
    <selUser
      :visible="selUserVisible"
      :aftersaleType="2"
      :orderModalFields="selUserFields"
      :orderModalItems="selUser"
      :orderModalRules="selUserRules"
      selectMode="1"
      @on-cancel="onSelUserCancel"
      @on-ok="onSelUserOk"
    />
    <el-dialog title="提示" :visible.sync="showDelOrder" width="30%">
      <span>删除该售后订单将清空已选转班商品信息，确认是否删除订单</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDelOrder = false">取 消</el-button>
        <el-button type="primary" @click="sureDelOrder">确 定</el-button>
      </span>
    </el-dialog>

    <el-drawer
      title="选择专业"
      :visible.sync="showTable"
      direction="rtl"
      size="70%"
    >
      <div class="meunBox">
        <section>
          <div
            v-for="(mealGroupItem, mealGroupIndex) in zkMultiple.mealGroupData"
            :key="mealGroupIndex"
          >
            <div
              class="goodsSelBox"
              v-for="(
                goodsGroupItem, goodsGroupIndex
              ) in mealGroupItem.goodsGroupList"
              :key="goodsGroupIndex"
            >
              <div class="header-part">
                <div>
                  <span class="title">{{ goodsGroupItem.name }}</span>
                  <span class="intro">
                    (共{{ goodsGroupItem.goods.length }}门，任选 1 门)</span
                  >
                </div>
                <div>
                  <span class="selNum">已选 </span>
                  <span class="nowSel">{{
                    goodsGroupItem.selected_count || 0
                  }}</span
                  >/<span class="canSel">1</span>
                </div>
              </div>
              <div class="classBox">
                <ul>
                  <li
                    v-for="(goodsItem, goodsIndex) in goodsGroupItem.goods"
                    :key="goodsIndex"
                    :class="goodsItem.isSelected ? 'selected' : ''"
                    @click="
                      selectGoods(mealGroupIndex, goodsGroupIndex, goodsIndex)
                    "
                  >
                    <el-checkbox v-model="goodsItem.isSelected"></el-checkbox>
                    <div style="margin-left: 12px">{{ goodsItem.name }}</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="btns" style="display: flex; justify-content: center">
            <el-button
              size="large"
              type="primary"
              @click="professionalAlertEnsure"
              >确定</el-button
            >
            <el-button size="large" @click="professionalAlertCancel"
              >取消</el-button
            >
          </div>
        </section>
      </div>
    </el-drawer>

    <HqModal
      :visible="disountsShow"
      title="不可用优惠"
      @on-cancel="disountsShow = false"
      width="480"
    >
      <div class="discount-modal" v-for="item of discountNotuse" :key="item.id">
        <div>{{ item.activityName }}</div>
        <div>优惠¥0.00~ ¥{{ item.preferentialAmount }}.00</div>
        <div>
          <div>学员不符合优惠条件</div>
          <div>{{ item.endTime }} 过期</div>
        </div>
      </div>
      <div slot="footer">
        <el-button
          size="small"
          type="primary"
          @click="disountsShow = false"
        >确认</el-button>
      </div>
    </HqModal>

    <discount @saveDicount="saveDicount" ref="discount" />

    <!-- 没通过的弹窗  showTis-->
    <HqModal
      :visible="showTis"
      title="提交失败"
      @on-cancel="tijiaoProcancel"
      width="480"
    >
    <div><i icon="el-icon-warning" type="danger"></i>失败原因</div>
    <div class="tishiclass">
      <ul  
        v-for="(item, index) in tiforList"
        :key="index">
        <li>{{item}}</li>
      </ul>
    </div>
    <v-textarea
      v-model.trim="lingyuanForm.lingyuan"
      hide-details="auto"
      label="开通零元课程原因"
      placeholder="请输入开通零元课程原因,500字以内"
      outlined
      :dense="true"
      :height="120"
      maxlength="500"
      counter
    ></v-textarea>
    <!-- <el-form
      :model="lingyuanForm"
      :rules="lingyuanFormRules"
      label-position="right"
      ref="form"
    >
      <el-form-item prop="lingyuan" class="Contextclass">
         <v-textarea
          v-model.trim="lingyuanForm.lingyuan"
          hide-details="auto"
          label="开通零元课程原因"
          placeholder="请输入开通零元课程原因,500字以内"
          outlined
          :dense="true"
          :height="120"
          maxlength="500"
          counter
        ></v-textarea>
      </el-form-item>
    </el-form> -->
    <div slot="footer">
      <el-button
        size="small"
        @click="tijiaoProcancel"
      >取消</el-button>
      <el-button
        size="small"
        type="primary"
        @click="tijiaoProadd"
      >提交审批</el-button>
    </div>
    </HqModal>
  </div>
</template>

<script>
import {
  allPayMap,
  payTypeMap,
  orderStatusMap,
  afterSaleStatusMap,
  installmentTypeMap,
  refundPayTypeEnum,
  refundPayTypeMap,
  xlTypeEnum,
  businessIdEnum,
  refundTypeEnum,
  installmentTypeEnum,
  volunteerEnum,
  goodsTemplateEnum,
  mockCashierChecker,
  statusOnlineSellEnum,
  selectGoodsTypeEnum,
  goodModalTypeEnum,
  aftersaleTypeEnum,
  orderTypeEnum,
  schoolIdEnum,
  freeEnum,
  installmentModeEnum,
  OrderOriginTypeEnum,
  recommandTypeMap
} from "../../constants";

import { 
    getSchoolProperties,
    getSchoolUserCashierVerifiedUser,
    getCustomerData,
    getJiamiCustomerData
} from "api/customer";
import { mapGetters } from "vuex";
import { accAdd, accSub } from "@/utils/mathMethod";
import Checker from "@/utils/Checker";
import common from '@/views/order/after-sale-nc/mixins/common'
import { getNcOrderDetail, saveTransactionInfo, 
getNCOrderList, getSelectStudentInfo, getSelectLjUserInfo, doSaveLjUser } from '@/api/order/change';
import validate from "@/utils/validate";
import axios from "axios"

const getRandomKey = () => {
  return Math.random().toString().slice(4);
};
export default {
  name: "OrderTransfer",
  mixins: [common],
  data() {

    const orderTableValidator = (rule, value, cb) => {
      if (!this.orderTable.length) {
        return cb(new Error("请选择订单"));
      }
      cb();
    };
    const checkMobile = (rule, value, cb) => {
      if (!this.studentInfo.mobile) {
        return cb(new Error("此字段必填！"));
      }
      cb();
    };
    const checkStuName = (rule, value, cb) => {
      if (!this.studentInfo.realName) {
        return cb(new Error("此字段必填！"));
      }
      cb();
    };
    const checKidCard = (rule, value, cb) => {
  
      if (!this.signUpForm.idCard) {
        return cb(new Error("此字段必填！"));
      }
      cb();
    };
    const cashierCheckValidator = (rule, value, cb) => {
      if (!this.cashierData) {
        return cb(new Error("此字段必选!"));
      }
      cb();
    };

    return {
      lingyuan: '',
      lingyuanForm: {
        lingyuan: ''
      },
      lingyuanFormRules: {lingyuan: [{ required: true, message: "此字段必填！", trigger: "change" }],},
      tiforList: [],
      showTis: false,
      processParmas: {},
      discountType: '',
      //优惠活动字段
      // activityId: 活动id,
      // discount:   优惠金额
      wxTradeOrderDiscountVOList: [],  
      discountPrice: 0,
      discountNewPrice: 0,
      discountTip:"",
      discountInfoList:[],
      discountNotuse: [],
      transactionInfo:{},


      waitPayPrice: 0,
      shouldReturnPayPrice: 0,
      // goodsDiscount:0,
      showTable: false,
      xlType: 1,
      //多个<template>隐藏显示控制
      ctr: {
        showCjSingleOption: false,
        showZkSingleOption: false,
        showZkMultipleOption: false,
        showCkSingleOption: false,
      },
      //获取报考省份，已经该省份可选择的院校
      zkSingle: {
        enrollingProvinceList: [],
        enrollingSchoolList: [],
        specificationPriceDataSource: [],
        zkGoodsSpecTable: [],
        courseList: [],
        form: { provinceId: "", schoolId: "" },

        zkGoodsColumns: [
          { label: "商品价格", prop: "goodsPrice" },
          { label: "会答总数", prop: "kuaidaTotal" },
          { label: "学习有效期（月）", prop: "effectiveMonth" },
          { label: "培训有效期（月）", prop: "trainEffectiveMonth" },
          { label: "复训期（月）", prop: "retrainingCount" },
          { label: "休学次数", prop: "suspendSchoolCount" },
          { label: "转校次数", prop: "shiftSchoolCount" },
        ],
      },

      zkMultiple: {
        //是否是双层次
        isDoubleProfessional: false,
        //双层次中的专科- 省
        zkEnrollingProvinceList: [],
        //双层次中的专科 - 院校
        zkEnrollingSchoolList: [],
        //双层次中的本科 - 省
        bkEnrollingProvinceList: [],
        //双层次中的本科 - 院校
        bkEnrollingSchoolList: [],
        //用于存储双层次中已经选的专科的省，院校
        selected: {
          zkProvinceId: "", //双层次下的专科省
          zkProvinceName: "",
          zkSchoolId: "", //双层次下的专科院校
          zkSchoolName: "",
          bkProvinceId: "", //双层次下的本科省
          bkProvinceName: "",
          bkSchoolId: "", // 双层次下的本科院校
          bkSchoolName: "",
        },

        enrollingProvinceList: [],
        enrollingSchoolList: [],
        specificationPriceDataSource: [],
        zkGoodsSpecTable: [],
        courseList: [],
        mealGroupData: [],
        curEnrollingMajorName: "--", //报考专业选择时的名字的拼接（可以是多个专业+名字）
        curClassInfo: "", //当前选择的班型信息
        form: {
          provinceId: "",
          schoolId: "",
          zkProvinceId: "",
          zkSchoolId: "",
          bkProvinceId: "",
          bkSchoolId: "",
        },
        zkGoodsColumns: [
          { label: "商品价格", prop: "goodsPrice" },
          { label: "会答总数", prop: "kuaidaTotal" },
          { label: "会答频次（天）", prop: "kuaidaFrequency" },
          { label: "学习有效期（月）", prop: "effectiveMonth" },
          { label: "培训有效期（月）", prop: "trainEffectiveMonth" },
          { label: "复训期（月）", prop: "retrainingCount" },
          { label: "休学次数", prop: "suspendSchoolCount" },
          { label: "转校次数", prop: "shiftSchoolCount" },
        ],
      },

      ckSingle: {
        xlType: 2,
        goodsId: "",
        goodsPrice: "", //商品价格
        enrollingGradationDatasource: [],
        enrollingGradationList: [],
        enrollingMajorList: [],
        specificationPriceDataSource: [],
        courseList: [],
        form: {
          enrollingGradationId: "",
          enrollingMajorId: "",
          // 第二志愿 start
          enrollingGradationId2: "",
          enrollingMajorId2: "",
          // 第二志愿 end
        },
        // 第二志愿 start
        xlType2: 2,
        goodsId2: "",
        goodsPrice2: "", //商品价格
        enrollingGradationDatasource2: [],
        enrollingGradationList2: [],
        enrollingMajorList2: [],
        specificationPriceDataSource2: [],
        courseList2: []
        // 第二志愿 end
      },
      //原来购买的商品是否是财经商品
      originGoodsIsCj: true,
      //转班时选择的商品类型
      isCj: true, //决定了 businssInt值
      zkNewGoodsPrice: 0,
      zkGoodsDiscount: 0,
      /***
       * 针对新加的学历
       */
      graduactionForm: {
        enrollingProvinceIdList: [], //报考省份名称集合
        enrollingProvinceNameList: [], //报考省份名称集合
        enrollingMajorIdList: [], //报考专业id集合
        enrollingMajorNameList: [], //报考专业名称集合
        enrollingSchoolIdList: [], //报考院校id集合
        enrollingSchoolNameList: [], //报考省份名称集合
        gradationIdList: [], //报考层次(1专科  2本科)
        enrollingTime: "", //报考时间(用于记录成考、网教报考学级)
        xlCommodityIdList: [], //学历的规格ids
        // 成考第二志愿 start
        enrollingProvinceIdList2: [], //报考省份名称集合
        enrollingProvinceNameList2: [], //报考省份名称集合
        enrollingMajorIdList2: [], //报考专业id集合
        enrollingMajorNameList2: [], //报考专业名称集合
        enrollingSchoolIdList2: [], //报考院校id集合
        enrollingSchoolNameList2: [], //报考省份名称集合
        gradationIdList2: [], //报考层次(1专科  2本科)
        enrollingTime2: "", //报考时间(用于记录成考、网教报考学级)
        xlCommodityIdList2: [], //学历的规格ids
        // 成考第二志愿 end
      },

      loanArray: [],
      previousSpecIdArr: null,
      cashierCheckList: [],
      cashierData: "",
      sureVisible: false, //提交时确认弹出
      sureData: {},
      receiveOutVisible: false, //收支项目框
      receiveOutFields: {
        select: "",
        name: "",
        code: "",
      },
      receiveItemData: [], //收费项目
      receiveItemData2: [], //第二志愿收费项目
      tmpRow: null, //临时存要删除的订单
      showDelOrder: false,
      breadcrumbs: [
        { title: "订单售后管理", path: "/aftersale/list" },
        { title: "申请售后" },
      ],
      signUpForm: {
        oneOrMore: "0",
        // 原商品名
        oldGoodsName: "",
        reason: "",
        phpneNumber: "",
        userName: "",
        idCard: "",
        // 学员真正的学习账号
        mobile: "",
        // 学员手机
        stuMoblie: "",
        // 选择的商品名称
        goodsName: "",
        // 选择的商品规格名称
        spec: "",
        cashierId: '',//出纳审核人id
        // 成考第二志愿 start
        goodsName2: '',
        oldGoodsName2: '',
        // 成考第二志愿 end
        // 贷款相关-只作为触发表单校验。
        loanRefundTime: '',
        loanCompanyName: '',
        loanBankName: '',
        loanBankAccount: '',
        loanRefundMoney: '',
        loanIouNo: '',
        loanReturnedMoney: '',
        // end 贷款相关-只作为触发表单校验。
        dropOrderNo: "",
        dropMobile: "",
        dropName: "", //学员余额退款时的姓名
        // 支付方式银行账户
        bank: "",
        bankAccountName: "",
        bankAccount: "",
        // 付款银行信息:start
        ncAccountName: '',
        ncBankAccountPk: '',
        // 付款银行信息:end
        jobLowestSalaryUnderCollege: '',
        jobLowestSalaryUpCollege: '',
        // 是否 贷款
        isLoanRefund: 0,
        loanOccupiedinterestMoney: 0,
        loanRefundType: "",
        loanPlatformId: "",
        // 学历
        education: "",
      },
      loanIouNoDisabled: false,
      //退款时选择学员余额
      dropInfo: {
        dropOrderNo: "",
        dropMobile: "",
        dropName: "", //学员余额退款时的姓名
      },
      specArray: [],
      receiveItemColumns: [
        {label: "收支项目", prop: "inoutProjectName", width: "300", fixed: true,},
        { label: "标准价格", prop: "standardPrice", number: true },
        { label: "优惠额", prop: "priceDiscount", number: true },
        { label: "应收金额", prop: "shouldRecive", number: true },
      ],
      orderTable: [],
      orderColumns: [
        { label: "订单号", prop: "orderNo", width: "200px", fixed: true },
        { label: "报读商品", prop: "seSeQuenName", fixed: true },
        { label: "班型", prop: "goodsSpecName", width: "120px" },
        { label: "产品线", prop: "prodLineName",width:"120px" },
        { label: "商品分类", prop: "goodsCategoryName",width: "100px" },
        { label: "产品系列", prop: "priTypeName",width: "100px" },
        { label: "学习帐号", prop: "phone" ,width: "140px"},
        { label: "学员姓名", prop: "stuName",width: "140px" },
        { label: "身份证号", prop: "idCard", width: "185px" },
        { label: "客户编号", prop: "customerIdCode", width: "185px" },
        { label: "应收金额", prop: "dnshoulddCost",width: "100px" },
        { label: "已收金额", prop: "thisReceivAble",width: "100px" },
        { label: "订单状态", prop: "orderStatus",width: "130px" },
        { label: "售后", prop: "afterSaleTypes",width: "100px" },
        { label: "招生老师", prop: "recruiterName" ,width: "140px"},
        { label: "上课校区", prop: "campusName",width: "140px" },
        { label: "报名时间", prop: "registDate",width: "140px" },
      ],
      dropoutForm: {
        payType: "1",
        // bank: "",
        // bankAccountName: "",
        // bankAccount: "",
        // 有钱花贷款
        loanRefundTime: '',
        loanCompanyName: '',
        loanBankName: '',
        loanBankAccount: '',
        loanRefundMoney: '',
        loanIouNo: '',
        loanReturnedMoney: '',
        // 有钱花贷款 end
        // // 付款银行信息:start
        // ncAccountName: '',
        // ncBankAccountPk: '',
        // // 付款银行信息:end
        loanOccupiedinterestMoney: 0,
        loanRefundType: "",
        loanPlatformId: ""
      },
      dropOutTableList: [],
      dropOutColumns: [
        // { label: "商品 ID", prop: "goodsId", width:"180px"},
        // { label: "商品名", prop: "goodsName", },
        {
          label: "收支项目",
          prop: "orderInoutProjectName",
          width: "160px",
        },
        { label: "应收金额", prop: "receivableMoney", width: "100px" },
        { label: "已收金额", prop: "receivedMoney", width: "100px" },
        { label: "扣费金额", prop: "deductionMoney", width: "130px" },
        { label: "核定可退款金额", prop: "refundableMoney", width: "130px" },
        { label: "操作", prop: "canDo", width: "130px" },
      ],
      goodsSpecTable: [],
      goodsColumns: [
        { label: "商品价格", prop: "goodsPrice" },
        { label: "会答总数", prop: "kuaidaTotal" },
        { label: "学习有效期（月）", prop: "effectiveMonth" },
        { label: "培训有效期（月）", prop: "trainEffectiveMonth" },
        { label: "复训期（月）", prop: "retrainingCount" },
        { label: "重读次数", prop: "restudyCount" },
        { label: "请假次数", prop: "leaveCount" },
        { label: "转班次数", prop: "shiftClassCount" },
        { label: "休学次数", prop: "suspendSchoolCount" },
        { label: "转校次数", prop: "shiftSchoolCount" },
      ],
      giftTableData: [],
      giftTableColumns: [
        { label: "附属商品", prop: "goodsName" },
        { label: "赠品类型", prop: "giftType" },
      ],
      phoneCodeStatus: false,
      phoneTipMsg: "发送短信验证码",
      smsCodeTimer: null,
      // 订单弹窗显隐
      orderModalVisible: false,
      orderModalFields: {
        productId: "",
        orderNo: "",
        goodsName: "",
        stuName: "",
        mobile: "",
        customerCode: "",
        idCard: "",
        goodsId: "",
      },

      orderModalItems: [
        {
          type: "select",
          label: "产品线",
          prop: "productId",
          options: [],
        },
        {
          label: "报名单号",
          prop: "orderNo",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "商品ID",
          prop: "goodsId",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "报读商品",
          prop: "goodsName",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "学员账号",
          prop: "mobile",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "学员姓名",
          prop: "stuName",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "客户编号",
          prop: "customerCode",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "身份证号",
          prop: "idCard",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
      ],
      receiveOut: [
        {
          label: "收支项目类型",
          type: "select",
          prop: "type",
          itemStyle: "",
          style: "",
          placeholder: "请选择",
          options: [
            {
              value: "代收代付",
              label: "代收代付",
            },
            {
              value: "营业收入",
              label: "营业收入",
            },
            {
              value: "其他",
              label: "其他",
            },
            {
              value: "其他收入",
              label: "其他收入",
            },
          ],
        },
        {
          label: "收支项目名称",
          prop: "name",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "收支项目编码",
          prop: "code",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
      ],
      orderModalRules: {},
      studentInfo: {},
      orderExtraInfo: {}, //原订单详情
      teachOrder: {}, //成交信息
      idCardVisible: false,
      gsVisible: false,
      // 商品规格数组
      specList: [],
      // 商品规格价格数组
      specPriceList: [],
      goodsSpecModalKey: getRandomKey(),
      // --------------商品弹窗区------------------
      goodsDrawerVisible: false,
      goodsForm: {
        productId: "",
        goodsTypeId: "",
        goodsTypeName: "",
        goodsCategoryName: "",
        goodsCategoryId: "",
        goodsRank: "",
        goodsId: "",
        goodsName: ""
      },
      selectGoodsType: String(selectGoodsTypeEnum.qwTransfer),
      currentGoods: {},
      currentGoods2: {},// 当前成考第二志愿
      goodsFormRules: {},
      goodsFormItems: [
        {
          type: "select",
          label: "产品线",
          prop: "productId",
          options: [],
        },
        {
          type: "search",
          label: "商品分类",
          prop: "goodsCategoryName",
          placeholder: "请选择商品分类",
        },
      ],

      businessId: this.$route.query.businessId || "1",
      selectSpecIds: [],
      // 套餐分组信息
      mealGroupData: [],
      oneItem: [], //单品的信息
      // 短信验证码剩余时间
      smsCodeInterval: 60,
      collectionInfo: {
        // 选择的新转班商品售价
        newGoodsPrice: "0",
        // 原商品的售价->总已收金额
        oldGoodsPrice: "0",
        // 转班折扣 ，原商品的总退费金额
        discountPrice: 0,
        // 是否使用余额
        isBalancePay: "0",
        isLoan: "0",
        // 学员的账户上的余额
        stuAccountMoney: "0",
        // 余额抵扣的价格
        balancePay: 0,
        // 余额抵扣的账户
        userMobile: "",
        // 短信验证码
        phoneCode: "",
        // 不包余额抵扣的应收款，最终价格 取计算属性的finalPrice
        finalPrice: "0",
        // 最后计算的价格 应退款
        refundMoney: "0",
        goodsDiscountPrice: 0, //优惠价格
        recegoodsmoney: "0",
      },
      // 选取的原订单id数组
      orderIds: [],
      specTypeArr: [],
      specPriceArr: [],
      currentGoodsRank: 0,
      allPayMap,
      payTypeMap,
      orderStatusMap,
      afterSaleStatusMap,
      installmentTypeMap,
      specCountArr: [],
      showloanform: false, //贷款本金内容
      userSelectMealGroup: [],
      classInfo: [], //退费信息  退费计算的商品，已收上课等信息
      afterSaleId: this.$route.query.afterSaleId,
      loading: false,
      hasStuInfo: false, //是否有在蓝鲸内部查到学生信息，查到就更新，没查到就保存 true是更新
      /** 多转一选择学员弹窗 **/
      selUserVisible: false, //多转一选择学员的弹窗
      selUserFields: {
        userId: "",
        name: "",
        mobile: "",
      }, //多转一选择学员的弹窗fileds
      selUserRules: {},
      selUser: [
        {
          label: "学员姓名",
          prop: "name",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "学员账号",
          prop: "mobile",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
        {
          label: "学员ID",
          prop: "userId",
          itemStyle: "",
          style: "",
          placeholder: "请填写",
        },
      ],
      seledUser: {}, //多转一选中的学员
      useDraft: true, //是否使用了草稿的内容，草稿内容只用一次，防止getDetailData一直走草稿if
      currentSelDrop: null, //当前选中表格的收支项目
      specificationPriceArr: [], //规格相关信息
      showloanCheck: true, //显示贷款按钮
      loanPrice: [], //用来装贷款平台信息
      // 成交信息
      makeList: [
        {label: "客户编号", value: "customerId"},
        {label: "商机编号", value: "ncCustomerUserId"},
        {label: "学员手机", value: "phoneNumber"},
        {label: "招生老师", value: "teacherRecruitName"},
        {label: "销售老师", value: "teacherSalesName"},
        {label: "成交校区", value: "dealSchoolName"},
        {label: "上课校区", value: "campusSchoolName"},
        {label: "转介绍", value: "isRecommandName"}, //是否是转介绍
        {label: "转介绍类型", value: "transIntroduceType"},
        {label: "转介绍老师", value: "teacherRecommandName"},
        {label: "小型公开课", value: "openClassType"},
        {label: "讲师", value: "teacherLecturerName"},
        {label: "流量经纪人", value: "economicFlowPartner"},
        {label: "流量合伙人", value: "flowPartner"},
        {label: "促单形式", value: "promotionForm"},
        {label: "招生途径", value: "channelsTypeName"},
        {label: "是否46分成", value: "isFourSix"}, //是否46分成
      ],
      refundPayTypeEnum,
      cjComComGoodsInfo: {},
      comComGoodsInfoList: [],
      cjComComGoodsColumns: [
        { label: "班型", prop: "goodsName", width: "260px" },
        { label: "会答总数", prop: "kuaidaTotal", width: "140px"},
        { label: "学习有效期（月）", prop: "effectiveMonth", width: "140px" },
        {
          label: "培训有效期（月）",
          prop: "trainEffectiveMonth",
          width: "140px",
        },
        { label: "复训期（月）", prop: "retrainingCount", width: "140px" },
        { label: "请假次数", prop: "leaveCount", width: "140px" },
        { label: "转班次数", prop: "shiftClassCount", width: "140px" },
        { label: "转校次数", prop: "shiftSchoolCount", width: "140px" },
        { label: "重读次数", prop: "restudyCount", width: "140px" },
        { label: "休学次数", prop: "suspendSchoolCount", width: "140px" },
      ],
      SecondGoods: 2,//常量：成考第二志愿
      xlTypeEnum,
      installmentTypeEnum,
      goodsTemplateEnum,
      goodModalTypeEnum,
      disabledChangeStuMobile: false,//禁止修改学员信息-学员帐号
      submitLoading: false,
      installmentModeEnum,
      disabledChangeDropOrderNo: false,
      payBankList: [],//付款银行列表
      recommandTypeMap,
      formRules: {},
      // 出纳校验
      cashierFormRules: {
        goodsName: [
          { required: true, message: "此字段必填！", trigger: "blur" },
        ],
        spec: [
          { required: true, message: "此字段必填！", trigger: "blur" },
        ],
        oldGoodsName: [
          { required: true, message: "此字段必填！", trigger: "blur" },
        ],
        originOrder: [
          {
            required: true,
            validator: orderTableValidator,
            trigger: ["click", "change"],
          },
        ],
        mustWirte: [
          {
            required: true,
            validator: orderTableValidator,
            trigger: ["click", "change"],
          },
        ],
        stuMoblie: [{ required: true, message: '请填写学员账号', trigger: "change" }],
        mobile: [{ required: true, validator: checkMobile, trigger: "change" }],
        stuName: [{ required: true, validator: checkStuName, trigger: "blur" }],
        idCard: [{ required: true, validator: checKidCard, trigger: "blur" }],
        education: [{ required: true, message: '请选择学员学历', trigger: "blur" }],
        cashierCheck: [
          {
            required: true,
            validator: cashierCheckValidator,
            trigger: ["click", "change"],
          },
        ],
        reason: [{required: true,message: "此项必填！",tirgger: "submit" }],
        jobLowestSalaryUnderCollege: [{ required: true, validator: validate.underCollege, trigger: "change" }],
        jobLowestSalaryUpCollege: [{ required: true, validator: validate.upCollege, trigger: "change" }],
      },
      firstMajor: "",
      // 列表信息
      tableData: [],
      // 优惠劵新增4个字段
      activedParams: {},
      marketInoutConfigSnapshotId: "",
      inoutConfigSnapshot: null,
      // 是否展示协议
      employmentShow: false,
      disountsShow: false,
      // 合计扣费金额
      allDeductionMoney: 0,
      loanPlatformList: [],
      cashierId: "",
      phoneList: [],
      // 报名商品是否重复 
      repeat: false,
      // 商品定价状态
      statusPrice: "",
      // 是否可以修改学习账号
      isCanStudy: false,
      findSchoolList: [],
      showYxOption: false,
      majorList: [],
      // 第二志愿
      findtwoSchoolList: [],
      showYxOption2: false,
      canSelectMajor: false,
      assistDealList:[],//协助成交人员列表数据
    };
  },
  watch: {

    /***
     * 使用余额
     * 当余额发生变化时，应该更新待收金额和应退金额。
     */
    "collectionInfo.balancePay": {
       immediate: true,
       deep: true,
        handler(newVal, oldVal) {
          console.log("collectionInfo.balancePay: "+newVal)
          if (newVal && newVal.indexOf(" ") != -1) {
            this.collectionInfo.balancePay = oldVal;
            return;
          }
          newVal = newVal.toString();
          var pointIndex = newVal.indexOf(".");
          if (pointIndex > 0 && newVal.length - pointIndex > 3) {
            this.collectionInfo.balancePay = oldVal;
            return;
          }
        },
       
    },
    //转班折扣跟随退费信息里的 核定可退金额 变动
    approvedRefundableAmount(newVal) {
      this.collectionInfo.discountPrice = newVal;
      this.collectionInfo.oldGoodsPrice = newVal;
      this.calcFinalMoney();
    },
    "collectionInfo.userMobile"(newVal) {
      if (newVal.length < 11) {
        this.collectionInfo.userName = "";
        this.collectionInfo.stuAccountMoney = 0;
        this.collectionInfo.balancePay = 0;
        if (newVal.length != 0) {
          this.calcFinalMoney();
        }
      }
    },
    dropOutTableList: {
      deep: true,
      handler(dropOutTableList){
        if(dropOutTableList.length){
          let deductionMoney = 0;
          this.dropoutForm.loanOccupiedinterestMoney = 0
          dropOutTableList.map((dropOutTable, dropOutTableIndex) =>{
          
            let refundMoney = 0
            if(dropOutTable.length){
              dropOutTable.map(row =>{
                if(row.orderInoutProjectName == '利息支出') {
                  this.dropoutForm.loanOccupiedinterestMoney = Number(this.dropoutForm.loanOccupiedinterestMoney) + Number(row.deductionMoney)
                } 
                refundMoney += isNaN(+row.refundableMoney) ? 0 : +row.refundableMoney
              })
            }
            if(this.classInfo[dropOutTableIndex]){
              this.classInfo[dropOutTableIndex].refundMoney = refundMoney
            }

            let rowDeductionMoney = dropOutTable.reduce((pre, cur)=> accAdd(pre, isNaN(+cur.deductionMoney) ? 0 : cur.deductionMoney), 0)
            deductionMoney += isNaN(+rowDeductionMoney) ? 0 : +rowDeductionMoney
          })

          // 合计扣费金额
          this.allDeductionMoney = deductionMoney || 0
        }
      }
    },
    "dropoutForm.payType": {
      handler(value) {
        // 学员余额
        if(value == 0) {
          this.formRules = {
            dropOrderNo: [{required: true,message: "此项必填！！",tirgger: "submit" }],
            dropName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
            ...this.cashierFormRules
          }
        // 银行支付
        } else if(value == 1) {
          this.formRules = {
            bankAccountName: [{required: true,message: "此项必填！！",tirgger: "submit" }],
            bank: [{required: true,message: "此项必填！！",tirgger: "submit" }],
            bankAccount: [{required: true,message: "此项必填！！",tirgger: "submit" }],
            ncBankAccountPk: [{required: true,message: "此项必填！！",tirgger: "submit" }],
            ...this.cashierFormRules
          }
        }
        this.$nextTick(() => {
          this.$refs.form && this.$refs.form.clearValidate()
        })
      },
      immediate: true
    }
  },
  components: {
    orderModal: () => import("../../components/order-modal"),
    goodsSpecsModal: () => import("../../components/goods-specs-modal1"),
    goodsModal: () => import("../../components/goods-modal1"),
    receiveItem: () => import("../../components/receive-item-table.vue"),
    receiveOut: () => import("../../components/receive-out"),
    sure: () => import("../../components/sure1"),
    selUser: () => import("../../components/sel-user"),
    selcetionClass: () => import("../../sign-up/components/selcetionClass"),
    checkBox: () => import("@/components/check-box"),
    annex: ()=> import('../drop-out/components/annex.vue'),
    loanInfo: ()=> import('../drop-out/components/loanInfo.vue'),
    discount: () => import('../../after-sale/drop-out/components/discount'),
    dictSelect: () => import( "components/dictSelect"),
    schooleTree: () => import("@/components/schooletree")
  },
  methods: {
    tijiaoProcancel() {
      this.showTis = false
    },
    async tijiaoProadd() {
      if(this.lingyuanForm.lingyuan == '') {
        this.$message.error('请输入内容')
      } else {
        let params = {
          orderData: this.processParmas,
          failMsgList: this.tiforList,
          reason: this.lingyuanForm.lingyuan,
          tyUserId:this.loginUser.tyUserId
        };
        console.log('params提交审批', params);
        const {code, data, msg} = await this.$fetch("third_processAdd",params)
        if (code == 200) {
          this.$message.success(msg)
          this.$router.push("/afterSale/order")
        } else {
          this.$message.error(msg)
        }
      }
    },
    // 获取列表数据
    async getNCOrderList() {
      let form = {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        sortItems: [
          {
            asc: false,
            column: "signUpTime",
          },
        ],
        orderNo: this.$route.query.orderNo
      }
      const { code, result, msg } = await getNCOrderList(form)
      if( code == 0 ){
        //1、更新分页数据
        
        //2、更新列表页面
        this.tableData  = result.list || [];
      }else{
        this.$message.error(msg)
      }
    },
    /***
   * 获取优惠券列表
   */
  async startActivityDetail(specArr){
    /***
       * 获取班型的信息，
       * (0) 默认班型
       * (1) 单层单选   [{ specificationNameId1:'',  specificationName1: '' }]
       * (2) 单层多选   [{ specificationNameId1:'',  specificationName1: '' },
       *                { specificationNameId1:'',  specificationName1: '' }, ...]
       * (3) 双层单选   [{ specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' }]
       * (4) 双层多选   [{ specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' },
       *                { specificationNameId1:'',  specificationName1: '', specificationNameId2:'',  specificationName2: '' }]
       */
      let specificationNameId1 = '';
      let specificationNameIds2 = []
      if( specArr.length > 1 ){
         //多选，可能是(2)(4)情形 
         specArr.forEach( item=>{
           let specificationNameId1_temp = item.specificationNameId1;
           let specificationNameId2 = item.specificationNameId2;
           //表示是单层多选情形
           if(!specificationNameId2){
             specificationNameId1 = this.specTypeArr[0].id;
             specificationNameIds2.push(specificationNameId1_temp)
           }else {
             //表示双层多选，此时的specificationNameId1都是同一个
             specificationNameId1 = item.specificationNameId1;
             specificationNameIds2.push(specificationNameId2)
           }
         } )
      } else if( specArr.length == 1 ){
        //单项，则只可能是 (1)(3) 情形
        specificationNameId1 = specArr[0].specificationNameId1;
        let specificationNameId2 = specArr[0].specificationNameId2;
        if( specificationNameId2 ){
          specificationNameIds2.push(specificationNameId2);
        } else {
          specificationNameIds2.push(specificationNameId1);
          specificationNameId1= this.specTypeArr[0].id;
        }
      }

      //goodsId, schoolId, specificationNameId1, specificationNameIds2
      let goodsId = this.currentGoods.goodsId
      let schoolId = this.transactionInfo.ncCampusSchoolId
      this.findActivityDetail( goodsId, schoolId, specificationNameId1, specificationNameIds2);
      this.findActivityDetail_notInUserGroup( goodsId, schoolId, specificationNameId1, specificationNameIds2);

  },

  async findActivityDetail( goodsId, schoolId, specificationNameId1, specificationNameIds2 ){

    let formData = {
      goodsId,
      schoolId,
      specificationNameId1,
      specificationNameIds2,
      specificationPriceIds: this.isCj ? this.specIdArr : [...this.commodityIdList],
      userId: this.signUpForm.userId,
    };
    this.activedParams = formData
    try {
        let res = await this.$fetch("findActivityDetail",formData);
    if( res.code == 200 ){
      let data = res.data || [];
      let dataList = data.map( (item)=>{
        return {
          activityName: item.activityName,
          isMultiple: item.isMultiple,
          preferentialAmount: item.preferentialAmount,
          endTime: item.endTime,
          id: item.activityId,
          activityId: item.activityId,
          marketInoutConfigSnapshotId: item.inoutConfigSnapshot ? item.inoutConfigSnapshot.id : "",
          inoutConfigSnapshot: item.inoutConfigSnapshot
        }
      })
       this.discountInfoList = dataList;
      }
    } catch ( e ){
      console.log(e);
    }
  },
  /***
     * 获取不可用优惠券列表
     */
    async findActivityDetail_notInUserGroup(
      goodsId,
      schoolId,
      specificationNameId1,
      specificationNameIds2
    ) {
      let formData = {
        goodsId,
        schoolId,
        specificationNameId1,
        specificationNameIds2,
        specificationPriceIds: this.isCj ? this.specIdArr : [...this.commodityIdList],
        userId: this.signUpForm.userId,
      };
      try {
        let res = await this.$fetch("findActivityDetail_notInUserGroup", formData);
        if (res.code == 200) {
          this.discountNotuse = res.data || [];
        }
      } catch (e) {
        console.log(e);
      }
    },

    /***
     * 优惠金额判断
     */
    inputDiscountPrice(newValue) {
      //先判断是否选中商品优惠，如果没有则报错且退回
      let findItem = this.discountInfoList.find((item) => item.isSelected);
      if (!findItem) {
        this.$message.error("请先选择商品优惠");
        return;
      }

      let value = Number(newValue);
      if( isNaN(value) || newValue.startsWith("00") ){
        this.$message.error("请输入大于0的优惠金额");
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }


      newValue = value;
      if (newValue < 0) {
        this.$message.error("请输入大于0的优惠金额");
        setTimeout(() => {
          this.discountPrice = "";
          this.getReceiveItem();
        });
        return;
      }

       if( newValue > Number(this.newGoodsPrice) ){
          this.$message.error(`请输入不大商品价格 ${ this.newGoodsPrice }`);
          setTimeout(() => {
           this.discountPrice = "";
           this.getReceiveItem();
         });
         return;
       }

      let discount = Number(findItem.preferentialAmount);
      this.discountNewPrice = discount
      // if (newValue > discount  ) {
      //    this.$message.error(`请输入不大于最大优惠金额${ discount }`);
      //   setTimeout(() => {
      //     this.discountPrice = "";
      //     this.getReceiveItem();
      //   });
      //   return;
      // }
      this.getReceiveItem();
    },
        /***
     * 选择优惠券信息
     */
    selectDiscountInfo( id ){
      this.discountPrice = ""
      if(!id){
         this.wxTradeOrderDiscountVOList = [];
        //  this.discountPrice = "";
         this.discountType="";
          this.getReceiveItem();
         return;
       }
       this.discountInfoList.map(item => {
        if(item.id == id) {
          this.marketInoutConfigSnapshotId = item.marketInoutConfigSnapshotId
          this.inoutConfigSnapshot = item.inoutConfigSnapshot
        }
      })
      this.discountInfoList.forEach(item=>item.isSelected=false)
      let findItem = this.discountInfoList.find( item=>item.id==id  );
      findItem.isSelected = true;
      this.wxTradeOrderDiscountVOList = [{
        activityId: findItem.activityId,
        discount: findItem.discount,
        // 优惠新增4个字段
        ...this.activedParams,
        marketInoutConfigSnapshotId: this.marketInoutConfigSnapshotId 
      }];
      this.discountTip = findItem.endTime ? `可优惠0~${findItem.preferentialAmount}元 ${ this.$dayjs(findItem.endTime).format("YYYY-MM-DD HH:mm:ss")} 过期` : `可优惠0~${findItem.preferentialAmount}元 本优惠长期有效`;
      this.getReceiveItem()
    },

    removeDiscountInfo(){
      setTimeout(() => {
        this.discountType = "";
        this.discountInfoList = [];
        this.discountNotuse = [];
        this.wxTradeOrderDiscountVOList=[];
        this.discountTip = "";
        this.discountPrice = "";
        this.signUpForm.jobLowestSalaryUnderCollege = '';
        this.signUpForm.jobLowestSalaryUpCollege = '';
       }, 0);
     },

    /********************自考相关数据请求*********************************************************************/
    selectZkSingleProvince(index) {
      let currentProvinceItem = this.zkSingle.enrollingProvinceList[index];
      this.zkSingle.enrollingSchoolList =
        currentProvinceItem.enrollingSchoolList || [];
      this.zkSingle.form.schoolId = "";
      this.getZkSingleGoodsSpecTable(currentProvinceItem.enrollingProvinceId);

      //通过选择的省的id，对应 price数组中的id
      this.zkNewGoodsPrice = this.totalPrice;
      //报考专业 majorId, majorName
      this.graduactionForm.enrollingMajorIdList = [
        this.currentGoods.enrollingMajorId,
      ];
      this.graduactionForm.enrollingMajorNameList = [
        this.currentGoods.enrollingMajorName,
      ];

      //报考省份 provinceId, provinceName
      this.graduactionForm.enrollingProvinceIdList = [
        currentProvinceItem.enrollingProvinceId,
      ];
      this.graduactionForm.enrollingProvinceNameList = [
        currentProvinceItem.enrollingProvinceName,
      ];

      let enrollingMajorList = this.currentGoods.enrollingMajorList || [];
      this.graduactionForm.gradationIdList = enrollingMajorList.map(
        (item) => item.gradationId
      );
    },

    selectZkSingleSchool(index) {
      let schoolItem = this.zkSingle.enrollingSchoolList[index];
      //报考院校 schoolId,   schoolName
      this.graduactionForm.enrollingSchoolIdList = [schoolItem.id];
      this.graduactionForm.enrollingSchoolNameList = [
        schoolItem.enrollingSchoolName,
      ];
    },
    //班型table
    getZkSingleGoodsSpecTable(enrollingProvinceId) {
      let curClassInfo =
        this.zkSingle.specificationPriceDataSource.find(
          (item) => item.enrollingProvinceId == enrollingProvinceId
        ) || {};
      //自考单品的价格
      this.totalPrice = curClassInfo.goodsPrice;
      //学历的规格ids
      this.graduactionForm.setCommodityId = curClassInfo.id;
      this.zkSingle.zkGoodsSpecTable =
        this.getZkSingleClassInfoTableData(curClassInfo);
      this.goodsSpecTable = this.zkSingle.zkGoodsSpecTable;
      this.specIdArr = [curClassInfo.id];
      this.commodityIdList = [curClassInfo.id];
      this.zkNewGoodsPrice = curClassInfo.goodsPrice;
      this.getZkSingleCourseInfoTableData(curClassInfo.goodsId);
      this.getReceiveItem();
    },
    // 获取班型信息
    getZkSingleClassInfoTableData(curClassInfo = {}) {
      if (curClassInfo.goodsDiscountPrice === undefined) {
        curClassInfo.goodsDiscountPrice = 0;
      }
      if (
        curClassInfo.kuaidaTotal === undefined ||
        curClassInfo.kuaidaTotal === null
      ) {
        curClassInfo.kuaidaTotal = "--";
      } else if (curClassInfo.kuaidaTotal == 0) {
        curClassInfo.kuaidaTotal = "无上限";
      }

      if (curClassInfo.kuaidaFrequency === undefined) {
        curClassInfo.kuaidaFrequency = 0;
      }
      return [curClassInfo];
    },
    //获取课程信息
    //goodsIdStr 商品id字符串（用’,’隔开）
    async getZkSingleCourseInfoTableData(goodsIdStr) {
      let form = {
        goodsIdStr,
      };
      const { data = [] } = await this.$fetch(
        "third_findCourseByGoodsIdStr",
        form
      );
      let coursrDatasource = data[0] || {};
      this.zkSingle.courseList = coursrDatasource.goodsCourseList || [];
    },

    /***
     * 获取自考，成考，网课班型和报考信息
     * 需要参数：
     *      bussinessId： 赛道id
     *      goodsId：     商品id
     */
    async getZkGoodsSpecs() {
      let form = {
        goodsId: this.currentGoods.goodsId,
        businessId: 0,
      };
      const { data } = await this.$fetch(
        "third_getfindSpByIdGoodsId",
        form
      );

      console.log("third_getfindSpByIdGoodsId: ");
      console.log(data);

      //1.获取报考省份，已经该省份可选择的院校
      this.zkSingle.enrollingProvinceList = data.enrollingProvinceList || [];
      //3.获取班型信息
      this.zkSingle.specificationPriceDataSource =
        data.specificationPriceArr || [];
    },
    /********************自考 套餐 相关数据请求******************************************************************** */
    //选择省份，然后根据省份的ID，以及商品的id，获取班型信息，此时含有单科的价格，以及对应的课程的id
    async selectZkMultipleProvince(index) {
      let currentProvinceItem = this.zkMultiple.enrollingProvinceList[index];
      this.zkMultiple.enrollingSchoolList =
        currentProvinceItem.enrollingSchoolList || [];
      this.zkMultiple.form.schoolId = "";

      //收集：报考省份 provinceId, provinceName
      this.graduactionForm.enrollingProvinceIdList = [
        currentProvinceItem.enrollingProvinceId,
      ];
      this.graduactionForm.enrollingProvinceNameList = [
        currentProvinceItem.enrollingProvinceName,
      ];

      let enrollingMajorList = this.currentGoods.enrollingMajorList || [];
      this.graduactionForm.gradationIdList = enrollingMajorList.map(
        (item) => item.gradationId
      );

      //通过包括专业中的商品id + 报考省份 获取商品价格信息， 然后减去套餐的优惠价格。
      let form = {
        goodsSpPriceList: [
          ...this.zkMultiple.goodsIdList.map((item) => {
            return {
              goodsId: item.goodsId,
              enrollingProvinceId: currentProvinceItem.enrollingProvinceId,
            };
          }),
        ],
      };
      await this.findSpInfoByProvinceId(form);
    },
     //自考专科的select下拉事件
    //套餐多层次专科的选择省
    async selectZkZkMultipleProvince(index) {
      let currentProvinceItem = this.zkMultiple.zkEnrollingProvinceList[index];
      this.zkMultiple.selected.zkProvinceId =
        currentProvinceItem.enrollingProvinceId;
      this.zkMultiple.selected.zkProvinceName =
        currentProvinceItem.enrollingProvinceName;
      this.zkMultiple.zkEnrollingSchoolList =
        currentProvinceItem.enrollingSchoolList || [];
      this.zkMultiple.selected.zkSchoolId = "";

      //收集：报考省份 provinceId, provinceName
      this.graduactionForm.enrollingProvinceIdList = [
        this.zkMultiple.selected.zkProvinceId,
        this.zkMultiple.selected.bkProvinceId,
      ];
      this.graduactionForm.enrollingProvinceNameList = [
        this.zkMultiple.selected.zkProvinceName,
        this.zkMultiple.selected.bkProvinceName,
      ];
      this.graduactionForm.gradationIdList = [1, 2];

      //通过包括专业中的商品id + 报考省份 获取商品价格信息， 然后减去套餐的优惠价格。
      let goodsSpPriceList = [];
      for (let index in this.zkMultiple.goodsIdList) {
        let item = this.zkMultiple.goodsIdList[index];
        let provinceId = this.graduactionForm.enrollingProvinceIdList[index];
        //如果存在省份id为0，则表示双层次没有都选上。
        if (provinceId == "") {
          return;
        }
        goodsSpPriceList.push({
          goodsId: item.goodsId,
          enrollingProvinceId: provinceId,
        });
      }

      await this.findSpInfoByProvinceId({
        goodsSpPriceList,
      });
    },
    //自考本科的select下拉事件
    //套餐多层次本科的选择省
    async selectZkBkMultipleProvince(index) {
     let currentProvinceItem = this.zkMultiple.bkEnrollingProvinceList[index];
      // this.zkMultiple.selected.bkProvinceItemId = currentProvinceItem.id;
      this.zkMultiple.selected.bkProvinceId =
        currentProvinceItem.enrollingProvinceId;
      this.zkMultiple.selected.bkProvinceName =
        currentProvinceItem.enrollingProvinceName;
      this.zkMultiple.bkEnrollingSchoolList =
        currentProvinceItem.enrollingSchoolList || [];
      this.zkMultiple.selected.bkSchoolId = "";

      //收集：报考省份 provinceId, provinceName
      this.graduactionForm.enrollingProvinceIdList = [
        this.zkMultiple.selected.zkProvinceId,
        this.zkMultiple.selected.bkProvinceId,
      ];
      this.graduactionForm.enrollingProvinceNameList = [
        this.zkMultiple.selected.zkProvinceName,
        this.zkMultiple.selected.bkProvinceName,
      ];
      this.graduactionForm.gradationIdList = [1, 2];

      //通过包括专业中的商品id + 报考省份 获取商品价格信息， 然后减去套餐的优惠价格。
      let goodsSpPriceList = [];
      for (let index in this.zkMultiple.goodsIdList) {
        let item = this.zkMultiple.goodsIdList[index];
        let provinceId = this.graduactionForm.enrollingProvinceIdList[index];
        //如果存在省份id为0，则表示双层次没有都选上。
        if (provinceId == "") {
          return;
        }
        goodsSpPriceList.push({
          goodsId: item.goodsId,
          enrollingProvinceId: provinceId,
        });
      }

      await this.findSpInfoByProvinceId({
        goodsSpPriceList,
      });
    },
    selectZkZkMultipleSchool(index) {
      let schoolItem = this.zkMultiple.zkEnrollingSchoolList[index];
      this.zkMultiple.selected.zkSchoolId = schoolItem.id;
      this.zkMultiple.selected.zkSchoolName = schoolItem.enrollingSchoolName;

      //报考院校 schoolId,   schoolName
      this.graduactionForm.enrollingSchoolIdList = [
        this.zkMultiple.selected.zkSchoolId,
        this.zkMultiple.selected.bkSchoolId,
      ];
      this.graduactionForm.enrollingSchoolNameList = [
        this.zkMultiple.selected.zkSchoolName,
        this.zkMultiple.selected.bkSchoolName,
      ];
    },
    selectZkBkMultipleSchool(index) {
     let schoolItem = this.zkMultiple.bkEnrollingSchoolList[index];
      this.zkMultiple.selected.bkSchoolId = schoolItem.id;
      this.zkMultiple.selected.bkSchoolName = schoolItem.enrollingSchoolName;
      //报考院校 schoolId,   schoolName
      this.graduactionForm.enrollingSchoolIdList = [
        this.zkMultiple.selected.zkSchoolId,
        this.zkMultiple.selected.bkSchoolId,
      ];
      this.graduactionForm.enrollingSchoolNameList = [
        this.zkMultiple.selected.zkSchoolName,
        this.zkMultiple.selected.bkSchoolName,
      ];
    },
    selectZkMultipleSchool(index) {
     let schoolItem = this.zkMultiple.enrollingSchoolList[index];
      //报考院校 schoolId,   schoolName
      this.graduactionForm.enrollingSchoolIdList = [schoolItem.id];
      this.graduactionForm.enrollingSchoolNameList = [
        schoolItem.enrollingSchoolName,
      ];
    },
    async findSpInfoByProvinceId(form) {
      let { data = [] } = await this.$fetch(
        "findSpInfoByProvinceId",
        form
      );
      this.commodityIdList = [];
      this.graduactionForm.enrollingProvinceItemIdList = [];
      //返回来是一个数组，有多个goodsId就有多少个元素。
      let totalPrice = 0;
      //对每一门课程的价格进行累加。
      data = data.filter((item) => item != null);
      data.forEach((item) => {
        this.commodityIdList.push(item.id);
        totalPrice += item.goodsPrice;
        this.graduactionForm.enrollingProvinceItemIdList.push(item.id);
      });
      totalPrice = Math.round(totalPrice * 100) / 100;
      //商品总价
      this.zkNewGoodsPrice = totalPrice;
      //商品优惠
      this.zkGoodsDiscount = this.zkMultiple.curClassInfo.discountAmount;
      if (this.zkNewGoodsPrice <= this.zkGoodsDiscount) {
        this.zkGoodsDiscount = 0;
      }
      //商品支付价
      this.totalPrice =
        Math.round((totalPrice - this.zkGoodsDiscount) * 100) / 100;
      //学历的规格ids
      this.graduactionForm.setCommodityId = this.zkMultiple.curClassInfo.id;
      this.zkMultiple.curClassInfo.goodsPrice = totalPrice;
      this.zkMultiple.curClassInfo.discountAmount = this.zkGoodsDiscount;

      this.zkMultiple.zkGoodsSpecTable = this.getZkMultipleClassInfoTableData(
        this.zkMultiple.curClassInfo
      );
      //修改报考院校下面的表格中显示的商品价格
      this.goodsSpecTable = this.zkMultiple.zkGoodsSpecTable;

      if (Array.isArray(this.receiveItemData)) {
        //根据商品的总价，进行套餐内每一个价格的计算
        this.receiveItemData = this.receiveItemData.map((item) => {
          return {
            ...item,
            priceDiscount:
              Math.round(this.zkGoodsDiscount * item.inoutProportion) / 100.0,
            standardPrice:
              Math.round(totalPrice * item.inoutProportion) / 100.0,
          };
        });
      }

      //获取课程信息
      let goodsSpPriceForm = {
        goodsSpPriceList: [
          ...data.map((item) => {
            return {
              goodsId: item.goodsId,
              spPriceId: item.id,
            };
          }),
        ],
      };
      await this.findCourseBySpPriceIdStr(goodsSpPriceForm);
    },
    async findCourseBySpPriceIdStr(form) {
      const { data = [] } = await this.$fetch(
        "findCourseBySpPriceIdStr",
        form
      );
      //根goodsId进行匹配
      let dataList = [];

      //获取到商品中的课程信息，然后与之前选择的 “专业+课程名”进行匹配，组合成课程清单中要显示的数据
      for (let item of data) {
        for (let goodsItem of this.zkMultiple.goodsIdList) {
          if (item.goodsId == goodsItem.goodsId) {
            let goodsCourseList = item.goodsCourseList || [];
            dataList.push(
              ...goodsCourseList.map((item) => {
                return {
                  ...item,
                  ...goodsItem,
                };
              })
            );
          }
        }
      }
      this.zkMultiple.courseList = dataList;
    },

    // 获取班型信息
    getZkMultipleClassInfoTableData(curClassInfo = {}) {
      if (curClassInfo.goodsDiscountPrice === undefined) {
        curClassInfo.goodsDiscountPrice = 0;
      }
      if (curClassInfo.kuaidaTotal === undefined) {
        curClassInfo.kuaidaTotal = 0;
      }
      if (curClassInfo.kuaidaFrequency === undefined) {
        curClassInfo.kuaidaFrequency = 0;
      }
      return [curClassInfo];
    },
    //选择商品的回调事件
    async getZkMultipleGoodsSpecs() {
       // 重置商品规格弹窗数据
      this.goodsSpecModalKey = getRandomKey();
      this.signUpForm.spec = "";
      const form = {
        businessId: "0",
        goodsId: this.currentGoods.goodsId,
      };

      const { data } = await this.$fetch(
        "third_getfindSpByIdGoodsId",
        form
      );
      this.specificationPriceArr = data.specificationPriceArr || [];

      console.log("third_getfindSpByIdGoodsId:");
      console.log(data);

      this.zkMultiple.specificationPriceDataSource =
        data.specificationPriceArr || [];
      this.specificationPriceArr.forEach(
        (item) => (item.goodsPrice = item.spPriceScope)
      );

      /***
       * 自考套餐, 多个班型情形
       */
      if (data.specificationTypeArr.length > 0) {
        //获取可以选择的组合商品类型
        this.specTypeArr = data.specificationTypeArr || [];
        this.specPriceArr = data.specificationTypeArr || [];
        //specificationNameArr 存在，则表示有两层需要选择
        if (
          this.specTypeArr.length > 1 &&
          Array.isArray(this.specTypeArr[1].specificationNameArr)
        ) {
          let firstSpecificationNameArr =
            this.specTypeArr[0].specificationNameArr || [];
          let lastSpecificationNameArr =
            this.specTypeArr[1].specificationNameArr || [];

          this.specArray = firstSpecificationNameArr.map((firstItem) => {
            return {
              id: firstItem.id,
              specificationName: firstItem.specificationName,
              secondSpNameList: [
                ...lastSpecificationNameArr.map((lastItem) => {
                  return {
                    spName: lastItem.specificationName,
                    spNameId: lastItem.id,
                  };
                }),
              ],
            };
          });
        }
      } else if (data.specificationTypeArr.length == 0) {
        /***
         *  自考套餐，默认班型的情形
         *  */
        this.specTypeArr = [];
        this.signUpForm.spec = "默认班型";
        let spPriceIdArr = data.specificationPriceArr.map((item) => item.id);
        this.getZkGroupInfoByGoodsId(spPriceIdArr);
        this.getZkMultipleGoodsSpecTable(0);
      } else {
        //感觉specificationTypeArr肯定> = 0，所以不会走到这个else
        this.specTypeArr = [];
        this.signUpForm.spec = "默认规格";
        this.goodsSpecTable = this.getSpcMergeData(
          data.specificationPriceArr.slice()
        );
        this.specIdArr = [data.specificationPriceArr[0].id];
        this.totalPrice = this.sumTotalPrice();
      }

      //处理班型弹出框选择时显示的价格。
      this.specPriceArr = data.specificationPriceArr || [];
      this.specPriceArr.forEach((item) => {
        item.goodsDiscountPrice = item.spPriceScope;
      });
    },
    //班型table
    getZkMultipleGoodsSpecTable(index) {
      let curClassInfo = this.zkMultiple.specificationPriceDataSource[index];
      this.zkMultiple.curClassInfo = curClassInfo; //记录该选中项，用于计算总价格时使用
    },

    //获取自考套餐分组信息
    async getZkGroupInfoByGoodsId(specIdArr) {
      this.specIdArr = specIdArr;
      this.getReceiveItem(specIdArr);
    },

    /***
     *  选中了班组的情形
     */
    updateZkMultipleInfo(specInfo = {}) {
      this.zkMultiple.curClassInfo = specInfo.specArr[0];
      //选择班型item对应显示的名字。
      this.signUpForm.spec = specInfo.specificationName;
      this.specIdArr = specInfo.specIdArr || [];
      this.getZkGroupInfoByGoodsId(this.specIdArr);
    },
    /***
     *  选择专业确定响应事件
     */
    async professionalAlertEnsure() {
     //需要清除专业之后的数据
      this.resetAfterPrefessionalData();
      //关闭弹窗
      this.professionalAlertCancel();
      //根据选中的专业信息，去获取分组中商品的报考信息
      let goodsIdStr = "";

      let goodsIdList = [];
      for (let mealGroupItem of this.zkMultiple.mealGroupData) {
        for (let goodsGroupItem of mealGroupItem.goodsGroupList) {
          for (let goodsItem of goodsGroupItem.goods) {
            if (goodsItem.isSelected) {
              goodsIdStr += goodsItem.goodsId + ",";
              goodsIdList.push({
                goodsId: goodsItem.goodsId,
                classType: goodsGroupItem.name,
                className: goodsItem.goodsName,
              });
            }
          }
        }
      }

      goodsIdStr = goodsIdStr.replace(/,$/, "");
      this.zkMultiple.goodsIdList = goodsIdList;
      let { data: infoList = [] } = await this.$fetch(
        "findEnrollingInfo",
        { goodsIdStr }
      );

      let majorName = "";
      //如果是双层次，则会获取到两条数据
      //如果是单层次，那么就只会获取到一条数据
      this.graduactionForm.enrollingMajorIdList = [];
      this.graduactionForm.enrollingMajorNameList = [];

      if (infoList.length > 1) {
        //需要识别出专科以及本科
        goodsIdList = [
          goodsIdList.find((item) => item.classType == "专科"),
          goodsIdList.find((item) => item.classType == "本科"),
        ];
        this.zkMultiple.goodsIdList = goodsIdList;

        for (let goodsItem of goodsIdList) {
          let infoItem = infoList.find(
            (item) => item.goodsId === goodsItem.goodsId
          );
          this.graduactionForm.enrollingMajorIdList.push(
            infoItem.enrollingMajorId
          );
          this.graduactionForm.enrollingMajorNameList.push(
            infoItem.enrollingMajorName
          );
          majorName +=
            goodsItem.classType + " - " + infoItem.enrollingMajorName + " / ";
          if (goodsItem.classType === "专科") {
            this.zkMultiple.zkEnrollingProvinceList =
              infoItem.enrollingProvinceList || [];
          } else if (goodsItem.classType === "本科") {
            this.zkMultiple.bkEnrollingProvinceList =
              infoItem.enrollingProvinceList || [];
          }
        }
      } else {
        let info = infoList[0];
        majorName = info.enrollingMajorName;
        //收集： 报考专业 majorId, majorName
        this.graduactionForm.enrollingMajorIdList = [info.enrollingMajorId];
        this.graduactionForm.enrollingMajorNameList = [info.enrollingMajorName];
        //1.获取报考省份，已经该省份可选择的院校
        this.zkMultiple.enrollingProvinceList =
          info.enrollingProvinceList || [];
      }
      this.zkMultiple.curEnrollingMajorName = majorName.replace(/\s\/\s$/, "");
    },


    selectGoods(mealGroupIndex, goodsGroupIndex, goodsIndex) {
      //获取到数组
      let goodsGroupItem =
        this.zkMultiple.mealGroupData[mealGroupIndex].goodsGroupList[
          goodsGroupIndex
        ];
      goodsGroupItem.goods.forEach((item) => {
        item.isSelected = false;
      });
      goodsGroupItem.goods[goodsIndex].isSelected = true;
    },
    // 获取院校选择的schoolNcId
    async selectSchoolNcid(value,valueid,name) {
      console.log('value获取院校选择的schoolNcId', value);
      // const { code, result } = await getDealSummary(this.customerId)
      let gradationId
      let goodsId
      let id
      this.ckSingle.specificationPriceDataSource.map((item) => {
        goodsId = item.goodsId
        id = item.id
        if(item.enrollingMajorList && item.enrollingMajorList.length) {
          gradationId = item.enrollingMajorList[0].gradationId
        }
      })
      this.currentGoods.enrollingSchoolName = name
      this.currentGoods.enrollingSchoolId = valueid
      console.log('this.currentGoods', this.currentGoods);
      await this.$fetch("third_getfindEnrollingMajorList", {schoolNcId: value, gradationId: gradationId, id: id, goodsId: goodsId}).then(res => {
        if(res.code === 200) {
          res.data.map((item) => {
            if (item.name) {
              item.majorName = item.name
            }
          })
          // this.ckSingle.enrollingMajorList = res.data
          // this.ckSingle.specificationPriceDataSource.map(item => {
          //   if(item.enrollingMajorList && item.enrollingMajorList.length) {
          //     item.enrollingMajorList.map(i => {
          //       res.data.map(j => {
          //         if(j.majorId === i.majorId) {
          //           j.gradationId = i.gradationId
          //         }
          //       })
          //     })
          //   }
          // })
          this.ckSingle.enrollingMajorList = res.data;
          // console.log('this.ckSingle.enrollingMajorList', this.ckSingle.enrollingMajorList);
          this.majorList = res.data
        }
      })
    },
    // 获取院校
    async getfindEnrollingSchoolList () {
      const { data } = await this.$fetch("third_getfindEnrollingSchoolList");
      console.log('data院校', data);
      data.map((item) => {
        if (item.children) {
          item.disabled = true
          item.children.map((i) => {
            if(!i.schoolNcId) {
              i.disabled = true
            }
          })
        }
      })
      this.findSchoolList = data
    },
    // 第二志愿
    async selectSchoolNcidtwo(value,valueid,name) {
      console.log('value获取院校选择的schoolNcId2', value);
      // const { code, result } = await getDealSummary(this.customerId)
      let gradationId
      let goodsId
      let id
      this.ckSingle.specificationPriceDataSource.map((item) => {
        goodsId = item.goodsId
        id = item.id
        if(item.enrollingMajorList && item.enrollingMajorList.length) {
          gradationId = item.enrollingMajorList[0].gradationId
        }
      })
      this.currentGoods2.enrollingSchoolName = name
      this.currentGoods2.enrollingSchoolId = valueid
      console.log('this.currentGoods', this.currentGoods);
      await this.$fetch("third_getfindEnrollingMajorList", {schoolNcId: value, gradationId: gradationId, id: id, goodsId: goodsId}).then(res => {
        if(res.code === 200) {
          res.data.map((item) => {
            if (item.name) {
              item.majorName = item.name
            }
          })
          this.ckSingle.enrollingMajorList2 = res.data;
        }
      })
    },
    // 获取院校2
    async getfindEnrollingSchoolListwo () {
      const { data } = await this.$fetch("third_getfindEnrollingSchoolList");
      console.log('data院校', data);
      data.map((item) => {
        if (item.children) {
          item.disabled = true
          item.children.map((i) => {
            if(!i.schoolNcId) {
              i.disabled = true
            }
          })
        }
      })
      this.findtwoSchoolList = data
    },
    /********************成考相关数据请求******************************************************************** */
    async getCkGoodsSpecs() {
      let form = {
        goodsId: this[this._choiceGoods2 ? 'currentGoods2' : 'currentGoods'].goodsId,
        businessId: businessIdEnum.xl,
      };
      // enrollingGradationList:学级列表 enrollingMajorList:专业列表
      const { data } = await this.$fetch("third_getfindSpByIdGoodsId", form);
      if (data.canSelectMajor && data.canSelectMajor == true) {
        this.canSelectMajor = true
        this.getfindEnrollingSchoolList()
        this.getfindEnrollingSchoolListwo()
        this.showYxOption = true
      } else {
        this.canSelectMajor = false
        // 如果没有就走之前的逻辑
        this.showYxOption = false
      }
      let _specificationPriceArr = data.specificationPriceArr || []
      let _enrollingMajorList = data.enrollingMajorList || []
      // 筛选specificationPriceArr.enrollingMajorList中层次id注入进enrollingMajorList
      _specificationPriceArr.map(item =>{
        if(item.enrollingMajorList && item.enrollingMajorList.length){
          item.enrollingMajorList.map(i =>{
            _enrollingMajorList.map(j =>{
              if(j.majorId === i.majorId){
                j.gradationId = i.gradationId
              }
            })
          })
        }
      })
      const enrollingGradationList = data.enrollingGradationList || []
      const enrollingMajorList = _enrollingMajorList
      const specificationPriceArr = _specificationPriceArr
      const goodsId = data.goodsId
      const xlType = data.xlType
      if(!this._choiceGoods2){
        // 第一志愿
        this.ckSingle.enrollingGradationDatasource = enrollingGradationList;
        this.ckSingle.enrollingMajorList = enrollingMajorList;
        this.ckSingle.specificationPriceDataSource = specificationPriceArr;
        this.ckSingle.goodsId = goodsId;
        this.ckSingle.xlType = xlType;
      }else{
        // 第二志愿
        const checkResult = this.checkCKGoods2(enrollingMajorList, enrollingGradationList)
        if(!(checkResult.sameEnrollingMajor && checkResult.sameEnrollingGradation)){
          // 该商品不符合《与报名的商品同一个层次、同一个学级》要求
          this.removeChoiceGoods(this.SecondGoods)
          console.log('[检查成考第二志愿]', checkResult)
          return this.$message.warning('第二志愿商品不符合与报名的商品同一个层次、同一个学级要求')
        }
        this.ckSingle.enrollingGradationDatasource2 = enrollingGradationList;
        this.ckSingle.enrollingMajorList2 = enrollingMajorList;
        this.ckSingle.specificationPriceDataSource2 = specificationPriceArr;
        this.ckSingle.goodsId2 = goodsId;
        this.ckSingle.xlType2 = xlType;
      }
       // 判断canSelectMajor 是不是有院校
      if (data.canSelectMajor && data.canSelectMajor == true) {
        this.getfindEnrollingSchoolList()
        
        if (!this._choiceGoods2) {
          this.showYxOption = true
        } else if (this._choiceGoods2) {
          this.showYxOption2 = true
        }
      } else {
        // 如果没有就走之前的逻辑
        if (!this._choiceGoods2 && this.ckSingle.goodsId) {
          this.showYxOption = true
        }else {
          this.showYxOption = false
          this.showYxOption2 = false
        }
        if (this._choiceGoods2 && this.ckSingle.goodsId2) {
          this.showYxOption2 = false
        } else {
          this.showYxOption = false
        }
        console.log('this.showYxOption2', this.showYxOption2);

        this.getCkSingleCourseInfoTableData(goodsId);
        if(!this._choiceGoods2){
          this.startActivityDetail([]);
        }
      }
    },
    // 检查成考第二志愿与报名的商品同一个层次、同一个学级
    checkCKGoods2(enrollingMajorList2, enrollingGradationDatasource2){
      let sameEnrollingMajor = []//层次
      let sameEnrollingGradation = false//学级
      const enrollingMajorList1 = this.ckSingle.enrollingMajorList
      const enrollingGradationDatasource1 = this.ckSingle.enrollingGradationDatasource
      // 先排查层级
      enrollingMajorList1.map(i => {
        const hit = enrollingMajorList2.filter(j => j.gradationId === i.gradationId)
        sameEnrollingMajor.push(hit.length > 0)
      })
      // 排查学级
      enrollingGradationDatasource1.map(i =>{
        enrollingGradationDatasource2.map(j =>{
          if(i.gradation === j.gradation){
            // 有学级相同
            sameEnrollingGradation = true
          }
        })
      })

      return {
        sameEnrollingMajor: sameEnrollingMajor.filter(item=> item).length >= enrollingMajorList1.length && sameEnrollingMajor.length > 0,
        sameEnrollingGradation
      }
    },
    //获取课程信息
    //goodsIdStr 商品id字符串（用’,’隔开）
    async getCkSingleCourseInfoTableData(goodsIdStr) {
     let form = {
        goodsIdStr,
      };
      const { data = [] } = await this.$fetch(
        "third_findCourseByGoodsIdStr",
        form
      );
      let coursrDatasource = data[0] || {};
      this.ckSingle[this._choiceGoods2 ? 'courseList2' : 'courseList'] = coursrDatasource.goodsCourseList || [];
    },
    /***
     * 成考选择报考专业 - 下拉框响应事件
     */
    selectCkSingleProfessional( majorId, action ) {
      const isGoods1 = action !== this.SecondGoods//第一志愿标志
      if(isGoods1) {
        this.firstMajor = majorId
      }
      console.log('majorId', majorId);
      const dataKey = key => isGoods1 ? key : `${key}2`;
      let index = 0;
      let curEnrollingMajorItem = undefined;
      let enrollingMajorList = this.ckSingle[dataKey('enrollingMajorList')]
      console.log('enrollingMajorList下拉选择', enrollingMajorList);

      if (this.showYxOption == true) {
        for( let i in  enrollingMajorList){
          let item = enrollingMajorList[i];
          if( item.majorId === majorId ){
            index = i;
            curEnrollingMajorItem = item;
            break;
          }
        }
      } else {
        for( let i in  enrollingMajorList){
          let item = enrollingMajorList[i];
          if( item.id === majorId ){
            index = i;
            curEnrollingMajorItem = item;
            break;
          }
        }
      }
      console.log('curEnrollingMajorItem=====', curEnrollingMajorItem);
      
      if(Checker.isUndefined(curEnrollingMajorItem)) {
        this.cleanProfessional(isGoods1)
        return
      }
      this.ckSingle[dataKey('enrollingGradationList')] = this.ckSingle[dataKey('enrollingGradationDatasource')] || [];
      this.ckSingle.form[dataKey('enrollingGradationId')] = "";

      this.getCkSingleGoodsSpecTable(curEnrollingMajorItem.majorId, isGoods1);
      this.getfindPlatformBySpPriceId(!isGoods1);
      //报考专业 majorId, majorName
      this.graduactionForm[dataKey('enrollingMajorIdList')] = [
        curEnrollingMajorItem.majorId,
      ];
      this.graduactionForm[dataKey('enrollingMajorNameList')] = [
        curEnrollingMajorItem.majorName,
      ];
      //报考院校 schoolId,   schoolName
      this.graduactionForm[dataKey('enrollingSchoolIdList')] = [
        this[dataKey('currentGoods')].enrollingSchoolId,
      ];
      this.graduactionForm[dataKey('enrollingSchoolNameList')] = [
        this[dataKey('currentGoods')].enrollingSchoolName,
      ];
      //报考省份 provinceId, provinceName
      this.graduactionForm[dataKey('enrollingProvinceIdList')] = [
        this[dataKey('currentGoods')].enrollingProvinceId,
      ];
      this.graduactionForm[dataKey('enrollingProvinceNameList')] = [""];

      //根据选择的报考专业来获取对应的 specificationPriceArr 中的 专业层次
      console.log('index', index);
      console.log('this.ckSingle.specificationPriceDataSource', this.ckSingle.specificationPriceDataSource);

      let specificationPriceItem = this.ckSingle.specificationPriceDataSource[0]
      // if (index == 1 || index == 2) {
      //   console.log('this.ckSingle.specificationPriceDataSource[0]', this.ckSingle.specificationPriceDataSource[0]);
      //   specificationPriceItem = this.ckSingle.specificationPriceDataSource[0]
      // } else {
      //   specificationPriceItem = this.ckSingle.specificationPriceDataSource[index];
      // }
      // specificationPriceItem = this.ckSingle.specificationPriceDataSource[0]
      console.log('specificationPriceItem', specificationPriceItem);

      this.graduactionForm[dataKey('gradationIdList')] = [
        specificationPriceItem.enrollingMajorList[0].gradationId,
      ];
      this.setEnrollingGradationId2()
    },
    // 成考第二志愿，选择报考专业 - 下拉框响应事件
    selectCkSingleProfessional2(majorId){
      if(this.firstMajor === majorId) {
        this.$message.error("第二志愿专业不能跟第一志愿专业相同")
        this.$nextTick(() => {
          this.ckSingle.form.enrollingMajorId2 = "";
        });
        return false
      }else {
        this.ckSingle.form.enrollingMajorId2 = majorId
      }
      console.log('this.ckSingle.form.enrollingMajorId2', this.ckSingle.form.enrollingMajorId2);
      this.selectCkSingleProfessional(majorId, this.SecondGoods)
    },
    
    // 清除成考专业
    cleanProfessional(isGoods1){
      const dataKey = key => isGoods1 ? `${key}` : `${key}2`;
      this.ckSingle.gradationYear = ''
      this[dataKey('receiveItemData')] = []
      this.ckSingle.form[dataKey('enrollingMajorId')] = ''
      this.ckSingle.form[dataKey('enrollingGradationId')] = ''
      this.ckSingle[dataKey('goodsPrice')] = 0
      // 清除第一志愿专业。需要处理第二志愿专业
      if(isGoods1){
         this.ckSingle.form.enrollingMajorId2 = ''
         this.ckSingle.form.enrollingGradationId2 = ''
         this.ckSingle.goodsPrice2 = 0
         this.receiveItemData2 = []
      }
      // this.zkNewGoodsPrice = (isNaN(+this.ckSingle.goodsPrice) ? 0 : +this.ckSingle.goodsPrice) + (isNaN(+this.ckSingle.goodsPrice2) ? 0 : +this.ckSingle.goodsPrice2);
      this.zkNewGoodsPrice = isNaN(+this.ckSingle.goodsPrice) ? 0 : +this.ckSingle.goodsPrice;
      // this.ckSingle.form[dataKey('effectiveMonth')] = ''
    },
    // 成考第二志愿，填充与报名商品同等学级
    setEnrollingGradationId2(){
      if(this.currentGoods.xlType === xlTypeEnum.ck){
        // 已选第二志愿专业
        if(this.ckSingle.form.enrollingMajorId2){
          // 报名商品已选中学级
          if(this.ckSingle.form.enrollingGradationId){
            const hit = this.ckSingle.enrollingGradationList.filter(item => item.id == this.ckSingle.form.enrollingGradationId)
            if(hit.length){
              // 筛选出与报名同等学级
              const same = this.ckSingle.enrollingGradationList2.filter(item => item.gradation == hit[0].gradation)
              if(same[0]){
                this.ckSingle.form.enrollingGradationId2 = same[0].id
              }else{
                this.ckSingle.form.enrollingGradationId2 = ''
              }
            }else{
              this.ckSingle.form.enrollingGradationId2 = ''
            }
          }else{
            this.ckSingle.form.enrollingGradationId2 = ''
          }
        }else{
          this.ckSingle.form.enrollingGradationId2 = ''
        }
      }
    },
    selectCkSingleGradation( gradationId ) {
      let enrollingGradationItem = this.ckSingle.enrollingGradationList.find( item=> item.id===gradationId ) || {gradation: '', gradationYear: ''}
      this.ckSingle.enrollingTime =
        enrollingGradationItem.gradation;
      this.ckSingle.gradationYear =
        enrollingGradationItem.gradationYear;
      this.setEnrollingGradationId2()
    },
    /***
     * 成考选择报考学级 - 下拉框响应事件
     */

    //班型table
    getCkSingleGoodsSpecTable(majorId, isGoods1) {
      const dataKey = key => isGoods1 ? key : `${key}2`;
      let curClassInfo
      if (this.showYxOption == true) {
        curClassInfo = this.ckSingle.specificationPriceDataSource[0]
      } else {
        curClassInfo =
          this.ckSingle[dataKey('specificationPriceDataSource')].find(
            (item) => item.majorId == majorId
          ) || {};
      }
        
      this.ckSingle[dataKey('goodsPrice')] = curClassInfo.goodsPrice;
      // this.zkNewGoodsPrice = curClassInfo.goodsPrice; //实际支付价格
      this.zkNewGoodsPrice = isNaN(+this.ckSingle.goodsPrice) ? 0 : +this.ckSingle.goodsPrice; //实际支付价格
      this.ckSingle[dataKey('effectiveMonth')] = curClassInfo.effectiveMonth;
      this[dataKey('goodsSpecTable')] = [curClassInfo];
      this[dataKey('specIdArr')] = [curClassInfo.id];
      this[dataKey('commodityIdList')] = [curClassInfo.id];
      this.getReceiveItem(null, !isGoods1);
      this.startActivityDetail([])
      this[dataKey('totalPrice')] = curClassInfo.goodsPrice;
    },
    /********************************************************************************************* */
    /********************************************************************************************* */

    // 贷款按钮
    async clickFinalMoney(value) {
      this.collectionInfo.isLoan = value
      // if(value == 0) {
      //   this.collectionInfo.isLoan = 1
      // } else {
      //   this.collectionInfo.isLoan= 0
      // }
      let params = {
        goodsId: this.currentGoods.goodsId,
        spPriceIdStr: this.specIdArr.toString(),
        platformId:
          this.loanPrice &&
          this.loanPrice.length &&
          this.loanPrice[0].loanPlatformId,
      };

      if (this.collectionInfo.isLoan == "1") {
        // this.loanInfo = {};
        // 获取规格的贷款信息
        /***
         * {
         *  "downPaymentRatio" : 10,
         *  "umoneyProjectId": "123",
         *  "priceLimit": 100,
         *  "methoidInterestPayment": 2
         * }
         */
        try {
          let { data = {} } = await this.$fetch(
            "third_getfindLoanInfoBySpPriceId",
            params
          );
          this.loanInfo.downPaymentRatio = data.downPaymentRatio;
          this.loanInfo.umoneyProjectId = data.umoneyProjectId;
          this.loanInfo.priceLimit = data.priceLimit;
          this.loanInfo.methoidInterestPayment = data.methoidInterestPayment;

          let waiPay = this.isCj
            ? this.newGoodsPrice -
              this.goodsDiscount -
              this.collectionInfo.balancePay
            : this.zkNewGoodsPrice - this.zkGoodsDiscount > 0
            ? this.zkNewGoodsPrice -
              this.zkGoodsDiscount -
              this.collectionInfo.balancePay
            : 0;
          this.calLoanPriceScope(waiPay);
        } catch (error) {
          this.$message.error("贷款信息获取失败，无法贷款，请联系管理员");
          this.collectionInfo.isLoan = "0";
        }
      }
    },
    /***
     * 判断该商品是否能使用贷款
     */
    // 获取规格可使用贷款平台的接口
    async getfindPlatformBySpPriceId() {
      let params = {
        goodsId: this[this._choiceGoods2 ? 'currentGoods2' : 'currentGoods'].goodsId,
        spPriceIdStr: this[this._choiceGoods2 ? 'specIdArr2' : 'specIdArr'].toString(),
      };
      let { data, code } = await this.$fetch(
        "third_getfindPlatformBySpPriceId",
        params
      );
      if (code == 200) {
        if (data.length !== 0) {
          // 判断是否有可贷款的平台，如果有，就显示贷款的按钮
          this.showloanCheck = true;
          this.loanPrice = data;
        } else {
          this.showloanCheck = false;
        }
      }
    },
    setSelcetionShow() {
      if (this.$refs.selcetionClass) {
        // this.$refs.selcetionClass.editLesson("edit") //使选中课程页变为可编辑状态
      }
      this.oneItem = [];
    },

    // 根据手机号获取学员的账户余额
    async getBalanceByMoblie(mobile) {
      if (!mobile) {
        return this.$message.error("请输入手机账号");
      }
      const params = { mobile };
      const { data, code, msg } = await this.$fetch(
        "thrid_getBalanceByMoblie",
        params
      );
      if (code === 407) {
        return this.$message.error(msg);
      }
      if (!data) return false;

      this.collectionInfo.stuAccountMoney = data.money;
      this.collectionInfo.userName = data.nickName;
    },
    onSureCancel() {
      this.sureVisible = false;
    },
    //比对价格是否变动
    comparePrice(val = []) {
      //(1) 比较财经商品的价格是否变动
      //(2) 比较成考、网教的价格是否变动
      let specificationPriceArr = this.specificationPriceArr
      // 成考从ckSingle.specificationPriceDataSource获取商品信息
      if(this.currentGoods.xlType === xlTypeEnum.ck){
        this.specIdArr.map(it =>{
          const hit = this.ckSingle.specificationPriceDataSource.find(item => item.id == it)
          if(hit){
            specificationPriceArr.push(hit)
          }
        })
      }
      for (let i of specificationPriceArr) {
        for (let j of val) {
          if (
            (j.id == i.id && j.goodsPrice != i.goodsPrice) ||
            (j.id == i.id && j.goodsDiscountPrice != i.goodsDiscountPrice)
          ) {
            return true;
          }
        }
      }
      return false;
    },

     /***
     * 获得商品详情判断是否价格变化
     */
    async getGoodsDetail(specIdArr) {
      const params = {
        spPriceIdStr: specIdArr.toString(),
      };
      if (params.spPriceIdStr === "") return;
      const { data } = await this.$fetch(
        "findPriceInfoBySpPriceId",
        params
      );
      if(!this.comparePrice(data)){
        this.sureVisible = false;
        return true;
      }

      this.$hqMessageBox({
        title: '提示',
        message: "此商品价格发生变化，请重新选择该商品购买！"
      }).then( ()=>{
          //点击商品重置按钮
          this.removeChoiceGoods(null);
          this.sureVisible = false
      });
      return false
    },
    async setCashierCheck() {
      // console.log("获取出纳")
      const params = {
        schoolNcId: this.orderTable[0].signSchoolId
      }
      
      const {result} = await getSchoolUserCashierVerifiedUser(params)
      result.map(item => {
        item.userId = item.userId +''
        item.tyUserId = item.tyUserId + ''
      })
      this.cashierCheckList = result.concat(mockCashierChecker)
    },
    setSelUserShow() {
      this.selUserVisible = true;
    },
    onSelUserCancel() {
      this.selUserVisible = false;
    },
    onSelUserOk(data) {
      data.mobile = data.userPhone;
      if (
        this.seledUser.mobile != data.mobile ||
        this.seledUser.idCard != data.idCard
      ) {
        this.setDeafultData();
        this.orderTable = [];
        this.$refs.orderModal.setDeafultData();
      }
      Object.assign(this.signUpForm, {
        ...data,
         stuMoblie: data.mobile,
         name: data.stuName,
      });

      this.seledUser = data; //多升一选中学员的信息
      this.selUserVisible = false;
    },
    // 新增学员到蓝鲸
    addCustomeruser() {
      //判断是不是手机号，并且返回。
      if (!this.isPhoneNumber(this.signUpForm.stuMoblie)) {
        this.signUpForm.stuMoblie = ''
        return this.$message.warning("学员账号格式不正确");
      }

      doSaveLjUser({
        idCard: this.signUpForm.idCard,
        mobile: this.signUpForm.stuMoblie,
        realName: this.signUpForm.name
      }).then(res => {
        let { code, result } = res
        if(code === 0) {
          this.isCanStudy = true
          Object.assign( this.signUpForm, {
            stuMoblie: result.mobile,
            userId: result.userId,
            name: result.nickName
          });
        }
      })
    },
    // 自动通过学员账号查询学员信息（废弃）
    async getCustomeruser(isNotNotifce) {
      console.log("根据手机号查询学生信息", this.signUpForm);
      let {stuMoblie, name, idCard } = this.signUpForm;

      //判断手机号是不是没有改变
      if( stuMoblie == this._tmpMoile ){
        return;
      }

      //判断是不是手机号，并且返回。
      if (!this.isPhoneNumber(stuMoblie)) {
        return !isNotNotifce && this.$message.warning("学员账号不正确");
      }

      //根据用户手机号获取用户信息
      let { data={}, code } = await this.$fetch("getUserByMobile", {  mobile: stuMoblie });
      
      //检查学生信息，有就更新，没有就新增
      this._tmpMoile = this.signUpForm.stuMoblie;
      // debugger
      if( data == null || code != 200 ){
        this.signUpForm.userId = null;
        return;
      }

      //(1) 如果当前不存在学员姓名，以及idcard,那么直接更新;
      //(1) 如果存在姓名和身份证号，就询问下是否需要覆盖之前信息
      if( name && idCard ){
        
          this.$hqMessageBox({
            message: '是否替换当前学员信息?'
          }).then( ()=>{
            //确定更新
            this.updateUserInfo(data);
          })
      }else {
        this.updateUserInfo(data);
      }
    },
    updateUserInfo(data){
      Object.assign( this.signUpForm, {
          name:      data.realName,
          idCard:    data.idCard,
          stuMoblie: data.mobile,
          userId:    data.userId
        });
    },

    //确认订单信息弹窗 点击提交
    async onSureOk() {

      let res = await this.getGoodsDetail(this.specIdArr);
      console.log('[检验商品价格变动] 没变化:', res)
      if (res) {
        // this.collectionInfo.phoneCode = infoData.phoneCode;
        this.onSubmit();
      }
    },
    // 选择班型时候触发
    async getthrid_checkCost() {
      let params = {
        goodsId: this.currentGoods.goodsId, //商品id
        businessIdInt: this.currentGoods.businessId,
        commodityIdList: this.isCj ? this.specIdArr : [...this.commodityIdList], //, //商品规格价格id集合
      }
      this.$fetch("thrid_checkCost", params).then(res => {
        this.getsure();
      }).catch(res => {
        if (res.code == 500) {
          return this.$message.error(res.msg)
        }
      })
    },
    getsure() {
      let shouyingtui = this.isLoanOrder() && this.loanRefundStuMoney > 0 ? this.loanRefundStuMoney : this.shouldReturnPayPrice
      console.log('提交了', this.signUpForm, this.dropoutForm, shouyingtui);
      if(shouyingtui !== '0.00') {
        if(this.dropoutForm.payType == 1) {
          // if(!this.signUpForm.bankAccount && !this.signUpForm.bankAccountName && !this.signUpForm.bank && !this.signUpForm.ncBankAccountPk) {
          //   return this.$message.error('退费方式不能为空！')
          // }
          console.log('!this.dropoutForm.bankAccount', !this.dropoutForm.bankAccount);
          console.log('!this.dropoutForm.bankAccountName', !this.dropoutForm.bankAccountName);
          console.log('!this.dropoutForm.bank', !this.dropoutForm.bank);
          console.log('!this.dropoutForm.ncBankAccountPk', !this.dropoutForm.ncBankAccountPk);
          if (!this.signUpForm.bankAccount) {
            return this.$message.error('退费方式不能为空！')
          }
          if (!this.signUpForm.bankAccountName) {
            return this.$message.error('退费方式不能为空！')
          }
          if (!this.signUpForm.bank) {
            return this.$message.error('退费方式不能为空！')
          }
          if (!this.signUpForm.ncBankAccountPk) {
            return this.$message.error('退费方式不能为空！')
          }
        }
      }
      this.$refs.form.validate(async (valid) => {
        console.log(this.signUpForm, '-----------------');
        if(!this.$refs.annexRef.getAllFiles().length) return this.$message.error("请上传附件")
        // if (!valid || !this.ckeckXL()) return this.$message.error("表单填写有误，请检查");
        if(!this.ckeckXL()) return false
        if (!valid || this.repeat) return this.$message.error("表单填写有误，请检查");
        if(!this.statusPrice) return this.$message.error('商品收支项目未配置，暂不支持报名，请联系商品管理老师')
        if (
          this.collectionInfo.isBalancePay == 1 &&
          this.collectionInfo.balancePay <= 0
        ) {
          return this.$message.warning("使用余额抵扣需大于0");
        }
        // if(this.isLoanOrder()){
        console.log('[申请转班] 该单为贷款')
        if(this.approvedRefundableAmount == 0){
            return this.$message.error("转班抵扣 = 0 元，不支持转班，请修改相关扣费或改为申请退费")
        }
        if(this.approvedRefundableAmount < 0){
            return this.$message.error("原订单需要学员补款，暂不支持售后申请")
        }
        // }
        //查看所有选择课程
        if (!this.getAllSeledClass()) return;
        // let buyStatus = await this.findOrderStatus(); //根据规格id和用户id获取商品状态
        // if (buyStatus) {
        //   return;
        // }

        this.cashierId = await this.getDDUserIdByTyUserId(this.signUpForm.cashierId)
        if(!this.cashierId) {
          this.$message.error('该出纳人员未配置钉钉id，请重新选择')
          return false
        }

        console.log("mobile", this.studentInfo.mobile , shouyingtui == '0.00', refundPayTypeMap[this.dropoutForm.payType]);
        let payTypeSet
        if (shouyingtui == '0.00') {
          payTypeSet = 0
        } else {
          payTypeSet = refundPayTypeMap[this.dropoutForm.payType]
        }
        let obj = {
          orderNo: this.orderTable[0].orderNo, //售后订单
          oldGoodsName: this.orderTable[0].goodsName, //售后商品
          oldGoodsSpecName: this.orderTable[0].goodsSpecName, //旧规格名称
          payType: payTypeSet, //退款方式
          approvedRefundableAmount: this.approvedRefundableAmount, //核定可退金额
          stuMobile: this.signUpForm.stuMoblie, //学员账号
          stuMobileNo: this.studentInfo.mobile,//学习帐号
          mobile: this.collectionInfo.userMobile, //余额抵扣时扣款账号
          name: this.signUpForm.name, //学员姓名 this.studentInfo.realName
          idCard: this.signUpForm.idCard, //身份证
          goodsName: this.signUpForm.goodsName, //转班商品
          spec: this.signUpForm.spec, //转班商品规格
          balancePay: Number(this.collectionInfo.balancePay), //余额抵扣
          finalPrice: this.waitPayPrice, //应收金额(减掉转班抵扣)
          amountReceivable: this.amountReceivable,//应收金额(没有减转班抵扣)
          refundMoney: this.isLoanOrder() && this.loanRefundStuMoney > 0 ? this.loanRefundStuMoney : this.shouldReturnPayPrice, //应退金额(为贷款单并且退学员金额>0则显示贷款-退学员金额字段)
          isBalancePay: this.collectionInfo.isBalancePay, //是否使用学员余额
          waitpayAmount: this.waitpayAmount,//收款信息-待收金额
          type: "transfer",
          receivable:
            Number(this.collectionInfo.finalPrice) -
            Number(this.collectionInfo.balancePay) -
            Number(this.approvedRefundableAmount), // 待收金额
          currentGoods: this.currentGoods.enrollingSchoolName, //报考院校
          enrollingMajorNameList:
            this.graduactionForm.enrollingMajorNameList[0], //报考专业
          enrollingTime: this.ckSingle.enrollingTime, //报考学级
          // bank: this.dropoutForm.bank, //开户行
          // bankAccountName: this.dropoutForm.bankAccountName, //开户名
          // bankAccount: this.dropoutForm.bankAccount, //户行账号
          bank: this.signUpForm.bank, //开户行
          bankAccountName: this.signUpForm.bankAccountName, //开户名
          bankAccount: this.signUpForm.bankAccount, //户行账号

          businessId: this.orderTable[0].businessId,
          refundPayType: this.dropoutForm.payType ? this.dropoutForm.payType : ''
        };

        if (obj.receivable < 0) {
          obj.receivable = 0;
        } else {
          obj.receivable = obj.receivable.toFixed(2);
        }

        if(this.signUpForm.isLoanRefund) {
          this.loanPlatformList.map(item => {
            if(item.id == this.dropoutForm.loanPlatformId) {
              obj.loanCompanyName = item.accountName
              obj.loanBankName = item.bankName
              obj.loanBankAccount = item.bankAccount
              obj.loanPlatformName = item.loanPlatformName

              this.dropoutForm.loanCompanyName = item.accountName
              this.dropoutForm.loanBankName = item.bankName
              this.dropoutForm.loanBankAccount = item.bankAccount
              this.dropoutForm.loanPlatformName = item.loanPlatformName
            }
          })
        }

        obj.isLoanRefund = this.signUpForm.isLoanRefund

        this.sureData = obj;
        localStorage.setItem("sureData", JSON.stringify(this.sureData));
        localStorage.setItem("showZkSingleOption", JSON.stringify(this.ctr.showZkSingleOption));
        localStorage.setItem("showCkSingleOption", JSON.stringify(this.ctr.showCkSingleOption));
        localStorage.setItem("showZkMultipleOption",  JSON.stringify(this.ctr.showZkMultipleOption));
        localStorage.setItem("showCjSingleOption", JSON.stringify(this.ctr.showCjSingleOption));
        localStorage.setItem("isDoubleProfessional",  JSON.stringify(this.zkMultiple.isDoubleProfessional));
        // 1）转班抵扣金额 > 0，显示【转班申请确认】弹窗
        // 2）转班抵扣金额 = 0，显示【转班抵扣确认】弹窗，点击【确认提交】按当前转班申请配置显示【转班申请确认】弹窗，点击【返回修改】关闭弹窗用户可修改转班申请相关数据
        if(Number(this.approvedRefundableAmount) == 0) {
          this.$refs.discount.init()
        } else {
          this.sureVisible = true;
        }
        console.log("转班数据", this.sureData);
      });
    },
    //点击底部 提交 按钮
    showSureData() {
      this.getthrid_checkCost()
    },
    //已选择完毕收支项目
    onReceiveOutOk(val) {
      let dropOutTable = [];
      for (let i = 0; i < val.length; i++) {
        const element = val[i];
        element.id = "";
        element.inoutType = "1";
        if (
          this.dropOutTableList[this._dropOutTableIndex].findIndex(
            (it) => it.inoutProjectId == element.inoutProjectId
          ) === -1
        ) {
          dropOutTable.push(
            Object.assign({}, element, {
              canDelete: true,
              orderInoutProjectName:
                element.orderInoutProjectName || element.name,
              edit: false,
              receivableMoney: element.receivableMoney || 0,
              receivedMoney: element.receivedMoney || 0,
              deductionMoney: element.deductionMoney || 0,
              refundableMoney: element.refundableMoney || 0,
            })
          );
        }
      }

      this.$set(this.dropOutTableList, this._dropOutTableIndex, [
        ...this.dropOutTableList[this._dropOutTableIndex],
        ...dropOutTable,
      ]);

    
      this.receiveOutVisible = false;
      this._dropOutTableIndex = -1;
    },

    //取消收支项目选择
    onReceiveOutCancel() {
      this.receiveOutVisible = false;
      this._dropOutTableIndex = -1;
    },
    //增加退费计算列
    addDropOut(index) {
      // 重置默认条件显示
      this.receiveOutFields = {
        select: "",
        name: "",
        code: "",
      };
      this.receiveOutVisible = true;
      this._dropOutTableIndex = index;

      this.currentSelDrop = this.dropOutTableList[index];
    },

    setBalanceNull() {
      //如果待收款不大于0，则应该取消余额抵扣
      if (this.collectionInfo.finalPrice <= 0) {
        this.collectionInfo.isBalancePay = "0";
        this.collectionInfo.balancePay = 0;
      }
    },

    //计算收费项目表格
    async getReceiveItem(specIdArr, isGoods2) {
      console.log('[获取收费项目]')
      const dataKey = key => isGoods2 ? `${key}2` : key;
      if (specIdArr) {
        this[dataKey('specIdArr')] = specIdArr;
      }
      this.waitPay();
      this.shouldReturnPay();
      let _spPriceIdStr = isGoods2 ? this.specIdArr2 : this.specIdArr
      if (!Checker.isArray(_spPriceIdStr) || !_spPriceIdStr.length) return;
      const params = { 
        spPriceIdStr: _spPriceIdStr.toString() 
      };
      if(!params.spPriceIdStr) return console.log('[getReceiveItem] spPriceIdStr:', params.spPriceIdStr)
      //获取财经组合套餐
      // if( this.currentGoods.goodsTemplate == goodsTemplateEnum.cjzh ){
      //   this.getComComReceiveItem(params);
      //   return;
      // }
       this.getComComReceiveItem(params,'', dataKey('receiveItemData'));

     
      // let requestName = this.isCj
      //   ? "findInoutProjectForApi"
      //   : "third_findInoutProjectForApi";
      // const { data } = await this.$fetch(requestName, params);

      // this[dataKey('receiveItemData')] = data;
    },
    //获取组合套餐的收支项
    async getComComReceiveItem(params, childSpecificationPriceIds = '', key){
      console.log('[getComComReceiveItem] params:', params);
      let receiveData = []
      const res = await this.$fetch('queryBySpStrAndGoodsTemp', {
        spStr: params.spPriceIdStr,
        goodsTemp: this.currentGoods.goodsTemplate,
        upClassDecimal: 0,
        goodsDiscounts:this.discountPrice || 0,
        childSpecificationPriceIds,
        inoutConfigSnapshot: this.inoutConfigSnapshot,
        // newGoodsPrice: this.collectionInfo.newGoodsPrice,
        newGoodsPrice: this.isCj?this.newGoodsPrice:this.zkNewGoodsPrice,
        shouldPay: this.shouldPay
      })
      console.log('[组合套餐]收支项目: ', res);
      if(res && res.code === 200){
        const data = res.data.inoutProjectList || []
        data.map(item =>{
          // 优惠额
          item.priceDiscount = item.discountAmount
          // 应收金额
          item.shouldRecive = item.receivable
          receiveData.push(item)
        })
      }
      this[key] = receiveData;
      if(res.data && res.data.moreThanFlag) { // 3578
        if (this.discountNewPrice < res.data.maxDiscountAmount) {
          this.$message.error(`请输入不大于最大优惠金额${ this.discountNewPrice }`);
        } else {
          this.$message.error(`请输入不大于收支项目优惠金额${res.data.maxDiscountAmount}，如有问题请联系管理员`)
        }
        this.discountPrice = ""
        this.getReceiveItem()
      } else if(this.discountPrice > this.discountNewPrice){
          this.$message.error(`请输入不大于最大优惠金额${ this.discountNewPrice }`);
          this.discountPrice = ""
      }
    },
    // 套餐任选课程结果
    confirmClass(isEdit){
      // 保存课程选择结果
      if(!isEdit){
        let childSpecificationPriceIds = ''
        if(this.getAllSeledClass()){
          console.log('[已选课程]', this.userSelectMealGroup)
          childSpecificationPriceIds = (this.userSelectMealGroup.map(
            (item) => item.goodsSpecificationPriceId
          ) || []).toString()
        }
        this.getComComReceiveItem({ spPriceIdStr: this.specIdArr.toString() }, childSpecificationPriceIds)
      }
    },
    // 商品规格弹窗确定回调
    onSpecModalOk(specInfo) {

      this.removeDiscountInfo();
      const { totalPrice, specIdArr, specificationName, specArr } = specInfo;
      console.log("specInfo:");
      console.log(specInfo);
      this.clearValidate()
      if (!this.isCj) {
        //自考套餐，选择班型之后，根据班型的数据去拿取报考专业等信息。
        //需要清空报考专业，报考专业的选择数据。报考院校数据
        this.removeZkChoiceSpc();
        this.updateZkMultipleInfo(specInfo);
        this.gsVisible = false;
        //针对有班型选择的自考套餐情形
        this.getfindPlatformBySpPriceId();
        return;
      }

      this.startActivityDetail(specArr);


      this.setDeafultBalancePay();
      this.collectionInfo.newGoodsPrice = totalPrice;
      this.specIdArr = specIdArr;
      this.goodsSpecTable = this.getSpcMergeData(specArr);
      this.signUpForm.spec = specificationName;
      // 判断是否套餐还是单品的商品
      if (this.currentGoods.type === 1) {
        this.getSetMealGroupInfo();
        console.log("goodsSpecTable", this.goodsSpecTable);
      }
      if(this.currentGoods.goodsTemplate == goodsTemplateEnum.cjzh){
        this.findComComGoodsInfo()
      }
      this.getOrderStatus();
      this.getfindPlatformBySpPriceId();
      this.setSelcetionShow();
      this.calcFinalMoney();
      this.findGoodsCourseBySpPriceId();
      this.gsVisible = false;
      // this.collectionInfo.recegoodsmoney = (this.collectionInfo.newGoodsPrice -  (this.isCj ? this.goodsDis : this.zkGoodsDiscount)).toFixed(2)
      // this.collectionInfo.refundMoney = this.collectionInfo.recegoodsmoney - this.approvedRefundableAmount
      // if (this.collectionInfo.refundMoney < 0) {
      //   let num = this.collectionInfo.refundMoney.toString().slice(1)
      //   this.collectionInfo.refundMoney = Number(num)
      // }
      this.getEmploymentContract()
      if (this._priceChange) {
        this.$nextTick(() => {
          this.$bus.$emit("autoSelOnPriceChange", this._changeSel);
        });
      }
    },
    getSpcMergeData(data, isInit = false) {
      let obj = {
        kuaidaTotal: 0,
        kuaidaFrequency: 0,
      };
      let goodsPrice = 0; //原价格
      let goodsDiscountPrice = 0; //优惠价格
      data.forEach((item) => {
        if (item.goodsPrice)
          goodsPrice += isNaN(+item.goodsPrice) ? 0 : +item.goodsPrice;
        if (item.goodsDiscountPrice)
          goodsDiscountPrice += isNaN(+item.goodsDiscountPrice)
            ? 0
            : +item.goodsDiscountPrice;
      });
      obj = Object.assign(obj, data[0]);
      if (!isInit) {
        obj.goodsPrice = accAdd(0, goodsPrice, 2);
        obj.goodsDiscountPrice = goodsDiscountPrice
          ? accAdd(0, goodsDiscountPrice, 2)
          : "--";
        this.collectionInfo.newGoodsPrice = obj.goodsPrice;
        this.collectionInfo.goodsDiscountPrice = goodsDiscountPrice
          ? accAdd(0, goodsDiscountPrice, 2)
          : obj.goodsPrice;
      } else {
        if (data[0]) {
          const item = data[0];
          obj.goodsPrice = accAdd(0, item.goodsPrice || 0, 2);
          goodsDiscountPrice = item.goodsDiscountPrice || 0;
          obj.goodsDiscountPrice = item.goodsDiscountPrice
            ? accAdd(0, item.goodsDiscountPrice, 2)
            : "--";
          this.collectionInfo.newGoodsPrice = obj.goodsPrice;
          this.collectionInfo.goodsDiscountPrice = goodsDiscountPrice
            ? accAdd(0, goodsDiscountPrice, 2)
            : obj.goodsPrice;
        }
      }
      this.collectionInfo.discountPrice = this.approvedRefundableAmount;
      let result = [obj]
      if(data.length > 1){
        // result = [...result, ...data.slice(1)]
      }
      return result;
    },
    // 获取套餐分组信息
    async getSetMealGroupInfo() {
      if (!this.currentGoods.goodsId || !this.specIdArr.length) return;
      const params = {
        goodsId: this.currentGoods.goodsId,
        spPriceIdStr: this.specIdArr.toString(),
      };
      const { data } = await this.$fetch(
        "thrid_getGoodsGroupInfo",
        params
      );
      for (let i in data) {
        for (let j in data[i].goods) {
          data[i].closeStatus = true;
          data[i].selComplete = false;
          if (data[i].sellStatus == 0) {
            data[i].goods[j].checked = true;
          } else {
            data[i].goods[j].checked = false;
          }
        }
      }

      this.mealGroupData = data;
      this.$nextTick(() => {
        this.mealGroupData.map((item) => {
          item.selected_count = 0;
          if (!item.sellStatus) {
            item.goods.map((obj) => {
              obj.disabled = true;
              obj.checked = true;
              if (this.$refs.multipleTable) {
                this.$refs.multipleTable.toggleRowSelection(obj);
              }
            });
          }
        });
      });
    },

    // 选择商品规格回调
    onChoiceGoods(action) {
      // 当前动作是否为选取第二志愿
      this._choiceGoods2 = action === this.SecondGoods
      if(this._choiceGoods2){
        if(!this.ckSingle.form.enrollingMajorId){
          return this.$message.error('请选择报名商品报考专业')
        }
        if(!this.ckSingle.enrollingTime){
          return this.$message.error('请选择报名商品报考学级')
        }
        
      }
      this.goodsForm = {}
      this.selectGoodsType = String(this._choiceGoods2 ? selectGoodsTypeEnum.ckSecondGood : selectGoodsTypeEnum.qwTransfer)
      console.log('[选取第二志愿]', this._choiceGoods2);
      this.goodsDrawerVisible = true;
    },

    removeChoiceGoods(action) {
      if(action == null ) {
        this.$refs.deptsys && this.$refs.deptsys.init()
        this.$refs.deptsystwo && this.$refs.deptsystwo.init()
        this.showYxOption = false
        this.showYxOption2 = false
      }

      this.removeDiscountInfo();
      const isGoods2 = action === this.SecondGoods//清除第二志愿
      console.log('[第二志愿]', isGoods2, action);
      if(isGoods2) {
        this.$refs.deptsystwo && this.$refs.deptsystwo.init()
        this.showYxOption2 = false
      }

      this.discountInfoList = [];
      this.discountNotuse = [];
      this.wxTradeOrderDiscountVOList=[];
      this.discountTip = "";
      this.discountPrice = "";
      let cleanGoods2 = ()=>{
        this.signUpForm.oldGoodsName2 = ''
        this.signUpForm.goodsName2 = "";
        this.signUpForm.spec2 = "";
        this.collectionInfo.newGoodsPrice2 = 0;
        this.collectionInfo.discountPrice2 = 0;
        this.collectionInfo.goodsDiscountPrice2 = 0;
        this.currentGoods2 = {};
        this.resetGoodSelectedInfo(true)
        this.cleanProfessional(false)
      }
      let cleanGoods1 = ()=>{
        this.signUpForm.oldGoodsName = "";
        this.signUpForm.goodsName = "";
        this.signUpForm.spec = "";
        this.collectionInfo.newGoodsPrice = 0;
        this.collectionInfo.discountPrice = 0;
        this.collectionInfo.goodsDiscountPrice = 0;
        this.oneItem = [];
        this.currentGoods = {};
        this.cjComComGoodsInfo = {}
        this.comComGoodsInfoList = []
        this.userSelectMealGroup = []
        this.resetGoodSelectedInfo(true)
        this.recoverTransactionInfo()
      }
      if(Checker.isUndefined(action) || Checker.isNull(action)){
        cleanGoods1()
        cleanGoods2()
      }else{
        if(isGoods2){
          cleanGoods2()
        }else{
          cleanGoods1()
          cleanGoods2()
          this.repeat = false
        }
      }
      this.calcFinalMoney();
      this.resetGoodSelectedInfo(isGoods2);
      cleanGoods1 = null
      cleanGoods2 = null
    },

    resetGoodSelectedInfo(isGoods2) {
      if(!isGoods2){
        this.goodsSpecTable = [];
        this.mealGroupData = [];
        this.specIdArr = [];
        this.totalPrice = 0;
        this.oneItem = [];
        this.currentGoods = {};
        this.receiveItemData = [];
      }else{
        this.goodsSpecTable2 = [];
        this.mealGroupData2 = [];
        this.specIdArr2 = [];
        this.totalPrice2 = 0;
        this.oneItem2 = [];
        this.currentGoods2 = {};
        this.receiveItemData2 = [];
      }
      this.removeZkChoiceSpc(isGoods2);
    },

    removeZkChoiceSpc(isGoods2) {
      this.zkMultiple.mealGroupData = [];
      this.zkMultiple.curEnrollingMajorName = "--";
      this.zkMultiple.curClassInfo = "";
      this.resetAfterPrefessionalData(isGoods2);
    },

    removeChoiceSpc() {
      this.signUpForm.spec = "";
      this.goodsSpecTable = [];
      this.mealGroupData = [];
      this.specIdArr = [];
      this.collectionInfo.newGoodsPrice = 0;
      this.collectionInfo.discountPrice = 0;
      this.collectionInfo.goodsDiscountPrice = 0;
      this.calcFinalMoney();
    },

    resetAfterPrefessionalData(isGoods2) {
      const dataKey = key => !isGoods2 ? key : `${key}2`
      this.zkSingle.enrollingProvinceList = [];
      this.zkSingle.enrollingSchoolList = [];
      this.zkSingle.specificationPriceDataSource = [];
      this.zkSingle.zkGoodsSpecTable = [];
      this.zkSingle.courseList = [];
      this.zkSingle.form.provinceId = "";
      this.zkSingle.form.schoolId = "";

      this.ckSingle[dataKey('goodsId')] = "";
      this.ckSingle[dataKey('goodsPrice')] = 0;
      this.ckSingle[dataKey('enrollingMajorName')] = "";
      this.ckSingle[dataKey('enrollingGradationDatasource')] = [];
      this.ckSingle[dataKey('enrollingGradationList')] = [];
      this.ckSingle[dataKey('enrollingMajorList')] = [];
      this.ckSingle[dataKey('specificationPriceDataSource')] = [];
      this.ckSingle[dataKey('courseList')] = [];
      this.ckSingle.form[dataKey('enrollingGradationId')] = "";
      this.ckSingle.form[dataKey('enrollingMajorId')] = "";
      this.ckSingle[dataKey('effectiveMonth')] = ''

      this.zkMultiple.enrollingProvinceList = [];
      this.zkMultiple.enrollingSchoolList = [];
      this.zkMultiple.specificationPriceDataSource = [];
      this.zkMultiple.zkEnrollingProvinceList = [];
      this.zkMultiple.zkEnrollingSchoolList = [];
      this.zkMultiple.bkEnrollingProvinceList = [];
      this.zkMultiple.bkEnrollingSchoolList = [];
      this.zkMultiple.zkGoodsSpecTable = [];
      this.zkMultiple.courseList = [];
      this.zkMultiple.form.provinceId = "";
      this.zkMultiple.form.schoolId = "";
      //是否是双层次
      // this.zkMultiple.isDoubleProfessional = false
      //双层次中的专科- 省
      this.zkMultiple.zkEnrollingProvinceList = [];
      //双层次中的专科 - 院校
      this.zkMultiple.zkEnrollingSchoolList = [];
      //双层次中的本科 - 省
      this.zkMultiple.bkEnrollingProvinceList = [];
      //双层次中的本科 - 院校
      (this.zkMultiple.bkEnrollingSchoolList = []),
        (this.zkMultiple.form.zkProvinceId = "");
      this.zkMultiple.form.zkSchoolId = "";
      this.zkMultiple.form.bkProvinceId = "";
      this.zkMultiple.form.bkSchoolId = "";
      if(!isGoods2){
        this.totalPrice = 0;
        this.zkNewGoodsPrice = 0;
        this.zkGoodsDiscount = 0;
        this.ckNewGoodsPrice = 0;
      }
    },

    // 展示商品规格弹窗
    onChoiceGoodsSpec() {

      if (this.signUpForm.goodsName.trim().length === 0) {
        return this.$message.error("请先选择商品");
      }
      if (this.signUpForm.spec === "默认班型" || this.signUpForm.spec === "默认班型") {
        return;
      }
      this.gsVisible = true;
    },
    // 选择商品弹窗完毕
    async onGoodsDrawerOk(goods) {
      console.log('[选中商品]', goods);
      this.dropoutForm.loanRefundMoney = ''

      // 商品定价状态 0 待定 1 已定
      this.statusPrice = goods.statusPrice
      // if(this._choiceGoods2 && (!this.currentGoods.goodsId || this.currentGoods.goodsId == goods.goodsId || goods.xlType != xlTypeEnum.ck)){
      //   console.log('[未选择第一志愿或第二志愿不是成考或第一第二志愿的商品一致]');
      //   this.$message.error('请选择与报名的商品同一个层次、同一个学级')
      //   // this.goodsDrawerVisible = false
      //   // this._choiceGoods2 = false
      //   return
      // }


      //如果是默认班型，那么就直接去获取优惠券信息
      // let goodsId = this.currentGoods.goodsId
      // let schoolId = this.transactionInfo.ncCampusSchoolId
      // this.findActivityDetail( goodsId, schoolId, "", []);
      // if(goods.goodsId == this.orderTable[0].goodsId){
      //   return this.$message.error('学员已报名该商品，请重新选择商品')
      // }
      // this.removeChoiceGoods(this._choiceGoods2 ? this.SecondGoods : null);
      //将所有的template初始化。即都不显示出来
      Object.keys(this.ctr).forEach((key) => {
        this.ctr[key] = false;
      });

      this.isCj = `${ goods.businessId}` === '1';
      this.ctr.showCjSingleOption = this.isCj;

      if( !this._choiceGoods2 ){
         this.removeChoiceGoods(this._choiceGoods2 ? this.SecondGoods : null);
      }

      //根据不同的商品展示不同的template
      let getGoodsSpecs = "getGoodsSpecs";
       if (goods.xlType == 2 || goods.xlType == 3) {
        console.log('[选择商品]', goods);
        this.ctr.showCkSingleOption = true;
        getGoodsSpecs = "getCkGoodsSpecs";
        this[this._choiceGoods2 ? 'xlType2' : 'xlType'] = goods.xlType;
        if(!this._choiceGoods2){
          this.zkSingle.form.enrollingMajorId = "";
          this.ckSingle.form.enrollingGradationId = "";
          this.ckSingle.effectiveMonth = "";
          this.ckSingle.goodsPrice = "";
        }else{
          this.zkSingle.form.enrollingMajorId2 = "";
          this.ckSingle.form.enrollingGradationId2 = "";
          this.ckSingle.effectiveMonth2 = "";
          this.ckSingle.goodsPrice2 = "";
        }
      }


      this.showloanCheck = false;
      this.showloadfont = false;
      const currentGoodsKey = this._choiceGoods2 ? 'currentGoods2' : 'currentGoods'
      if (this[currentGoodsKey].goodsId == goods.goodsId && !this._priceChange) {
        this.goodsDrawerVisible = false;
        return;
      }
      this.$nextTick(() => {
        // 1单品单规格，2单品多规格，3单品默认规格，4套餐规格单选，5套餐规格多选，6套餐默认规格）
        this[currentGoodsKey] = goods;
        this.currentGoodsRank = this[currentGoodsKey].goodsRank;
        this.signUpForm[this._choiceGoods2 ? 'goodsName2' : 'goodsName'] = goods.goodsName;
        this.goodsDrawerVisible = false;
        this[getGoodsSpecs]();
        // if(!this._choiceGoods2){
        //   this.transferSignUpJudgeRecommend(goods)
        // }
        this.clearValidate()
      });
    },
    // 用于判断选择班型后是否已经购买过了
    async getOrderStatus() {
      let params
      if (this.canSelectMajor == true) {
         params = {
          // userId: this.studentInfo.userId,
          // spPriceIdStr: this.specIdArr.toString(),

          userId: this.studentInfo.userId,
          commodityIdList: this.specIdArr,
          businessIdInt: 1,
          enrollingTime: "",
          enrollingInfo: {
            enrollingSchoolId: this.currentGoods.enrollingSchoolId,
            enrollingMajorId: this.ckSingle.form.enrollingMajorId,
            canSelectMajor: true
          }
        }
      } else {
        params = {
          // userId: this.studentInfo.userId,
          // spPriceIdStr: this.specIdArr.toString(),

          userId: this.studentInfo.userId,
          commodityIdList: this.specIdArr,
          businessIdInt: 1,
          enrollingTime: ""
        }
      }

      const {
        data = {},
      } = await this.$fetch(
        // "findOrderStatusByUserIdAndSpecificationId",
        "doCheckRepeatBuy",
        params
      );
      //purchaseStatusResultStatus购买状态(0 未购买(暂不返回数据) 1全款待支付 2已购买 3支付中 4分笔待支付)
      // if (data.purchaseStatusResultStatus === 0) {
      //   return false;
      // } else {
      //   this.$message.error("学员已报名该班型，请重新选择班型");
      //   return true;
      // }

      // 结果 status（1通过，0不通过）
      if(data.status == 0) {
        this.repeat = true
        // this.$message.error("学员已报名该班型，请重新选择班型");
        // return true
      } else {
        this.repeat = false
      }
    },

    /****************************************************************************************** */
    // 请求商品规格
    async getGoodsSpecs() {
      // 重置商品规格弹窗数据
      this.goodsSpecModalKey = getRandomKey();
      this.signUpForm.spec = "";
      const params = {
        businessId: this.businessId,
        goodsId: this.currentGoods.goodsId,
      };
      this.collectionInfo.discountPrice = 0;
      this.collectionInfo.goodsDiscountPrice = 0;

      //获取商品列表信息
      const { data } = await this.$fetch("getGoodsSpecs", params);
      this.specificationPriceArr = data.specificationPriceArr;

      if (data.specificationTypeArr.length > 0) {
        this.getGoodsSpBySpType();
        this.specTypeArr = data.specificationTypeArr;

        // 此字段是为了在 套餐多规格多选的情况下 限制选择的数量
      } else if (data.specificationTypeArr.length == 0) {
        //typeArr为空的话， priceArr就只有一条数据 这条数据就是默认班型
        /***
         * {
         *    specificationPriceArr: [1个数据元素],
         *    specificationSelectCountArr:[],
         *    specificationTypeArr: []
         * }
         */
        this.specTypeArr = [];
        this.signUpForm.spec = "默认班型";
        this.specIdArr = [data.specificationPriceArr[0].id];
        this.goodsSpecTable = this.getSpcMergeData(
          data.specificationPriceArr.slice()
        );
        let goodsId = this.currentGoods.goodsId;
        let schoolId = this.transactionInfo.ncCampusSchoolId;
        this.findActivityDetail(
          goodsId,
          schoolId,
          '',[]
        );
    


        //difference  商品总价
        this.collectionInfo.newGoodsPrice =
          data.specificationPriceArr[0].goodsPrice;
        this.calcFinalMoney();


        this.getOrderStatus();
        //对于默认规格的套餐，也要调用一下商品信息 0单品  1套餐
        this.getfindPlatformBySpPriceId();

        if (this.currentGoods.type === 0) {
          this.findGoodsCourseBySpPriceId();
         /**
           * 财经-组合套餐
           * 1、获取课程清单
           * 2、获取组合套餐信息
           */
        } else if (
          this.currentGoods.type == 1 &&
          this.currentGoods.goodsTemplate == 6
        ) {
          this.getSetMealGroupInfo();
          this.findComComGoodsInfo();
          /***
           * 财经-普通套餐
           */
        } else if (this.currentGoods.type == 1) {
          this.getSetMealGroupInfo();
        }

        if(this.isCj) {
          this.getEmploymentContract()
        }
    
      }
      
      this.getReceiveItem();
      this.specPriceArr = data.specificationPriceArr;
      this.specCountArr = data.specificationSelectCountArr || [];
      this.setSelcetionShow();
    },



    // 获取商品规格第二行
    async getGoodsSpBySpType() {
      let params = {
        goodsId: this.currentGoods.goodsId,
      };
      const { data } = await this.$fetch("third_GoodsSpBySpType", params);
      this.specArray = data;

      //如果时价格变动引起重新获取规格，则自动填充
      if (this._priceChange) {
        this.autoSelSpecs();
      }
    },
    // 老师根据学员账户的余额，填写，这次使用的抵扣输入框改变
    onBalancePayChange(val) {
        this.collectionInfo.balancePay = val;
      //是否开启余额抵扣
      if (this.collectionInfo.isBalancePay == "0") {
        return;
      }

      if (isNaN(Number(this.collectionInfo.balancePay))) {
        this.collectionInfo.balancePay = 0;
        return;
      }

      
      //余额抵扣金额不能超过账户余额。
      if (
        this.collectionInfo.balancePay > this.collectionInfo.stuAccountMoney
      ) {
        this.collectionInfo.balancePay = "";
        return this.$message.error("余额抵扣金额不能超过账户余额");
      }

      //余额抵扣金额不能超过应收款
      if (
        Number(this.collectionInfo.balancePay) >
        Number(this.collectionInfo.shouldPay)
      ) {
        this.collectionInfo.balancePay = "";
        return this.$message.error("余额抵扣金额不能超过应收款");
      }

      //余额抵扣金额不能为负数
      if (this.collectionInfo.balancePay < 0) {
        this.collectionInfo.balancePay = "";
        return this.$message.error("余额抵扣金额不能为负数");
      }

      //根据余额抵扣计算待收金额，应退金额。
      this.waitPay();
      this.shouldReturnPay();
    },


    // 计算最终价格
    calcFinalMoney(reCalcReceiveItem = true) {
      let diffPrice; //商品还应付的款
      let goodsSpecTableFirstObj = this.goodsSpecTable[0] || { goodsPrice: 0 };
      //如果取消余额抵扣，本次使用归零
      if (this.collectionInfo.isBalancePay == "0") {
        this.collectionInfo.balancePay = 0;
      } else {
        //如果转班商品价格 - 商品优惠 - 转班抵扣 < 0,  则不让其选中。
        if (
          goodsSpecTableFirstObj.goodsPrice -
            this.collectionInfo.discountPrice -
            this.approvedRefundableAmount <=
          0
        ) {
          this.collectionInfo.isBalancePay = "0";
          this.collectionInfo.balancePay = 0;
          return false;
        }
      }

      // 不使用学员账户余额的场景
      if (this.collectionInfo.isBalancePay == "0") {
        // 应收款 = 转班商品售价 - 转班折扣
        if (this.currentGoods.type === 0) {
          //单品
          diffPrice = accSub(
            goodsSpecTableFirstObj.goodsPrice,
            this.collectionInfo.discountPrice
          );
          diffPrice = this.isCj
            ? diffPrice
            : accSub(diffPrice, this.approvedRefundableAmount);
        } else {
          diffPrice = accSub(
            this.collectionInfo.goodsDiscountPrice || 0,
            Number(this.collectionInfo.newGoodsPrice) > 0
              ? this.collectionInfo.discountPrice +
                  this.approvedRefundableAmount
              : this.collectionInfo.discountPrice
          ); //
        }
      } else {
        // 使用学员账户余额的场景
        // 应收款 = 转班商品售价 - 转班折扣 - 老师输入的余额抵扣价
        if (!this.collectionInfo.userMobile && this.studentInfo.mobile) {
          this.getBalanceByMoblie(this.studentInfo.mobile);
          this.collectionInfo.userMobile = this.studentInfo.mobile;
        }

        if (this.currentGoods.type === 0) {
          //单品
          diffPrice = this.isCj
            ? accSub(
                goodsSpecTableFirstObj.goodsPrice,
                this.collectionInfo.discountPrice
              )
            : accSub(
                this.collectionInfo.newGoodsPrice,
                this.collectionInfo.discountPrice
              );
          diffPrice = this.isCj
            ? diffPrice
            : accSub(diffPrice, this.approvedRefundableAmount);
        } else {
          diffPrice = accSub(
            this.collectionInfo.goodsDiscountPrice || 0,
            this.collectionInfo.discountPrice
          );
          diffPrice = this.isCj
            ? diffPrice
            : accSub(diffPrice, this.approvedRefundableAmount);
        }
        const balancePay = isNaN(+this.collectionInfo.balancePay)
          ? this.collectionInfo.balancePay
          : 0;
        diffPrice = accSub(diffPrice, balancePay);
      }

      if (diffPrice >= 0 && this.collectionInfo.isBalancePay === "1") {
        this.collectionInfo.finalPrice = diffPrice;
        if (this.collectionInfo.finalPrice) this.setBalanceNull();
        this.collectionInfo.refundMoney = 0;
      } else {
        //有退款
        this.collectionInfo.finalPrice = 0;
        this.collectionInfo.refundMoney =
          this.collectionInfo.recegoodsmoney - this.approvedRefundableAmount;
        if (this.collectionInfo.refundMoney < 0) {
          let num = this.collectionInfo.refundMoney.toString().slice(1);
          this.collectionInfo.refundMoney = Number(num);
        }
      }

      this.collectionInfo.recegoodsmoney = (
        this.collectionInfo.newGoodsPrice -
        (this.isCj ? this.goodsDis : this.zkGoodsDiscount)
      ).toFixed(2);
      // this.isCj && reCalcReceiveItem && this.getReceiveItem();
      this.getReceiveItem();
    },


    // 去原订单详情
    goOrderDetail(row) {
      let path = `${ row.businessId}` === '1' ?  "/afterSale/cjOrderDetail": "/afterSale/xlOrderDetail";
      this.$router.push( {
        path: path,
        query: {
          orderId: row.orderId
        }
      });     
    },
    sureDelOrder() {
      //有商品时删除订单，弹出提醒
      const deleteOrderRowIndex = this.orderTable.findIndex(
        (item) => item === this.tmpRow
      );
      this.orderTable.splice(deleteOrderRowIndex, 1);
      if (this.orderTable.length != 0) {
        this.dropOutTableList.splice(deleteOrderRowIndex, 1);
        this.classInfo.splice(deleteOrderRowIndex, 1);
        this.showDelOrder = false;
        this.collectionInfo.balancePay = 0; //删除订单不重置余额抵扣容易出现问题
        return;
      }

      this.signUpForm = {
        oneOrMore: this.signUpForm.oneOrMore,
        reason: "",
        phpneNumber: "",
        userName: "",

        idCard: "",
        // 学员真正的学习账号
        mobile: "",
        // 学员手机
        stuMoblie: "",
        // 选择的商品名称
        goodsName: "",
        // 选择的商品规格名称
        spec: "",
      };
      this.setDeafultData(deleteOrderRowIndex);
      this.showDelOrder = false;
    },
    //删除订单时 还原页面的数据，
    setDeafultData(deleteOrderRowIndex) {
      if (this.orderTable.length) {
        this.dropOutTableList.splice(deleteOrderRowIndex, 1);
        return;
      }
      this.studentInfo = {}; //清空学员信息
      this.orderExtraInfo = {}; //清空成交信息
      this.goodsSpecTable = []; //还原商品价格字段
      this.receiveItemData = []; //清空收费项目
      this.receiveItemData2 = []
      this.classInfo = []; //清空退费信息 - 退费计算的商品信息
      this.dropOutTableList = []; //清空退费计算的表格
      this.collectionInfo = {
        //清空收款信息
        // 选择的新升班商品售价
        newGoodsPrice: "0",
        // 原商品的售价->总已收金额
        oldGoodsPrice: "0",
        // 升班折扣 ，老师输入的
        discountPrice: 0,
        // 是否使用余额
        isBalancePay: "0",
        // 学员的账户上的余额
        stuAccountMoney: "0",
        // 余额抵扣的价格
        balancePay: 0,
        // 余额抵扣的账户
        userMobile: "",
        userName: "", //余额账户姓名
        // 短信验证码
        phoneCode: "",
        //最后计算出的价格
        finalPrice: "0",
        // 最后计算的价格 应退款
        refundMoney: "0",
        isLoan: "0",
      },
      this.oneItem = [];
      this.currentGoods = {},
        //还原退费方式>学员余额
      this.dropInfo = {
          dropOrderNo: "",
          dropMobile: "",
          dropName: "", //学员余额退款时的姓名
        },
      this.dropoutForm = {
          payType: "0",
          // bank: "",
          // bankAccountName: "",
          // bankAccount: "",
          // 有钱花贷款
          loanRefundMoney: '',
          loanBankName: '',
          loanBankAccount: '',
          loanRefundTime: '',
          loanCompanyName: '',
          loanRepaymentMoney: '',
          // 有钱花贷款 end
        };
    },
    //获得套餐所有已选中的课程
    getAllSeledClass() {
      if (
        this.$refs.selcetionClass &&
        this.$refs.selcetionClass.lessonStatus &&
        this.currentGoods.type === 1
      ) {
        this.$message.error('请保存选课')
        return false;
      }
      let allSel = [];
      for (let i of this.mealGroupData) {
        for (let j of i.goods) {
          if (j.checked === true) {
            allSel.push(j);
          }
        }
      }
      this.userSelectMealGroup = allSel;
      return true;
    },
    // 检查学历相关数据
    ckeckXL(){
      // let pass = true
      // // 检查成考
      // if(this.currentGoods.xlType === xlTypeEnum.ck){
      //   if(!this.ckSingle.form.enrollingMajorId || !this.ckSingle.form.enrollingGradationId || !this.ckSingle.form.enrollingMajorId2 || !this.ckSingle.form.enrollingGradationId2){
      //     pass = false
      //   }
      // }
      // // 网教
      // if(this.currentGoods.xlType === xlTypeEnum.wj){
      //   if(!this.ckSingle.form.enrollingMajorId || !this.ckSingle.form.enrollingGradationId){
      //     pass = false
      //   }
      // }
      // return pass


       //成考、网教
      if (this.currentGoods.xlType === xlTypeEnum.ck || this.currentGoods.xlType === xlTypeEnum.wj) {
        if (!this.ckSingle.form.enrollingMajorId) {
          this.$message({ type: "error", message: "请选择专业" });
          return false;
        }

        if (!this.ckSingle.form.enrollingGradationId) {
          this.$message({ type: "error", message: "请选择学级" });
          return false;
        }

        //如果是成考，需要判断第二志愿
        if (this.currentGoods.xlType === xlTypeEnum.ck) {

          //第二志愿的专业
          if (this.signUpForm.goodsName2 && !this.ckSingle.form.enrollingMajorId2) {
            this.$message({ type: "error", message: "请选择第二志愿专业" });
            return false;
          }
        }
      }
      return true;
    },
    // 提交生成售后单
    async onSubmit() {
      //如果本次使用的款大于应收款，不允许提交
      this.$refs.form.validate(async (valid) => {
        if(!this.signUpForm.reason)  return this.$message.error("请填写申请原因");
        if (!valid || !this.ckeckXL()) return this.$message.error("表单填写有误，请检查");
        let validatePass = await this.goodsPriceAndUserInfoValidate()
        if (!validatePass) return;
        let shouyingtui = this.isLoanOrder() && this.loanRefundStuMoney > 0 ? this.loanRefundStuMoney : this.shouldReturnPayPrice
        try {
          //提交表单
          const params = await this.createAfterSaleOrder();
          if(shouyingtui == '0.00') {
            params.payType = "0"
          } else {
            params.payType = params.payType
          }
          console.log('[创建转班订单数据]', params)
          if(this.submitLoading) return console.log("[转班提交数据中]请稍等！！");
          this.submitLoading = true
          this.loading = true;
          params.channel = 0;
          if(this.dropoutForm.payType == 0) {
            params.bankAccountName = ""
            params.bank = ""
            params.bankAccount = ""
            params.ncBankAccountPk = ""
            params.ncAccountName = ""
          }
          
          const transactionInfoRes = await saveTransactionInfo({...this.transactionInfo, ...{ type: 1 }})
          console.log('[转班]保存成交信息 ', transactionInfoRes);
          if(!transactionInfoRes || transactionInfoRes.code !== 0){
            this.loading = false;
            return this.$message.error(transactionInfoRes.msg || '成交信息提交失败')
          }

          // 添加全网userId
          params.createrQwId = this.loginUser.userId
          params.canSelectMajor = this.canSelectMajor

          //20231110新增入参
          params.crmDeptId = this.loginUser.deptId

          this.processParmas = params
          
          let { code, msg, data } = await this.$fetch(
            "third_submitAfterSaleOrder",
            params
          )
          .catch(res =>{
            this.submitLoading = false
            console.log("[转班错误]", res);
            res.msg && this.$message.error(res.msg);
            this.loading = false;
          })
          console.log("[转班返回] code:", code, ' msg:', msg, ' data:', data);
          //创建成功
          if (code === 200) {
            this.submitLoading = false
            //插入日志
            this.$message.success("成功创建一条转班售后单");
            let path = "/aftersale/nclist"
            let query = {}
            if(Checker.isObject(data) && data.aftersaleId){
              path = '/afterSale/ncAfterSaleDetail'
              query.id = data.aftersaleId
              query.type = aftersaleTypeEnum.transfer
            }
            //跳转到售后列表
            this.$router.replace({
              path,
              query
            });
            this.$store.commit("setUpdateOrderList", true);
            
          } else if (code == 611) {
            this.tiforList = data
            this.showTis = true
          } else  {
            this.$message.error(msg);
          }
          this.submitLoading = false
          this.loading = false;
          

        } catch (err) {
          this.submitLoading = false
          console.error('[转班错误]catch: ', err);
          this.loading = false;
        }
        // this.sureVisible = false
      });
    },
    /**
     * 商品价格以及用户信息的验证
     */
    async  goodsPriceAndUserInfoValidate(){
      /***
         * 更新用户信息，或者创建用户信息
         */

        let errMsg = "";
        let hasUserId = await this.doLanJingData();
        if (!hasUserId) {
            errMsg = "用户信息更新蓝鲸失败，用户userId不存在，无法发起报名";
        }

        /***
         * 检查商品是否下架
         */
        if (await this.checkGoodsStatus() === 1) {
          errMsg = "商品已下架,请重新选择";
        }

        if( !this.transactionInfo.ncCustomerUserId ){
          errMsg = "成交信息中不存在ncId,不允许创建订单";
        }
      
        if( errMsg ){
          this.sureVisible = false;
          this.$message.error(errMsg)
        }

        return !errMsg;
    },
    // 检查商品是否下架
    async checkGoodsStatus() {
      const params = {
        goodsId: this.currentGoods.goodsId,
      };
      const { data, code } = await this.$fetch(
        "thrid_checkGoodsStatus",
        params
      );
      if (code === 200) {
        // goodsSchoolSellStatus  0待上架,1下架,2在售
        return data.goodsSchoolSellStatus;
      }
    },
    //检查收支项目，为空则补充默认信息，防止不能提交
    checkDataNull() {
      for (let i in this.dropOutTable) {
        if (this.dropOutTable[i].deductionsList.length < 1) {
          let fillData = this.dropOutTable[i];
          let obj = {
            orderInoutProjectId: fillData.orderInoutProjectId,
            orderInoutProjectName: fillData.orderInoutProjectName,
            inoutProjectId: "", //扣费项目id
            inoutProjectName: "", //扣费项目名称
            deductionMoney: 0, //扣费金额
            refundableMoney: fillData.refundableMoney,
            receivedMoney: fillData.receivedMoney,
            receivableMoney: fillData.receivableMoney,
          };
          this.dropOutTable[i].deductionsList.push(obj);
        }
      }
    },
    // 创建售后单
    /**
     *
     *   0: "退费",
        1: "转班",
        2: "转班",
     *
     * */
    async createAfterSaleOrder() {
      //收支项目中的扣费项目可能未空，所以要检查下
      this.checkDataNull();

      //1、 订单数组
      const orderTable = JSON.parse(JSON.stringify(this.orderTable))
      const aftersaleOrder = orderTable;
      aftersaleOrder[0].schoolId = this.transAftersaleOrderSchoolId()
      aftersaleOrder[0].ncOrderType = this.tableData[0].orderType
      aftersaleOrder[0].goodsTypeName = this.tableData[0].commodityTypeName
      aftersaleOrder[0].isTurnSchool = this.$route.query.transferSchool
      aftersaleOrder[0].subscribeSchoolName = this.orderTable[0].mabCampus //成交校区
      aftersaleOrder[0].subscribeSchoolId = this.orderTable[0].mabCampusPk //成交校区id
      /*2、付款详情
          / 退费计算，付款详情数组
      */
      let aftersalePay = [];
      for (let i in this.dropOutTableList) {
        aftersalePay.push(this.setOneDropOut(i));
      }
      let newGoodsPrice = this.isCj ? this.newGoodsPrice : this.zkNewGoodsPrice
      // 收款信息数组
      const aftersaleDetail = {
        newGoodsPrice: isNaN(+newGoodsPrice) ? 0 : +newGoodsPrice, //升（转）班商品售价
        oldGoodsPrice: this.collectionInfo.oldGoodsPrice, //原商品已收金额
        discountPrice: this.collectionInfo.discountPrice, //升（转）班折扣
        isBalancePay: this.collectionInfo.isBalancePay, //是否使用学员账户余额(0：不使用，1：使用)
        balancePay: Number(this.collectionInfo.balancePay), //	余额抵扣
        userMobile: this.collectionInfo.userMobile, //余额抵扣账户
        // finalPrice: this.collectionInfo.finalPrice, //	应收款
        finalPrice: isNaN(+this.waitpayAmount) ? 0 : +this.waitpayAmount,//	应收款
        // refundMoney: this.collectionInfo.refundMoney, //应退款
        refundMoney: isNaN(+this.shouldReturnPayPrice) ? 0 : +this.shouldReturnPayPrice, //应退款
        code: this.collectionInfo.phoneCode, //验证码
        isLoan: this.collectionInfo.isLoan, // 是否贷款0：不贷款，1贷款（三期新增）
      };

      // 选择的套餐课程
      let userSelectMealGroupIds =
        this.userSelectMealGroup.map(
          (item) => item.goodsSpecificationPriceId
        ) || [];
      // let cashierItem =
      //   this.cashierCheckList.find(
      //     (item) => item.dingtalkUserId === this.cashierData
      //   ) || {};

      let goodsEnrollingSchoolList = [
        ...this.graduactionForm.enrollingProvinceIdList.map((item, index) => {
          return {
            enrollingProvinceId: item,
            enrollingProvinceName:
              this.graduactionForm.enrollingProvinceNameList[index],
            enrollingSchoolId:
              this.graduactionForm.enrollingSchoolIdList[index],
            enrollingSchoolName:
              this.graduactionForm.enrollingSchoolNameList[index],
            volunteer: volunteerEnum.one,
            goodsId: this.currentGoods.goodsId,
            name: this.currentGoods.goodsName,
          };
        }),
      ];
      let goodsEnrollingMajorList = [
        ...this.graduactionForm.enrollingMajorIdList.map((item, index) => {
          return {
            majorId: this.graduactionForm.enrollingMajorIdList[index],
            majorName: this.graduactionForm.enrollingMajorNameList[index],
            gradationId: this.graduactionForm.gradationIdList[index],
          };
        }),
      ];
      let commodityIdList = this.specIdArr
      // 成考第二志愿
      if(this.currentGoods.xlType === xlTypeEnum.ck && this.currentGoods2.goodsId){
        goodsEnrollingSchoolList.push(...this.graduactionForm.enrollingProvinceIdList2.map((item, index) => {
          return {
            enrollingProvinceId: item,
            enrollingProvinceName:
              this.graduactionForm.enrollingProvinceNameList2[index],
            enrollingSchoolId:
              this.graduactionForm.enrollingSchoolIdList2[index],
            enrollingSchoolName:
              this.graduactionForm.enrollingSchoolNameList2[index],
            volunteer: volunteerEnum.second,
            goodsId: this.currentGoods2.goodsId,
            name: this.currentGoods.goodsName,
          };
        }))
        goodsEnrollingMajorList.push(...this.graduactionForm.enrollingMajorIdList2.map((item, index) => {
          return {
            majorId: this.graduactionForm.enrollingMajorIdList2[index],
            majorName: this.graduactionForm.enrollingMajorNameList2[index],
            gradationId: this.graduactionForm.gradationIdList2[index],
          };
        }))

      }

      // let cashierId = await this.getDDUserIdByTyUserId(this.signUpForm.cashierId)
      let selectedCashier = this.cashierCheckList.filter(item => item.tyUserId == this.signUpForm.cashierId)[0]
      
      if(this.wxTradeOrderDiscountVOList.length){
        if( !this.discountPrice ){
          this.discountPrice = 0;
        }
        this.wxTradeOrderDiscountVOList[0].discount = this.discountPrice;
        
        const specificationNameIdInfo = this.getSpecificationNameIdInfo()
        this.wxTradeOrderDiscountVOList.map((item, index) =>{
          this.wxTradeOrderDiscountVOList[index].goodsId = this.currentGoods.goodsId
          this.wxTradeOrderDiscountVOList[index].schoolId = this.orderTable[0].mabCampusPk//-1全国校区
          this.wxTradeOrderDiscountVOList[index].specificationNameId1 = specificationNameIdInfo.specificationNameId1
          this.wxTradeOrderDiscountVOList[index].specificationNameIds2 = specificationNameIdInfo.specificationNameIds2
        })
      }
      console.log('this.transactionInfo----打印',this.transactionInfo);
      const params = {
        // ncAccountName: this.dropoutForm.ncAccountName || '',
        // ncBankAccountPk: this.dropoutForm.ncBankAccountPk || '',
        ncAccountName: this.signUpForm.ncAccountName || '',
        ncBankAccountPk: this.signUpForm.ncBankAccountPk || '',
        orderOriginType: OrderOriginTypeEnum.nc,
        wxTradeOrderDiscountVOList: this.wxTradeOrderDiscountVOList,
        userId: this.signUpForm.userId,
        aftersaleType: "2",
        // 转班类型（0：一转一，1：多转一）
        // 现在只需要一转一
        changeClassType: 0, //this.signUpForm.oneOrMore,
        reason: this.signUpForm.reason,
        payType: this.dropoutForm.payType,
        // bank: this.dropoutForm.bank,
        // bankAccountName: this.dropoutForm.bankAccountName,
        // bankAccount: this.dropoutForm.bankAccount,
        bank: this.signUpForm.bank,
        bankAccountName: this.signUpForm.bankAccountName,
        bankAccount: this.signUpForm.bankAccount,
        idCard: this.signUpForm.idCard || "",
        createrId:  this.getTyUser().id,//this.userInfo.id,
        creater:  this.getTyUser().nickName || this.getTyUser().userName,//this.userInfo.userName,
        aftersaleOrder,
        aftersalePay,
        aftersaleDetail,

        schoolId: this.orderTable[0].signSchoolId || '',
        schoolName: this.orderTable[0].signSchoolName || '',
        signSchoolName:this.orderTable[0].signSchoolName || '',
        aftersaleSchoolNcid: this.orderTable[0].signSchoolId || '',//售后校区


        // balanceAccount: this.dropInfo.dropMobile,
        // accountName: this.dropInfo.dropName,
        balanceAccount: this.signUpForm.dropMobile,
        accountName: this.signUpForm.dropName,
        balanceUserId: this.orderTable[0].professional,

        // balanceAccount:this.signUpForm.mobile,
        // 创建订单对象（详情参考订单文档生成订单接口）
         cashierId: this.cashierId, //出纳审核ID
         cashier: selectedCashier && selectedCashier.userName, //出纳审核

        //外层的 businessId 表示售后的商品所属的赛道
        businessId: this.orderTable[0].businessId, //赛道
        promotionForm: this.transactionInfo.promotionForm,//促单形式
        isFourSix: ~~this.transactionInfo.isFourSix,//46分成
        createOrderVO: {

          phoneNumber: this.signUpForm.stuMoblie || "", //学员账号
          idCard: this.signUpForm.idCard || "", //身份证号
          name: this.signUpForm.name || "", //学员姓名
          education: this.signUpForm.education, // 学员学历
          goodsTemplate: this.currentGoods.goodsTemplate, //判断财经商品是否是组合套餐

          
          goodsId: this.currentGoods.goodsId, //商品id
          userId: this.signUpForm.userId, //用户id
          commodityIdList: commodityIdList, //商品规格价格id集合
          childSpecificationPriceIds: userSelectMealGroupIds.toString(),
          isFree: freeEnum.no, // 是否免费，1表示付费
          schoolId: schoolIdEnum.zsgzt, // 平台ID 招生工作台为4
          platformType: 1, // 平台标识 1为web
          isSendMessage: 0, //使用抵扣就要发送短信
          signSchoolName: this.orderExtraInfo.signSchoolName, // 上课校区名称
          signSchoolId: this.orderExtraInfo.assignedDeptCode, // 上课校区id
          orderType: orderTypeEnum.tranfer, // 1新增报名 2升班报名 3转班报名
          oldOrderId: this.orderIds.toString(),
          isFormal: 1, // 是否正式订单(0否 1是)
          isReceiveAll: 0, // 是否分笔支付(0否 1是)
          isIos: 0,

          //内层的 businessIdInt 表示转班时选择的商品的赛道
          businessIdInt: this.isCj ? "1" : "0", //1表示财经， 0表示非财经
          goodsEnrollingSchoolList, //成考，网教，自考 报名需要的
          goodsEnrollingMajorList,
          setCommodityId: this.specIdArr[0], //规则的id
          enrollingTime: this.ckSingle.enrollingTime, //成考的报名年级

          wxTradeOrderExtra: {
            projectCode: this.orderExtraInfo.projectCode, //赛道
            gztDeptId: this.teachOrder.gztDeptId,
            isRecommand: this.teachOrder.qkcStatus == 0 ? "否" : "是", //是否转介绍

            teacherRecruitName: this.orderExtraInfo.teacherName || "", //招生老师名称
            teacherRecruitId: this.teachOrder.teacherId || "", //招生老师Id
            teacherRecruitAffiliationName:
              this.teachOrder.teacherDeptName || "", //招生老师部门名称     全网新增的，可能为空
            teacherRecruitAffiliationId: this.teachOrder.schoolId || "", //招生老师部门Id
            subscribeSchoolName: this.orderExtraInfo.signSchoolName || "", //成交校区
            subscribeSchoolId: this.orderExtraInfo.assignedDeptCode || "", //成交校区id

            teacherRecommandId: this.teachOrder.qkcTeacherId || "", //转介绍老师id
            teacherRecommandName: this.teachOrder.qkcTeacherName || "", //转介绍老师名字
            teacherSalesId: this.teachOrder.assignedUserId || "", //销售老师id
            teacherSalesName: this.teachOrder.assignedUserName || "", //销售老师名字
            teacherSalesAffiliationName:
              this.teachOrder.assignedUserDeptName || "", //销售老师部门名称
            teacherSalesAffiliationId: this.teachOrder.assignedUserDeptId || "", //销售老师部门id
            affiliationId: this.teachOrder.assignedDeptId || "", //组织机构id
            affiliationName: this.teachOrder.assignedDeptName || "", //组织机构

            teacherSalesNCid: this.teachOrder.assignedUserCode || "", //销售老师NCID
            teacherRecruitNCid: this.teachOrder.teacherCode || "", //招生老师NCID
            teacherRecommandNCid: this.teachOrder.teacherNcId || "", //转介绍老师NCID
            ...{...this.transactionInfo, ...{isFourSix: ~~this.transactionInfo.isFourSix}},
            customerCode: this.studentInfo.customerCode, //客户编号
            name: this.signUpForm.name || "", //学员姓名
            idCard: this.signUpForm.idCard || "", //身份证号
            learnPhoneNumber: this.signUpForm.stuMoblie || "", //学员手机(全网) 从成交信息里取
            phoneNumber: this.signUpForm.stuMoblie || "", //学习账号（蓝鲸）
            ...{orderId: ''},//数据置空
          },

          jobLowestSalaryUnderCollege: this.signUpForm.jobLowestSalaryUnderCollege,
          jobLowestSalaryUpCollege: this.signUpForm.jobLowestSalaryUpCollege
        },

      };
      params.aftersaleAttachList = this.$refs.annexRef.getAllFiles()
      params.installmentMode = this.installmentMode
      params.isLoanRefund = this.shouldReturnPayPrice > 0?this.signUpForm.isLoanRefund:0
      if (!this.isCj) {
        params.createOrderVO.xlType = this.xlType;
      }
      // 贷款
      // if(this.signUpForm.isLoanRefund) {
      // if([installmentModeEnum.onlineLoan, installmentModeEnum.offlineLoan].includes(this.installmentMode)){
        // 退学员金额>0才需要提交贷款数据,不然提交空数据
        const isTrue = this.shouldReturnPayPrice > 0 && this.signUpForm.isLoanRefund == 1
        Object.assign(params, {
          loanRefundTime: isTrue ? this.dropoutForm.loanRefundTime : '',
          loanCompanyName: isTrue ? this.dropoutForm.loanCompanyName : '',
          loanBankName: isTrue ? this.dropoutForm.loanBankName : '',
          loanBankAccount: isTrue ? this.dropoutForm.loanBankAccount : '',
          loanRefundMoney: isTrue ? this.dropoutForm.loanRefundMoney : '',
          loanIouNo: isTrue ? this.dropoutForm.loanIouNo : '',
          loanReturnedMoney: isTrue ? this.dropoutForm.loanReturnedMoney : '',
          loanReturnedMoney: isTrue ? this.loanRefundStuMoney : '',

          loanPlatformId: isTrue ? this.dropoutForm.loanPlatformId : '',
          loanRefundType: isTrue ? this.dropoutForm.loanRefundType : '',
          loanOccupiedinterestMoney: isTrue ? this.dropoutForm.loanOccupiedinterestMoney : '',

          loanPlatformName: isTrue ? this.dropoutForm.loanPlatformName : '',
        })
         
      // }

      if (this._draft && this._draft.id) {
        params.id = this._draft.id;
      }
      return params;
    },
    onOrderModalCancel() {
      this.orderModalVisible = false;
    },

    // 订单弹窗确定回调
    async onOrderModalOk(rows) {

      //清除原来出纳审核，以及申请原因的数据
      this.cashierData = "";
      this.cashierCheckList = [];
      this.signUpForm.reason = "";
      //清除商品信息
      this.removeChoiceGoods();

      rows = Array.isArray(rows) ? rows : [rows];
      let nos = rows
        .reduce(
          (accumulator, cur) =>
            (accumulator ? accumulator + "," : "") + cur.orderNo,
          ""
        )
        .replace(/^,|,$/g)
        .split(",");
      this.orderNos = nos;
      this.orderModalVisible = false;
      this.collectionInfo.balancePay = ""; //重置用户输入的余额抵扣，避免收费项目计算出错
      this.showloanform = rows[0].installmentType;
      const result = await this.getDetailData(nos);


      // if (rows[0].installmentType !== 0) {
        // try { 
        //   await this.getRefundtrial(ids);
        // }catch( e ){
        //   console.error(e)
        // }    
      // }

      if (this.$route.query.afterSaleId && this.useDraft) {        
        this.setCalcDropout();
        this.useDraft = false;
      } else {
        this.getCalcDropout( result ? result.ncSignUpProjectEntity || [] : []);
      }
      if (this.goodsSpecTable.length) {
        //存在已选好规格后切换其他订单的情况，再次计算金额等信息
        this.calcFinalMoney();
      }
    },

    //获取选中售后原订单的详情
    async getDetailData() {
      this.orderIds = []
      const { result, code, msg } = await getNcOrderDetail({orderNo: this.orderNo, type: 1})
      
      if (code === 0) {
        // 构造数据
        this.handlePhone(result.ncSignUpDetailEntity, 'phone')
        result.ncSignUpDetailEntity.customerIdCode = result.bizOrderDetailReturnDto ? result.bizOrderDetailReturnDto.customerId : ''
        Object.assign(result.ncSignUpDetailEntity, {
          goodsCategoryId: result.ncSignUpDetailEntity.proTypeId,
          orderNo: this.orderNo,
          mobile: result.ncSignUpDetailEntity.phone,
          goodsSeriesId: result.ncSignUpDetailEntity.proTypeId,
          goodsName: result.ncSignUpDetailEntity.seSeQuenName,
          amountReceived: result.ncSignUpDetailEntity.thisReceivAble || 0,
          signSchoolId: result.ncSignUpDetailEntity.pkOrg || '',
          signSchoolName: result.ncSignUpDetailEntity.campusName || '',
          // userId: result.ncSignUpDetailEntity.userId || result.bizOrderDetailReturnDto.userId || '',
          orderId: result.ncSignUpDetailEntity.pkRegisTrationHid,
          installmentMode: result.ncSignUpDetailEntity.bisInsTallMent === 'N' ? installmentModeEnum.noLoan : installmentModeEnum.offlineLoan,
          productName: result.ncSignUpDetailEntity.prodLineName,
          businessName: result.ncSignUpDetailEntity.busTypeName,
          createTime: result.ncSignUpDetailEntity.registDate,
          stuName: result.ncSignUpDetailEntity.stuName,
          isLoan: result.ncSignUpDetailEntity.bisInsTallMent === 'N' ? 0 : 1,
          goodsSpecName: '',
          goodsTypeName: '',
          ncBusinessId: result.ncSignUpDetailEntity.busTypePk || '',
          totalMoney: result.ncSignUpDetailEntity.dnshoulddCost || 0,
        })
        this.orderIds = [result.ncSignUpDetailEntity.orderId]
        Object.assign(result.bizOrderDetailReturnDto, {
          assignedDeptCode: result.bizOrderDetailReturnDto.ncCampusSchoolId
        })
        if(!result.bizOrderDetailReturnDto.orderId && result.ncSignUpDetailEntity.pkRegisTrationHid){
          result.bizOrderDetailReturnDto.orderId = result.ncSignUpDetailEntity.pkRegisTrationHid
        }
        if(result.ncSignUpDetailEntity.userId && !result.bizOrderDetailReturnDto.userId){
          result.bizOrderDetailReturnDto.userId = result.ncSignUpDetailEntity.userId
        }
        this.handlePhone(result.bizOrderDetailReturnDto, 'phoneNumber')
        this.getTransactionInfo(result.bizOrderDetailReturnDto)
        //获取转班之前的商品信息
        this.orderTable = [result.ncSignUpDetailEntity];
        this.orderTable.forEach((item) => {
          this.businessId = item.businessId;
        });
        this.orderExtraInfo = result.bizOrderDetailReturnDto
        this.transactionInfo.tyCreateTime = result.ncSignUpDetailEntity.registDate

        if (this.orderTable.length) {
          let customerCode = this.orderTable[0].customerCode;
          this.setUserFirstData(); //设置学员信息
          this.dropoutForm.loanIouNo = this.orderTable[0].tradeSerialNumber || ''
          this.loanIouNoDisabled = !!this.orderTable[0].tradeSerialNumber
          //设置成交信息，客户存在，去请求全网的成交信息
          if(!customerCode){
            this.orderExtraInfo = {}; // 有客户id的话，取全网接口的信息来替换orderExtraInfo (学员信息)
          }

          if(this.orderTable[0].professional) {
            this.getUserByUserId(this.orderTable[0].professional)
          } else {
            // this.autoDirectUserId()
          }
        }
        
        this.autoDirectUserId()
        this.checkCashier()
        this.getPayBankList()
        return result
      }else{
        this.$message.error(msg || '获取售后订单数据超时')
      }
      
    },
    // 获取最新的学员账号
    getUserByUserId(userId) {
      this.$fetch('getUserByUserId', { userId }).then(res => {
        this.dropoutForm.dropMobile = res.data.mobile
        this.dropoutForm.userId = res.data.userId
      })
    },

    //从草稿里获取收支项目自动填充
    setCalcDropout() {
      let data = this._draft;
      let refundDetail = data ? data.aftersalePay : []; //防止没有启课程课包
      this.classInfo = refundDetail; //退费信息  退费计算的商品，已收上课等信息
      let dropOutTableList = [];
      refundDetail.map((it) => {
        let dropOutTable = [];
        it.inoutProjects &&
          it.inoutProjects.map((item) => {
            dropOutTable.push({
              ...item,
              refundMoney: it.refundMoney || 0,
              edit: false,
              canDelete: item.inoutType == 1 ? true : false,
              refundableMoney: item.refundableMoney || 0,
              deductionMoney: item.deductionMoney || 0,
            });
          });
        dropOutTableList.push(dropOutTable);
      });
      this.dropOutTableList = dropOutTableList;
    },
    //取第一条订单的数据填充学员信息
    setUserFirstData() {
      console.log("取第一条订单的数据填充学员信息", this.orderTable);
      let data = this.orderTable[0];
      this.studentInfo = JSON.parse(JSON.stringify(data));
      // 获取学员信息 禅道7238
      getSelectStudentInfo(this.tableData[0].orderPk, 1).then(async res => {
        let { code, result } = res
        if(code === 0) {
          Object.assign( this.signUpForm, {
            stuMoblie: result.phoneNumber,
            idCard: result.idCard,
            name: result.name,
            userId: result.userId,
            education: result.education
          })

          if(result.phoneNumber) {
            this.isCanStudy = true
          }

          // if(!!result.phoneNumber && !result.userId) {
          //   // 学习账号不为空，学员id为空。根据学习账号获取学员信息
          //   let list = await getSelectLjUserInfo({ mobile: result.phoneNumber })
          //   if(list.code === 0) {
          //     // 能获取到信息就直接用，不能获取到信息就新增
          //     if(list.result.userId) {
          //       Object.assign( this.signUpForm, {
          //         stuMoblie: list.result.mobile,
          //         userId: list.result.userId,
          //         name: list.result.nickName
          //       })
          //     } else {
          //       let obj = {
          //         idCard: this.signUpForm.idCard,
          //         mobile: this.signUpForm.stuMoblie,
          //         realName: this.signUpForm.name
          //       }
          //       let re = await doSaveLjUser(obj)
          //       if(re.code === 0) {
          //         Object.assign( this.signUpForm, {
          //           stuMoblie: re.result.mobile,
          //           userId: re.result.userId,
          //           name: re.result.nickName
          //         });
          //       }
          //     }
          //   }
          // }
          // if(!result.phoneNumber && !!result.userId) {
          //   // 学习账号为空，学员id不为空
          //   let list = await getSelectLjUserInfo( { userId: result.userId })
          //   if(list.code === 0) {
          //     Object.assign( this.signUpForm, {
          //       stuMoblie: list.result.mobile,
          //       userId: list.result.userId,
          //       name: list.result.nickName
          //     })
          //   }
          // }
        }
      })
      // Object.assign( this.signUpForm, {
      //   stuMoblie: this.studentInfo.mobile,
      //   idCard: this.studentInfo.idCard,
      //   name: this.studentInfo.stuName,
      //   userId: this.studentInfo.userId
      // })
      this.studentInfo.realName = data.stuName;
      this._tmpMoile = data.mobile; //用于检测手机号是否改变了
      this.signUpForm.mobile = data.mobile;
      // this.dropInfo.dropMobile = data.mobile;
      // this.dropInfo.dropName = data.stuName;
      // this.dropInfo.dropOrderNo = data.orderNo;
      this.signUpForm.dropMobile = data.mobile;
      this.signUpForm.dropName = data.stuName;
      this.signUpForm.dropOrderNo = data.orderNo;

      if(data.orderNo && data.mobile){
        this.disabledChangeDropOrderNo = true
      }
      this.studentInfo.customerCode = data.customerCode;
      this.studentInfo.customerCodeList = this.distinct(
        this.orderTable.map((it) => it.customerCode)
      ); //多升一，学员信息的客户编号列表
      this.studentInfo.mobileList = this.distinct(
        this.orderTable.map((it) => it.mobile)
      ); //多升一，账号列表
      this.studentInfo.stuNameReadonly =
        this.studentInfo.stuName && this.studentInfo.stuName.length > 0
          ? true
          : false;
      this.studentInfo.idCardonly =
        data.idCard && data.idCard.length > 0 ? true : false;
      this.hasStuInfo = true;

      // if (!this.studentInfo.realName) {
      //   this.getCustomerName();
      // }
      
      
    },
    //根据用户id和商品id获取商品状态
    async findOrderStatus() {
      const tmpParams = await this.createAfterSaleOrder();
      const params = {
        userId: this.studentInfo.userId,
        spPriceIdStr:
          tmpParams.createOrderVO.childSpecificationPriceIds ||
          tmpParams.createOrderVO.commodityIdList.toString(),
      };
      console.log("规格id", tmpParams, params);

      // if(params.spPriceIdStr === '') return
      const {
        data = {},
        code,
        msg,
      } = await this.$fetch(
        "findOrderStatusByUserIdAndSpecificationId",
        params
      );
      console.log("检查结果", data, code, msg);

      //purchaseStatusResultStatus购买状态(0 未购买(暂不返回数据) 1全款待支付 2已购买 3支付中 4分笔待支付)
      if (data.purchaseStatusResultStatus === 0) {
        return false;
      } else {
        alert("学员已报名该班型，请重新选择班型");
        return true;
      }
    },

    distinct(a) {
      return Array.from(new Set([...a]));
    },
    //退费贷款本金
    // async getRefundtrial(ids) {
      // ids = ids.toString();
      // const params = {
      //   orderId: ids,
      // };
      // const { data = [] } = await this.$fetch(
      //   "third_getRefundtrial",
      //   params
      // );
      // this.loanArray = data;
    // },
    // 获取退费计算表格数据
    async getCalcDropout(ncSignUpProjectEntity) {

      console.log('[获取收支项目]', ncSignUpProjectEntity)
      const order = this.orderTable[0] || {}
      let refundDetail = [
        {
          goodsName: order.seSeQuenName,
          inoutProjects: ncSignUpProjectEntity,
          orderId: order.orderId,
          goodsId: order.goodsId,
          goodsSpecName: order.goodsSpecName,
          goodsSpecId: order.goodsSpecId || '',
          goodsTypeId: order.goodsTypeId,
          productId: order.productId
        }
      ]
      if(refundDetail.length){
        refundDetail[0].expand = true
      }
      // console.log("退费计算的商品", data)
      let dropOutTableList = []
      refundDetail.map(it => {
        let receivedMoney = 0;//已收金额
        let dropOutTable = []
        it.inoutProjects && it.inoutProjects.map(item =>{
          if(!item.daMount) item.daMount = 0
          // 当核定可退费金额（初始值）= null 时，核定可退费金额（初始值）= 已收金额
          if(!item.drawBackMoney) item.drawBackMoney = item.daMount
          // if(!item.drawBackMoney) item.drawBackMoney = 0
          item.deductionMoney = item.daMount - item.drawBackMoney
          dropOutTable.push({
            ...item,
            refundMoney: it.refundMoney || 0,
            edit: false,
            canDelete: false,
            refundableMoney: item.drawBackMoney,
            deductionMoney: item.deductionMoney || 0,//扣费金额
            orderInoutProjectName: item.subjName,
            receivableMoney: item.shoulddCost || 0,//应收金额
            receivedMoney: item.daMount,//已收金额
            inoutProjectId: item.pkSubjCode,
            name: item.subjName,
            code: item.subjCode,
            calculatePerformance: item.isJiXiao,
            periodIncome: item.isKsSr,
            type: item.subjType,
          })
          receivedMoney += item.daMount//累加已收金额
        })
        it.inoutProjects = dropOutTable
        dropOutTableList.push(dropOutTable)
        if(Checker.isUndefined(it.receivedMoney) || Checker.isNull(it.receivedMoney)){
          it.receivedMoney = receivedMoney
        }
      })
      this.dropOutTableList = dropOutTableList
      console.log("退费表格>>>>>>>", this.dropOutTableList)
      this.classInfo = refundDetail //退费信息  退费计算的商品，已收上课等信息
    },
    // 编辑当前行
    onDropOutCellClick() {},
    //自定义退费表格合计
    onCustomSummaryDropoutBox(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        let values = data.map((item) => Number(item[column.property]));
        sums[index] = values.reduce((prev, curr) => {
          const value = isNaN(+curr) ? 0 : +curr;
          const preValue = isNaN(+prev) ? 0 : +prev;
          return preValue + value;
        }, 0);
        if (column.property === "canDo") {
          //操作栏不做统计
          sums[index] = "";
        } else if (!isNaN(+sums[index])) {
          const total = +accAdd(0, +sums[index], 2);
          // sums[index] = total < 0 ? 0 : total;
          sums[index] = total
        }
      });
      return sums;
    },

    toggleDeductionMoney(row, e) {
      if (!row.edit) {
        row.edit = true;
        const parentElement = e.target.parentElement;
        this.$nextTick(() => {
          const input = parentElement.querySelector("input");
          input && input.focus();
        });
      }
    },
    onDeductionMoneyBlur(dropOutTable, row) {
      console.log("本行", row);
      row.edit = false;
      let deductionMoney = +row.deductionMoney;
      let allDeductionMoney = dropOutTable.reduce(
        (pre, cur) =>
          pre + (isNaN(+cur.deductionMoney) ? 0 : +cur.deductionMoney),
        0
      );
      let allReceivedMoney = dropOutTable.reduce(
        (pre, cur) =>
          pre + (isNaN(+cur.receivedMoney) ? 0 : +cur.receivedMoney),
        0
      );
      if (deductionMoney === 0) {
        row.deductionMoney = 0;
        row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2);
        return;
      }
      if (isNaN(deductionMoney)) {
        row.deductionMoney = 0;
        row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2);
        return this.$message.error("请输入合法的金额");
      }

      // 小于0过滤
      if (row.deductionMoney < 0) {
        row.deductionMoney = 0;
        row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2);
        return this.$message.error("不能小于0");
      }
      row.refundableMoney = +accSub(row.receivedMoney, row.deductionMoney, 2);
    },
    onDeductionMoneyInput(row) {
      let val = (row.deductionMoney + "").split(".");
      if (val.length > 1 && (val[1] + "").length >= 3) {
        //命中小数点超出2位
        row.deductionMoney = val[0] + "" + "." + (val[1] + "").substr(0, 2);
        return row.deductionMoney;
      }
    },
    onDeleteDropOutTableRow(dropOutTable, tableIndex, row) {
      const deleteIndex = this.dropOutTableList[tableIndex].findIndex(
        (item) => item.inoutProjectId == row.inoutProjectId
      );
      console.log("[onDeleteDropOutTableRow]", row, deleteIndex);
      this.dropOutTableList[tableIndex].splice(deleteIndex, 1);
    },

    // 删除
    onDelete() {},
    // 保存 保存对应选择的ids数组,为啥要保存数组？不是保存信息吗？
    async onSave() {
    },
    // 加载之前保存的数据 根据售后单类型不同
    loadSaveData() {},
    // 关闭商品规格弹窗
    onGsCancel() {
      this.gsVisible = false;
    },

    //重置余额抵扣数值，重选商品或规格，或订单时，预防上次余额抵扣影响计算
    setDeafultBalancePay() {
      this.collectionInfo.balancePay = "";
    },

    //创建该页面的时候，触发是否由详情页进来的判断，以及对应的数据处理
    // 修改转班详情，初始化数据（保存为草稿又修改时使用）
    async initData() {
      
      //如果存在订单id
      if (!this.afterSaleId  ) return;

      const params = {
        id: this.afterSaleId,
        channel : 0
      };
      const { data } = await this.$fetch("aftersaleFindById", params);
      this._draft = data;
      await this.onOrderModalOk(data.aftersaleOrder);

      this.signUpForm.reason = data.reason; //申请原因
      let wxTradeOrderExtra = data.wxTradeOrderExtra || {};


      //学员账号
      this.studentInfo.customerCode = wxTradeOrderExtra.customerCode; //客户编号：
      this.studentInfo.mobile = wxTradeOrderExtra.phoneNumber; //学习账号：
      this.studentInfo.realName = wxTradeOrderExtra.name; //学生姓名：
      this.studentInfo.idCard = wxTradeOrderExtra.idCard; //身份证号：

      //出纳审核
      this.cashierData = data.cashierId;

      //退费方式
      if (this._draft.payType === 1) {
        this.dropoutForm.payType = "1";
        // this.dropoutForm.bankAccountName = this._draft.bankAccountName;
        // this.dropoutForm.bankAccount = this._draft.bankAccount;
        // this.dropoutForm.bank = this._draft.bank;
        this.signUpForm.bankAccountName = this._draft.bankAccountName;
        this.signUpForm.bankAccount = this._draft.bankAccount;
        this.signUpForm.bank = this._draft.bank;
      }

  
      //商品信息 调用选择商品的OK方法，走选择商品的逻辑
      let goodsId = data.aftersaleOrder[0].goodsId;
      const goodsParams = {
        pageSize: 10,
        curPage: 1,
        goodsId: goodsId,
      };    

      //处理默认选中商品
      if (goodsId) {
        //这里获取到了指定id的数据
        //获取到的商品数据
        const res = (await this.$fetch("getGoodsList", goodsParams)) || {
          data: [],
        };
        res.data.forEach((item) => {
          item.isAssignDefault = true;
        });

        await this.onGoodsDrawerOk(res.data[0]); //模拟手动选中商品
        
        let createOrderVo = data.createOrderVo || {};
        if (createOrderVo.isBalancePay) {
          this.collectionInfo.isBalancePay = "1";
          this.collectionInfo.balancePay = createOrderVo.balancePay;
          this.collectionInfo.userMobile = createOrderVo.userMobile;
          this.collectionInfo.stuAccountMoney = createOrderVo.stuAccountMoney;
          this.collectionInfo.userName = createOrderVo.userName;
        }
      }

      /**
       * 关于班型：
       * 如果是财经 - 暂时不清楚规则  todo 需要等待发鹏回来补充规则
       * 如果是自考 - 分为成考，网教，自考单品，自考套餐； 且自考单品，自考套餐存在两层选择的班型。
       *      成考，网教没有班型
       *  */

      if (this.signUpForm.spec != "默认班型") {
        //非默认班型自动填充规格
        let specInfo = this.getSpecInfo(data) //（草稿的数据）
        this.onSpecModalOk(specInfo)
      }
      // console.log("找回保存的余额抵扣信息")

      // // //找回余额抵扣
      // if (this._draft.aftersaleDetail.isBalancePay == 1) {
      //   this.collectionInfo.userMobile = data.aftersaleDetail.userMobile
      //   this.collectionInfo.isBalancePay = data.aftersaleDetail.isBalancePay + ""
      //   if (this.collectionInfo.isBalancePay === "1") {
      //     await this.getBalanceByMoblie(this.collectionInfo.userMobile) //获取学员账户余额和金额
      //   }
      //   this.collectionInfo.balancePay = data.aftersaleDetail.balancePay
      //   this.onBalancePayChange() //模拟输入本次抵扣金额后的计算
      // }
    },

    //取规格相关信息，模拟手动点击确定
    getSpecInfo(savedData) {
      //规格信息
      const specInfo = {
        // 计算出的总价
        totalPrice: 0,
        // 规格名称数组
        specificationName: "",
        // 规格id数组,
        specIdArr: [],
        // 整体的规格数组
        specArr: [],
      };

      console.log("规格2", savedData);
      if (!savedData.createOrderVo.commodityIds) return specInfo;
      specInfo.specIdArr = savedData.createOrderVo.commodityIds.split(",");
      let tmpSpecArr = [];
      let nameStr = "";
      let totalPrice = 0;
      for (let i of this.specPriceArr) {
        if (specInfo.specIdArr.includes(i.id + "")) {
          tmpSpecArr.push(i);
          if (i.specificationName2) {
            nameStr += `,${i.specificationName2}`;
          }
          totalPrice = accAdd(totalPrice, i.goodsDiscountPrice);
        }
      }

      specInfo.specArr = tmpSpecArr;
      specInfo.totalPrice = totalPrice;
      console.log("specInfo》》》》》》》", specInfo);

      specInfo.specificationName =
        nameStr != ""
          ? `${specInfo.specArr[0].specificationName1}(${nameStr.substr(1)})`
          : `${specInfo.specArr[0].specificationName1}`;

      console.log("草稿和商品", savedData, this.specPriceArr);
      return specInfo;
    },
    //预览商品
    async previewGoods(goodsId) {
      console.log("商品id", goodsId);
      const params = {
        goodsId: goodsId,
      };
      const { data } = await this.$fetch("previewGoods", params);
      window.open(data.url, "_blank");
    },
    //获得单品的信息
    async findGoodsCourseBySpPriceId() {
      const params = {
        goodsId: this.currentGoods.goodsId,
        spPriceId: this.specIdArr.toString(),
      };
      const { data } = await this.$fetch(
        "findGoodsCourseBySpPriceId",
        params
      );
      console.log("单品的课程信息", data);
      this.oneItem = data;
    },
    // 是否为存在就业协议
    async getEmploymentContract() {
      const res = await this.$fetch('employmentContract', {commodityIds: this.specIdArr.toString(), goodsId: this.currentGoods.goodsId})
      this.employmentShow = res.data
    },
    changeDropoutMobile(val) {
      let tmpItem = this.orderTable.find((item) => item.orderNo == val) || {mobile: '', stuName: ''};
      console.log("选中的值", val, tmpItem.stuName);
      // this.dropInfo.dropMobile = tmpItem.mobile;
      // this.dropInfo.dropName = tmpItem.stuName;
      this.signUpForm.dropMobile = tmpItem.mobile;
      this.signUpForm.dropName = tmpItem.stuName;
    },
    //获得规格名
    getSpecificationName(val) {
      let curSpec = val[0];
      if (!curSpec) return "";

      let name = "";
      // 单品默认规格 ,套餐默认规格
      if (this.currentGoodsRank === 1 || this.currentGoodsRank === 3) {
        let len = this.specTypeArr.length;
        console.log('len获取规格名',len);
        if (len === 0) {
          name = "默认规格";
        } else if (len === 1) {
          name = this.specTypeArr[0].specificationNameArr[0].specificationName;
        } else if (len == 2) {
          name = `${this.specTypeArr[0].specificationNameArr[0].specificationName}(${this.specTypeArr[1].specificationNameArr[0].specificationName})`;
        }
      }
      //单品多选，套餐单选
      else if (this.currentGoodsRank === 2 || this.currentGoodsRank === 4) {
        if (this.specTypeArr.length === 1) {
          name = curSpec.specificationName1;
        } else if (this.specTypeArr.length === 2) {
          name = `${curSpec.specificationName1}(${curSpec.specificationName2})`;
        }
      }
      // 套餐多规格多选的情况
      else if (this.currentGoodsRank === 5) {
        if (this.specTypeArr.length > 1) {
          let arr = [];
          val.forEach((item) => {
            arr.push(item.specificationName2);
          });
          let str = arr.length ? `(${arr.join("、")})` : "";
          return val.length ? val[0].specificationName1 + str : "";
        } else {
          //存在只有规格一项的情况
          let result = "";
          if (!val) return result;
          val.reduce(
            (pre, cur) => (result += cur.specificationName1 + ", "),
            result
          );
          return result.replace(/, $/, "");
        }
      }
      console.log('name',name);
      return name;
    },
    getTotalPrice(val) {
      let curSpec = val[0];
      console.log("计算总价", val);

      if (!curSpec) return;
      if (this.currentGoodsRank === 1 || this.currentGoodsRank === 3) {
        if (val.length < 1) return;
        // 单品默认
        if (this.currentGoodsRank === 1) {
          return this.specPriceArr[0].goodsPrice;
          // 套餐默认
        } else if (this.currentGoodsRank === 3) {
          return this.specPriceArr[0].goodsDiscountPrice;
        }
      } else if (this.currentGoodsRank === 2 || this.currentGoodsRank === 4) {
        //单品多选，套餐单选
        if (this.currentGoodsRank === 2) {
          return curSpec.goodsPrice;
        } else if (this.currentGoodsRank === 4) {
          return curSpec.goodsDiscountPrice;
        }
      } else if (this.currentGoodsRank === 5) {
        let sum = 0;
        for (const item of val) {
          sum += item.goodsDiscountPrice;
        }
        return Number(sum).toFixed(2);
      }
    },
    //价格改变，自动选择班型
    autoSelSpecs() {
      const specInfo = {
        // 计算出的总价
        totalPrice: 0,
        // 规格名称数组
        specificationName: "",
        // 规格id数组,
        specIdArr: [],
        // 整体的规格数组
        specArr: [],
      };
      let totalPrice;
      let specificationName;
      let specIdArr = [];
      let specArr = [];
      for (let i of this.specificationPriceArr) {
        if (this.previousSpecIdArr.findIndex((item) => item == i.id) >= 0) {
          specArr.push(i);
          specIdArr.push(i.id);
        }
      }
      totalPrice = this.getTotalPrice(specArr);
      specificationName = this.getSpecificationName(specArr);

      specInfo.specIdArr = specIdArr;
      specInfo.totalPrice = totalPrice;
      specInfo.specificationName = specificationName;
      specInfo.specArr = specArr;

      //模拟手动选择规格
      this.onSpecModalOk(specInfo);
    },

    professionalAlertCancel(){
      this.showTable = false
    },


    //待收金额 商品-优惠额-抵扣额
    waitPay() {
      let price = 0;
      if (this.isCj) {
        price =
          this.newGoodsPrice -
          this.goodsDiscount -
          this.collectionInfo.balancePay - this.approvedRefundableAmount;
      } else {
        price =
          this.zkNewGoodsPrice -
          this.zkGoodsDiscount -
          this.collectionInfo.balancePay - this.approvedRefundableAmount;
      }

      if( price < 0 || isNaN(price)){
        price = 0;
      }
      
      this.waitPayPrice = price.toFixed(2) || "--";
    },

    //应退金额
    shouldReturnPay(){
      let price = 0;

      //如果应收款 > 0， 那么应退款就是是被全部抵扣完了，所以应退款为0；
      if( this.waiPay > 0.000001 ){
        return 0;
      }

      //如果应收款为0，那么就存在应退金额
      if (this.isCj) {
        price =
          this.newGoodsPrice -
          this.discountPrice - this.approvedRefundableAmount;
      } else {
        price =
          this.zkNewGoodsPrice -
          this.discountPrice - this.approvedRefundableAmount;
      }

      if( price > 0 ){
        price = 0;
      }else {
        price = -price;
      }
      this.shouldReturnPayPrice =  price.toFixed(2) || "--";
    },

       /***
     * 获取成交信息
     */
    getTransactionInfo(bizOrderDetailReturnDto){
      this.transactionInfo = bizOrderDetailReturnDto || {};
      this.signUpForm.education = this.transactionInfo.education
      Object.keys(this.transactionInfo).forEach( key=>{
          if( key == "isRecommand" ){
            this.transactionInfo[`${key}Name`] = this.transactionInfo[key] == 1 ? '是': '否'
          }
      })
      this.cacheOriginTransactionInfo(this.transactionInfo)
      getJiamiCustomerData(this.transactionInfo.customerId).then(res => {
        res.result.contactList.map(item => {
          if(item.type == 0) {
            this.phoneList.push(item.detail)
          }
        })

        if(this.phoneList.indexOf(this.signUpForm.stuMobile) == -1) {
          this.signUpForm.stuMobile = this.phoneList[0]
        }
      })
      this.getAssistDealList()// 获取协助成交人员列表数据
    },
    // 获取协助成交人员列表数据
    getAssistDealList() {
      const arr = [this.transactionInfo.crmDealSchoolDeptId]
      axios.post(`/qw_api/wb/biz/userExtend/getTeacherList?requireOld=false`, arr).then(response => {
        const res = response.data
        if(res.code === 0){
          this.assistDealList = res.result.map((item) => {
            return {
              assistDealNcUserId:item.ncUserPk,
              assistDealCrmUserId:item.userId,
              assistDealUserName:item.userName
            }
          })
        }else{
          this.$message.error(res.msg)
        }
      }).catch( () => {
        this.$message.error(error.msg)
      })
    },
    // 更改协助成交人员
    changeAssistDeal() {
      console.log(this.transactionInfo.assistDealUserName)
      if(this.transactionInfo.assistDealCrmUserId !== "" && this.transactionInfo.assistDealCrmUserId !== null){
        this.assistDealList.forEach(el => {
          if(el.assistDealCrmUserId === this.transactionInfo.assistDealCrmUserId){
            this.transactionInfo.assistDealNcUserId = el.assistDealNcUserId
            this.transactionInfo.assistDealUserName = el.assistDealUserName
          }
        });
      }
      console.log(this.transactionInfo.assistDealUserName)
    },
    /***
     * 获取组合套餐信息
     * 参数: spPriceIdStr
     */
    async findComComGoodsInfo() {
      // alert("findComComGoodsInfo")
      const params = {
        spPriceIdStr: this.specIdArr.toString(),
      };
      const { data } = await this.$fetch("third_findComComGoodsInfo", params);
      this.cjComComGoodsInfo = data || {};
      console.log('[findComComGoodsInfo]', data);
      let singleGoodsSpPriceInfoList =
        this.cjComComGoodsInfo.singleGoodsSpPriceInfo || [];
      this.comComGoodsInfoList = [];
      for (let singleGoodsSpPriceInfoItem of singleGoodsSpPriceInfoList) {
        if (
          singleGoodsSpPriceInfoItem.kuaidaTotal === 0 ||
          singleGoodsSpPriceInfoItem.kuaidaTotal === "0"
        ) {
          singleGoodsSpPriceInfoItem.kuaidaTotal = "无上限";
        }
        if (singleGoodsSpPriceInfoItem.kuaidaTotal == null) {
          singleGoodsSpPriceInfoItem.kuaidaTotal = "--";
        }

        if (
          !(
            singleGoodsSpPriceInfoItem.spPriceName == "默认" ||
            singleGoodsSpPriceInfoItem.spPriceName == "默认班型" ||
            singleGoodsSpPriceInfoItem.spPriceName == "默认规格"
          )
        ) {
          singleGoodsSpPriceInfoItem.goodsName =
            singleGoodsSpPriceInfoItem.goodsName +
            "-" +
            singleGoodsSpPriceInfoItem.spPriceName;
        }
        this.comComGoodsInfoList.push({
          ...singleGoodsSpPriceInfoItem,
        });
      }
    },
    // 余额抵扣设置更新回调
    updateBanlancePayOption(){
      this.$nextTick(()=>{
        console.log('[updateBanlancePayOption]', this.collectionInfo.isBalancePay)
        this.calcFinalMoney()
      })
    },
    // 出纳
    async checkCashier(){
      this.setCashierCheck() //获取出纳列表
      this.cashierFormRules = {
        cashierId: [{required: true,message: "此项必填！！",tirgger: "submit" }],
        ...this.cashierFormRules
      }
      this.formRules = this.cashierFormRules

      this.$nextTick(() => {
        this.$refs.form && this.$refs.form.clearValidate()
      })
    },
    toggleDropoutTable(index){
      this.$set(this.classInfo[index], 'expand', !this.classInfo[index].expand)
      // dropoutTable.expand != dropoutTable.expand
    },
    // 收费项目合计
    receiveItemSummaryMethod(param){
      const { columns=[], data=[] } = param;
      const sums = [];

      if( !columns.length || !data.length ){
        return sums;
      }
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计';
          return;
        }

        //表示只计算最后一项。
        if(index < columns.length - 1) return ''
        const values = data.map(item => Number(item.shouldRecive));
        let totalPrice = values.reduce((prev, curr) => {
          return prev + (Number(curr) || 0)
        });
        sums[index] = totalPrice.toFixed(2)

      })  
      return sums;  
    },
    // 点击返回按钮
    doBack(){
      this.$hqMessageBox({
        title: "取消转班确认",
        message: '返回将不保留当前转班信息，是否确定取消转班？',
      })
      .then(() => {
        this.$router.back()
      })
      .catch(action => {})
    },
    // 探测是否有userId
    async autoDirectUserId(reRequest){
      let { data={}, code } = await this.$fetch("getUserByMobile", {  mobile: this.orderTable[0].mobile });
      if(code === 200){
        let isExit = data && data.userId
        if(!isExit){
          // 蓝鲸上不存在该帐号信息，注册到蓝鲸上
          await this.doLanJingData()
          // reRequest设置true只重新执行流程一次，防止死循环
          !reRequest && this.autoDirectUserId(true)
        }
        if(isExit){
          data.userId = data.userId + ''
          this.signUpForm.userId = data.userId
          this.studentInfo.userId = data.userId
          this.transactionInfo.userId = data.userId
          this.orderTable[0].userId = data.userId
        }
      }
      // 学习账号关联商机手机号，如手机号已注册，引用商机手机号且不允许修改
      this.disabledChangeStuMobile = !!(this.signUpForm.stuMoblie && this.signUpForm.userId)

    },
    // 获取付款银行列表
		async getPayBankList(){
      if(!(this.orderTable[0] && this.orderTable[0].pkOrg)) return
      // type 0 收款 1 退费
			const { data } = await this.$fetch("findFundMethod", { schoolId: this.orderTable[0].pkOrg, type: 1 })
			if(data){
				this.payBankList = data
			}
		},
		onBankAccountPkChange(val){
			const selectItem = this.payBankList.find(item => item.bankAccountPk == val) || {accountName: ''}
			this.signUpForm.ncAccountName = selectItem.accountName
		},
    // 贷款退费输入规则 -- 贷款退费金额 + 学员退费金额不能大于售后订单已收金额
		loanRefundMoneyVerify(value){
      console.log(value,'-------', this.loanRefundStuMoney, this.loanRefundStuMoney !== 0);
      if(!value) return false
      this.dropoutForm.loanRefundMoney = (value.match(/^\d*(\.?\d{0,2})/g)[0]) || ""
      if(this.loanRefundStuMoney == 0) {
        this.dropoutForm.payType = "0"
      }
      console.log("触发了", this.dropoutForm);
		},
    getloanPlatformList() {
      this.$fetch("findLoanPlatform").then(res => {
        this.loanPlatformList = res.data
      })
    },
    switchOneOrMore() {
      console.log("this.dropoutForm.payType", this.dropoutForm.payType);
      this.dropoutForm = {
        payType: this.dropoutForm.payType,
        // bank: "",
        // bankAccountName: "",
        // bankAccount: "",
        // 有钱花贷款
        loanRefundTime: '',
        loanCompanyName: '',
        loanBankName: '',
        loanBankAccount: '',
        loanRefundMoney: '',
        loanIouNo: '',
        loanReturnedMoney: '',
        // 有钱花贷款 end
        // // 付款银行信息:start
        // ncAccountName: '',
        ncBankAccountPk: '',
        // // 付款银行信息:end,
        loanOccupiedinterestMoney: this.dropoutForm.loanOccupiedinterestMoney,
        loanRefundType: "",
        loanPlatformId: ""
      }
      console.log("this.dropoutForm", this.dropoutForm);
      this.$forceUpdate()
    },
    saveDicount() {
      this.sureVisible = true;
    },
  },
  mounted() {
    this.orderNo = this.$route.query.orderNo;
    // this.afterSaleId = this.$route.query.aftersaleId;
    // this.getCustomeruser( true );
    
    //如果 this.afterSaleId 存在，那么会走 initData 来获取order数据。
    if( !this.afterSaleId ){
      this.onOrderModalOk({orderNo: this.orderNo});
    }

    
    this.loadSaveData();
    this.getNCOrderList()
    this.getloanPlatformList()
    this.$once("beforeDestroy", () => {
      clearInterval(this.smsCodeTimer);
    });
    //去除草稿功能之后，initData流程则不会再存在
    // this.initData();
  },
  computed: {
    ...mapGetters(["getTyUserInfo", "loginUser"]),
    //核定可退金额
    approvedRefundableAmount(){
      let refundMoney = 0;
      this.dropOutTableList.map(dropOutTable =>{
        let rowFefundMoney = dropOutTable.reduce((pre, cur)=> accAdd(pre, isNaN(+cur.refundableMoney) ? 0 : cur.refundableMoney), 0)
        refundMoney += isNaN(+rowFefundMoney) ? 0 : +rowFefundMoney
      })
      return refundMoney === 0 ? '0' : +accAdd(0, refundMoney, 2)
    },
    canDropoutList() {
      let canDropoutList = [];
      let orderTable = this.orderTable;
      for (let i in orderTable) {
        canDropoutList.push(orderTable[i]);
      }
      return canDropoutList;
    },
    labelTitle() {
      return "课程清单";
    },
    transferIds() {
      return this.$store.state.order.transferIds;
    },
    orderBtnDisabled() {
      if (
        this.signUpForm.oneOrMore == "1" &&
        this.signUpForm.idCard &&
        this.signUpForm.idCard.trim().length === 0
      ) {
        return true;
      }

      if (
        this.signUpForm.oneOrMore == "1" &&
        !this.signUpForm.idCard &&
        this.signUpForm.mobile.trim().length === 0
      ) {
        return true;
      }
      return false;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    /***
     * 财经的商品优惠金额
     */
    goodsDis() {
      if (
        this.goodsSpecTable[0] &&
        this.goodsSpecTable[0].goodsPrice > 0 &&
        this.goodsSpecTable[0].goodsDiscountPrice > 0
      ) {
        return this.isCj
          ? accSub(
              this.goodsSpecTable[0].goodsPrice,
              this.goodsSpecTable[0].goodsDiscountPrice
            )
          : this.zkDiscountAmount; // this.goodsSpecTable[0].discountAmount
      } else {
        return 0;
      }
    },
     /***
     * 商品价格
     */
    newGoodsPrice() {
      if (!Array.isArray(this.goodsSpecTable)) {
        return 0;
      }
      return this.goodsSpecTable && this.goodsSpecTable.length > 0
        ? this.goodsSpecTable[0].goodsPrice
        : 0;
    },
    /**
     * 能否使用余额抵扣
     */
    canUseBalancePay() {
      //转班抵扣金额      
      return this.shouldPay < 0.0001
    },
    //应收金额  商品-优惠额-转班抵扣
    shouldPay() {
      let price = 0;
      if (this.isCj) {
        price = this.newGoodsPrice - this.discountPrice - this.approvedRefundableAmount;
      } else {
        price = this.zkNewGoodsPrice - this.discountPrice - this.approvedRefundableAmount;
      }
      if( isNaN(price) || price < 0 ){
        price = 0
      }
      return price.toFixed(2) || "--";
    },
    // 付款信息-应收金额
    amountReceivable(){
      let price = 0;
      if (this.isCj) {
        price = this.newGoodsPrice - this.discountPrice ;
      } else {
        price = this.zkNewGoodsPrice - this.discountPrice;
      }
      if( isNaN(price) || price < 0 ){
        price = 0
      }
      return price.toFixed(2) || "--";
    },
    // 付款信息收支项目列表
    receiveItemDataList(){
      // const receiveItemDataList = this.receiveItemData.concat(this.receiveItemData2)//成考第二志愿不用显示收支
      const receiveItemDataList = this.receiveItemData
      receiveItemDataList.map(item =>{
        if(!item.transferDis){
          item.transferDis = 0
        }
        if(!item.shouldRecive){
          item.shouldRecive = 0
        }
        item.shouldRecive = accSub(item.standardPrice, item.priceDiscount)
      })
      return receiveItemDataList
    },
    // 展示成考第二志愿
    showCKGoods2(){
      return this.currentGoods.goodsId && this.currentGoods.xlType === xlTypeEnum.ck
    },
    // 收款信息-商品优惠
    goodsDiscount() {
      if (
        this.goodsSpecTable[0] &&
        this.goodsSpecTable[0].goodsPrice > 0 &&
        this.goodsSpecTable[0].goodsDiscountPrice > 0
      ) {
        return accSub(
          this.goodsSpecTable[0].goodsPrice,
          this.goodsSpecTable[0].goodsDiscountPrice
        );
      } else {
        return 0;
      }
    },
    // 反射成考第一志愿专业id
    firstEnrollingMajorId(){
      let enrollingMajorId = ''
      console.log('this.ckSingle.enrollingMajorList', this.ckSingle.enrollingMajorList);
      console.log('this.ckSingle.form.enrollingMajorId 反射成考第一志愿专业id', this.ckSingle.form.enrollingMajorId);
      this.ckSingle.enrollingMajorList && this.ckSingle.enrollingMajorList.forEach(item =>{
        enrollingMajorId = item.majorId
        // if(item.id == this.ckSingle.form.enrollingMajorId){
        //   return enrollingMajorId = item.majorId
        // }
      })
      return enrollingMajorId
    },
    // 收款信息-待收金额
    waitpayAmount(){
      const amount = (this.amountReceivable * 100 - this.approvedRefundableAmount*100) / 100
      return amount < 0 ? '0.00' : amount
    },
    loginUserSchoolId(){
      return (this.$store.getters.loginUser.deptId || '') + ''
    },
    ncDealSchoolId(){
      return this.transactionInfo.ncCampusSchoolId || ''
    },
    approvedRefundLabel(){
      const goodsTemplate = this.orderExtraInfo ? this.orderExtraInfo.goodsTemplate : ''
      if(this.installmentMode === installmentModeEnum.onlineLoan || this.installmentMode === installmentModeEnum.offlineLoan){
        return '核定可退费合计金额'
      }
      return goodsTemplate === goodsTemplateEnum.cjdp || goodsTemplate === goodsTemplateEnum.cjpt ? '核定可退金额': '核定可退费合计金额'
    },
    installmentMode(){
      if(!this.orderTable[0]) return installmentModeEnum.noLoan
      return this.orderTable[0].installmentMode
    },
    // 贷款信息-退学员金额
    loanRefundStuMoney(){
      // 应退金额-贷款平台退费
      return +accSub((isNaN(+this.shouldReturnPayPrice) ? 0 : +this.shouldReturnPayPrice) , (isNaN(+this.dropoutForm.loanRefundMoney) ? 0 : +this.dropoutForm.loanRefundMoney), 3)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./index.scss";

.tishiclass {
  margin-top: 10px;
  margin-bottom: 30px;
  background-color: #fdebec;
  width: 90%;
  border-radius: 10px;
  line-height: 40px;
  > ul {
    width: 100%;
    > li {
      color: #b61a19;
    }
  }
}
</style>