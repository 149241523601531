/*
 * @Description: 售后管理
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-08-16 19:11:58
 * @LastEditors: Seven
 * @LastEditTime: 2022-10-13 11:02:31
 */
import Permissions from "./perm"
class WbOrder extends Permissions {
  // 变更转介绍
  static orderChangeTransfer () {
    return WbOrder.checkPerm('ty:order:changeManage:transfer')
  }
  // 变更销售
  static orderChangeSales () {
    return WbOrder.checkPerm('ty:order:changeManage:sales')
  }
  // 变更招生
  static orderChangeRecruit () {
    return WbOrder.checkPerm('ty:order:changeManage:recruit')
  }
  // 订单列表导出
  static orderExport () {
    return WbOrder.checkPerm('ty:order:export')
  }
  // 售后列表导出
  static orderAfterExport () {
    return WbOrder.checkPerm('ty:order:after:export')
  }
  // 退费撤销
  static orderAfterRefundCancel () {
    return WbOrder.checkPerm('ty:order:after:refundCancel')
  }  
  // 转班撤销
  static orderAfterTurnClassPassedCancel () {
    return WbOrder.checkPerm('ty:order:after:turnClassPassedCancel')
  }  
  // 变更申请管理 通过--------
  static orderChangeAdopt () {
    return WbOrder.checkPerm('ty:change:adopt')
  }
  // 变更申请管理 驳回--------
  static orderChangeReject () {
    return WbOrder.checkPerm('ty:change:reject')
  }
  // 变更申请管理 撤销--------
  static orderChangeRevoke () {
    return WbOrder.checkPerm('ty:change:revoke')
  }
  // nc列表导出
  static orderNcExport () {
    return WbOrder.checkPerm('nc:order:export')
  }
  // 报名导入 商机excel导入报名做单
  static createExcelBtn () {
    return WbOrder.checkPerm('wb:order:create_excel')
  }
  // 学习中心
  static learningBtn () {
    return WbOrder.checkPerm('ty:order:update:learning')
  }
  // 变更招生
  static orderNcUpdateteacher() {
    return WbOrder.checkPerm('nc:order:update:teacher')
  }
  // 订单优惠审批按钮
  static orderDiscountApprovalBtn() {
    return WbOrder.checkPerm('order:discount:apply:approval')
  }
}
export default WbOrder
