/*
 * @Description: 订单
 * @Version: 1.0
 * @Autor: huliekao
 * @Date: 2021-04-28 11:18:57
 * @LastEditors: Seven
 * @LastEditTime: 2022-03-24 16:57:20
 */
import Layout from "@/layout";
const orderMange = [
  {
    path: "/afterSale",
    name: "afterSale",
    title: "订单管理",
    meta: { requireAuth: true },
    component: Layout,
    // redirect:'/afterSale/order',
    children: [
      {
        path: "list",
        name: "list",
        title: "售后列表",
        meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale/index.vue")
      },
      {
        path: "afterSaleDetail",
        name: "afterSaleDetail",
        title: "售后详情",
        meta: { sidebar: true },
        component: ()=>import("@/views/order/after-sale/detail/index.vue")
      },  
      {
        path: "upgrade",
        name: "upgrade",
        title: "升班",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale/upgrade/index.vue")
      },

      {
        path: "transfer",
        name: "transfer",
        title: "转班",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale/transfer/index.vue")
      },

      {
        path: "dropout",
        name: "dropout",
        title: "退费",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale/drop-out/index.vue")
      },
      {
        path: "signup",
        name: "signup",
        title: "报名",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/sign-up/index.vue")
      },
      {
        path: "collection",
        name: "collection",
        title: "收款",
        meta: { sidebar: true },
        component: ()=>import("@/views/order/management/collection/index.vue")
      },
      {
        path: "payComplete",
        name: "payComplete",
        title: "签约",
        meta: { sidebar: true },
        component: ()=>import("@/views/order/management/collection/pay-complete.vue")
      },


      {
        path: "order",
        name: "order",
        title: "订单列表",
        component: ()=>import("@/views/order/order-manager/order-list.vue")
      },

      {
        path: "cjOrderDetail",
        name: "cjOrderDetail",
        meta: { sidebar: true },
        title: "订单详情",
        component: ()=>import("@/views/order/order-manager/order-detail-cj.vue")
      },

      {
        path: "xlOrderDetail",
        name: "xlOrderDetail",
        title: "订单详情",
        meta: { sidebar: true },
        component: ()=>import("@/views/order/order-manager/order-detail-xl.vue")
      },



      // {
      //   path: "aftersaleList",
      //   name: "aftersaleList",
      //   title: "订单售后管理",
      //   meta: { requireAuth: true },
      //   component: ()=>import("@/views/order/")
      // },
      // {
      //   path: "",
      //   name: "",
      //   title: "贷款管理",
      //   meta: "",
      //   component: ()=>import("")
      // },
      

      // {
      //   path: "/orderMange/order",
      //   name: "order",
      //   title: "订单",
      //   meta: { requireAuth: true },
      //   component: () => import("@/views/order/order"),
      // },
      // {
      //   path: "/orderMange/collection",
      //   name: "collection",
      //   title: "收款",
      //   meta: { requireAuth: true },
      //   component: () => import("@/views/order/collection"),
      // },
      // {
      //   path: "/orderMange/refund",
      //   name: "refund",
      //   title: "退款",
      //   meta: { requireAuth: true },
      //   component: () => import("@/views/order/refund"),
      // },
      {
        path: "change",
        name: "change",
        title: "变更申请管理",
        meta: { requireAuth: true },
        component: () => import("@/views/order/change/list"),
      },
      {
        path: "collection-list",
        name: "collection-list",
        title: "收款确认列表",
        meta: { requireAuth: true },
        component: () => import("@/views/order/collection-list/list"),
      },
      {
        path: "collection-detail",
        name: "collection-detail",
        title: "收款确认详情",
        meta: { sidebar: true },
        component: () => import("@/views/order/collection-list/components/detail"),
      },
      // nc售后
      {
        path: "nclist",
        name: "ncOrderList",
        title: "nc订单列表",
        component: ()=>import("@/views/order/nc-order/list.vue")
      },
      {
        path: "ncAfterSaleDetail",
        name: "ncAfterSaleDetail",
        title: "售后详情",
        meta: { sidebar: true },
        component: ()=>import("@/views/order/after-sale-nc/detail/index.vue")
      },  
      {
        path: "ncUpgrade",
        name: "ncUpgrade",
        title: "升班",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale-nc/upgrade/index.vue")
      },
       
      {
        path: "costmanagement",
        name: "costmanagement",
        title: "成本变更管理",
        meta: { requireAuth: true },
        component: ()=>import("@/views/order/costmanagement/list.vue")
      },
      {
        path: "zeroElementPage",
        name: "zeroElementPage",
        title: "零元订单审批",
        meta: { requireAuth: true },
        component: ()=>import("@/views/order/zeroElementPage/list.vue")
      },
      {
        path: "discountApproval",
        name: "discountApproval",
        title: "订单优惠申请",
        meta: { requireAuth: true },
        component: ()=>import("@/views/order/discountApproval/list.vue")
      },
      {
        path: "ncTransfer",
        name: "ncTransfer",
        title: "转班",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale-nc/transfer/index.vue")
      },

      {
        path: "ncDropout",
        name: "ncDropout",
        title: "退费",
        meta: { sidebar: true },
        // meta: { requireAuth: true },
        component: ()=>import("@/views/order/after-sale-nc/drop-out/index.vue")
      },
      // end:nc售后
    ],
  },
];

export default orderMange;
